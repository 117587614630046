<template>
    <div>
        <div class="h-fit -mt-4" ref="day-progress-chart"></div>
        <p class="text-center text-sm -mt-4">{{calcTime()}}</p>
    </div>
</template>

<script>

import ApexCharts from 'apexcharts'
import helper from '@/services/helper'

export default {
    name: 'DayProgress',
    props: ['attendance'],
    methods: {
        calcDayProgress(){
            const max =  8*60
            let date = new Date()
            if(this.$props.attendance.end){
                date = new Date(helper.formatDate(new Date(this.$props.attendance.date)) + ' ' + this.$props.attendance.end)
            }
            const progress = helper.getTotalMinutes(date, this.$props.attendance.start)
            const percentage = Math.round((progress / max) * 100)
            return percentage
        },
        calcTime(){
            let date1 = new Date()
            if(this.$props.attendance.end){
                date1 = new Date(helper.formatDate(new Date(this.$props.attendance.date)) + ' ' + this.$props.attendance.end)
            }
            const date2 = new Date(helper.formatDate(new Date(this.$props.attendance.date)) + ' ' + this.$props.attendance.start)
            return helper.clacTime(date1, date2)
        }
    },
    mounted(){
        var options = {
        chart: {
            height: 150,
            type: "radialBar"
        },
        
        series: [this.calcDayProgress()],
        
        plotOptions: {
            radialBar: {
            hollow: {
                margin: 15,
                size: "55%",
            },
            
            
            dataLabels: {
                showOn: "always",
                name: {
                offsetY: 0,
                show: false,
                color: "#888",
                fontSize: "13px"
                },
                value: {
                color: "#111",
                fontSize: "20px",
                offsetY: 10,
                show: true
                }
            }
            }
        },
        fill: {
            colors: ['#3939B8']
        },
        stroke: {
            lineCap: "round",
        },
            labels: [""]
        };

        var chart = new ApexCharts(this.$refs['day-progress-chart'], options);

        chart.render();
    }
}
</script>