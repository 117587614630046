const validate = {
    isImage(mimeType) {
        if(!mimeType.startsWith('image/')){
            return false
        }
        return true
    },
    maxSize(size, maxKB){
        if(size > this.KBToBytes(maxKB)){
            return false
        }
        return true
    },
    bytesToKB(bytes) {
        return bytes / 1024;
    },
    KBToBytes(kilobytes) {
        return kilobytes * 1024;
    }
}

export default validate