<template>
  <div>
    <div class="grid lg:grid-cols-5 gap-4">
      <div class="card p-6 lg:col-span-1 h-fit">
        <p class="text-lg mb-6">Filter</p>
        <attendance-filter :adminAccess="adminAccess" :employees="employees" :onFilter="onFilter" />
      </div>
      <div class="card lg:col-span-4 h-fit overflow-hidden">
        <div class="p-6" v-show="adminAccess">
          <button class="btn btn-primary btn-sm" @click="addAttendanceModal = true">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
              </svg>
              Create
          </button>
        </div>
        <div class="overflow-x-auto">
          <attendances-table :employees="employees" :attendances="attendances" :onUpdate="getAttendances" :adminAccess="adminAccess" />
        </div>
        <div class="p-6"><table-footer :defaultCount="filters.limit" :next="nextPage" :previous="prevPage" :chnangeCount="limitChnage" :pages="tablePages" /></div>
      </div>
    </div>
    <modal :active="addAttendanceModal" :close="closeAddAttendanceModal" >
        <div class="w-full max-w-[384px] mx-auto px-4 lg:px-0">
            <div class="card p-6">
                <add-attendance-form :employees="employees" :onClose="closeAddAttendanceModal" :onSave="getAttendances" />
            </div>
        </div>
    </modal>
  </div>
</template>


<script>
import AttendancesTable from "@/components/partials/tables/hrm/AttendancesTable.vue";
import server from "@/services/server";
import AddAttendanceForm from '@/components/partials/forms/hrm/AddAttendanceForm.vue';
import Modal from '@/components/elements/Modal.vue'
import AttendanceFilter from '@/components/partials/filters/hrm/AttendanceFilter.vue';
import helper from '@/services/helper';
import TableFooter from '@/components/elements/TableFooter.vue';
import local from '@/services/local';
export default {
  components: { AttendancesTable, AddAttendanceForm, Modal, AttendanceFilter, TableFooter },
  name: "AdminAttendances",
  data() {
    return {
      attendances: [],
      employees: {},
      addAttendanceModal: false,
      filters: {
        filter: {
          date: ''
        },
        sort: {date: '-1'},
        limit: 50,
        skip: 0
      },
      tablePages: 0,
      adminAccess: false
    };
  },
  methods: {
    async getAttendances() {
      try {
        const url = this.adminAccess ? "/attendance/fetch" : '/attendance/self/fetch'
        const response = await server.post(url, this.filters, true);
        this.attendances = response.attendances;
        this.tablePages = Math.ceil(response.length / this.filters.limit)
        console.log(this.attendances)
      } catch (e) {
        this.$moshaToast("Unable to get attendances. Please try again.", {
          type: "danger",
        });
      }
    },
    async getEmployees() {
      try {
        const response = await server.post("/employee/fetch", {}, true);
        for(let i in response.employees){
            this.employees[response.employees[i]._id] = response.employees[i]
        }
      } catch (e) {
        this.$moshaToast("Unable to get employees. Please try again.", {
          type: "danger",
        });
      }
    },

    closeAddAttendanceModal(){
        this.addAttendanceModal = false
    },
    async onFilter(data){
      this.filters.filter = data.filter
      this.filters.sort = data.sort
      await this.getAttendances()
    },
    async limitChnage(count){
      this.filters.limit = count
      this.filters.skip = 0
      await this.getAttendances()
    },
    async nextPage(){
      this.filters.skip += this.filters.limit
      await this.getAttendances()
    },
    async prevPage(){
      this.filters.skip -= this.filters.limit
      await this.getAttendances()
    },
  },
  async created() {
    const localemp = local.get('_auth').employee
    this.adminAccess = (localemp.is_admin || localemp.access.indexOf('hrm') >= 0)
    this.filters.filter.date = helper.formatDate(new Date()) 
    await this.getEmployees()
    await this.getAttendances()
    
  },
};
</script>