<template>
    <table class="table">
        <thead>
            <tr>
                <th>Date</th>
                <th>Start</th>
                <th>End</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="row in attendances" :key="row._id">
                <td>{{formatDate(row.date)}}</td>
                <td>{{formatTime(formatDate(row.date), row.start)}}</td>
                <td>{{formatTime(formatDate(row.date), row.end)}}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import helper from '@/services/helper'


export default {
    name: 'MiniAttendanceTable',
    props: ['attendances'],
    methods: {
        formatDate(date){
            return helper.formatDate(new Date(date))
        },
        formatTime(date, time){
            return helper.formatTime12Hour(new Date(date + ' ' + time))
        }
    }
}
</script>