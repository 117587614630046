<template>
  <div class="dashboard">

    <div class="top-row">

      <div class="col-span-2 mb-6 lg:mb-0">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">

          <welcome-card :employee="employee" :attendances="attendances" />

          <attendance-score-board :attendanceScore="attendanceScore" />

        </div>
        <div class="w-full">
          <monthly-attendance-time v-if="monthlySelfAttendances[0]" :attendances="monthlySelfAttendances" />
        </div>
      </div>

        <employee-attendance-list :employees="employees" />

    </div>


<!-- 

    <div
      class="py-2 px-4 bg-green-500 w-full mb-8 rounded text-white"
      v-if="holiday._id"
    >
      <i class="fa-regular fa-face-laugh-beam mr-2 text-lg"></i> Upcoming
      holiday is <strong>{{ holiday.title }}</strong> on
      <strong>{{ holiday.date }}</strong
      >.
    </div> -->




    <div class="grid grid-cols-1 lg:grid-cols-3 lg:gap-6 mb-6">
           
      <!-- <div class="card p-6 col-span-2 mb-6 lg:mb-0" >
        <div class="flex justify-between items-center">
          <span class="w-full"><p class="card-heading pb-6">Working Hours</p></span>
          <toggle-button :labels="['Monthly', 'Weekly']" :onToggle="toggleWorkingHoursData" class="text-sm" />
        </div>
        <div ref="monthlyWorkingHoursRef"> -->
          <!-- <monthly-hours-progress
          v-if="monthlyAttendances.counts"
          :progress="monthlyAttendances"
          :employees="allEmployees"
        /> -->
        <monthly-hours-progress
          v-if="Object.keys(allEmployees).length !== 0"
          :employees="allEmployees"
        />
        <!-- <working-hours-progress v-if="monthlyAttendances.counts && holidaysOfMonth.length > 0"
          :progress="monthlyAttendances"
          :employees="allEmployees" :holidays="holidaysOfMonth" /> -->
        <!-- </div>
      </div> -->


      <div>
        <calender v-if="Object.keys(allEmployees)[0]"  :employees="allEmployees" />
      </div>
    </div>

    <div class="grid grid-cols-1 lg:grid-cols-3 lg:gap-6">
      <div class="col-span-1 mb-6 lg:mb-0">
        <notice-list :notices="notices" />
      </div>
      <div class="card col-span-2 pb-6">
          <div class="p-6">
            <p class="card-heading">Leaves</p>
          </div>
          
          <div class="overflow-x-auto">
            <leaves-table :leaves="leaves.leaves" :types="leaves.types" :employees="allEmployees" />
          </div>
      </div>
    </div>

 <!-- <notice-list :notices="notices" /> -->
  </div>
</template>

<script>
import server from "@/services/server";
import NoticeList from "@/components/partials/lists/dashboard/NoticeList.vue";
import WelcomeCard from '@/components/partials/info/dashboard/WelcomeCard.vue';
import AttendanceScoreBoard from '@/components/partials/info/dashboard/AttendanceScoreBoard.vue';
import MonthlyAttendanceTime from '@/components/partials/charts/dashboard/MonthlyAttendanceTime.vue';
import EmployeeAttendanceList from '@/components/partials/lists/dashboard/EmployeeAttendanceList.vue';
import Calender from '@/components/partials/info/dashboard/Calender.vue';
import MonthlyHoursProgress from '@/components/partials/charts/dashboard/MonthlyHoursProgress.vue';
import WorkingHoursProgress from '@/components/partials/charts/dashboard/WorkingHoursProgress.vue';
import LeavesTable from '@/components/partials/tables/hrm/LeavesTable.vue';
import ToggleSwitch from '@/components/elements/ToggleSwitch.vue';
import ToggleButton from '@/components/elements/ToggleButton.vue';
export default {
  name: "Dashboard",
  components: { NoticeList, WelcomeCard, AttendanceScoreBoard, MonthlyAttendanceTime, EmployeeAttendanceList, Calender, MonthlyHoursProgress, WorkingHoursProgress, LeavesTable, ToggleSwitch, ToggleButton },
  data() {
    return {
      employee: {},
      profile: {},
      attendances: [],
      dayProgress: {
        max: 100,
        progress: 0,
      },
      employees: {},
      notices: [],
      // holiday: {},
      monthlyAttendances: {},
      allEmployees: {},
      attendanceScore: { onTime: 0, absent: 0, late: 0, leaves: 0 },
      row2height: 0,
      monthlySelfAttendances: [],
      // holidaysOfMonth: [] 
      leaves: [],
      workingHoursWeekly: true
    };
  },
  methods: {
    imgSrc(id) {
      return server.resolve("/employee/avatar/" + id);
    },
    setHolidaysOfMonth(data){
      this.holidaysOfMonth = data
    },
    async fetchDashboard() {
      try {
        const response = await server.get("/dashboard");
        this.employee = response.employee.employee;
        this.profile = response.employee.profile;
        this.attendances = response.employee.attendances;
        this.employees = response.loggedin;
        this.notices = response.notices;
        this.monthlyAttendances = response.monthlyAttendances;
        this.allEmployees = response.allEmployees;
        this.attendanceScore = response.attendanceScore
        this.monthlySelfAttendances = response.monthlySelfAttendances
        this.leaves = response.leaves
      } catch (e) {
        this.$moshaToast("Unable to get data. Please try again.", {
          type: "danger",
        });
      }
    },

  },
  async created() {
    await this.fetchDashboard();
    // this.countEmployees();
    
    // try{
    //   new ResizeObserver(() => {
    //     this.row2height = this.$refs.monthlyWorkingHoursRef.clientHeight
    //   }).observe(this.$refs.monthlyWorkingHoursRef)
    // }catch(e){

    // }
  },
  mounted() {
    // this.orderAttendance();
    
  },
};
</script>
