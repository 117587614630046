<template>
    <div class="card py-6 overflow-y-hidden">
        <div class="px-6 w-full"><p class="card-heading">Logged In</p></div>
        <div class="overflow-y-auto px-6 h-[400px] divide-y">
            <div
                class="flex items-center justify-between w-full py-2"
                v-for="emp in employees"
                :key="emp._id"
            >
                <div class="flex items-center">
                    <div class="">
                        <img
                            :src="imgSrc(emp._id)"
                            class="w-12 h-12 rounded-full mr-2"
                            alt=""
                        />
                    </div>
                    <p class="">{{ emp.first_name }} {{ emp.last_name }}</p>
                </div>
                <span
                    class="font-bold"
                    :class="{
                        'text-green-600': checkOnTime(emp.attendance),
                        'text-red-500': !checkOnTime(emp.attendance),
                    }"
                    >{{ emp.attendance.start }}</span
                >
            </div>
        </div>
    </div>
</template>

<script>
import server from '@/services/server';
export default {
    name: "EmployeeAttendanceList",
    props: ["employees"],
    methods: {
        checkOnTime(attendance) {
            const currentDate = new Date();
            const currentDateTime = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate(),
                10,
                15,
                0
            );
            const start = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate(),
                attendance.start.split(":")[0],
                attendance.start.split(":")[1]
            );
            if (start <= currentDateTime) {
                return true;
            } else {
                return false;
            }
        },
         imgSrc(id) {
            return server.resolve("/employee/avatar/" + id);
        },
    },
};
</script>
