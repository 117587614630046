const helper = {
    clacTime(date1, date2){
        const milliseconds = date1 - date2
        let hours = Math.floor(milliseconds / 3600000); // 1 hour = 3600000 milliseconds
        let minutes = Math.floor((milliseconds % 3600000) / 60000); // 1 minute = 60000 milliseconds
        hours = hours.toString().padStart(2, '0'); // add leading zero if necessary
        minutes = minutes.toString().padStart(2, '0'); // add leading zero if necessary
        return `${hours}h ${minutes}m`;
    },

    formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    },

    formatTime(date){
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${formattedHours}:${formattedMinutes}`;
    },

    formatTime12Hour(date) {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const amOrPm = hours >= 12 ? 'PM' : 'AM';
        const twelveHourFormat = hours % 12 === 0 ? 12 : hours % 12;
        const formattedHours = twelveHourFormat < 10 ? `0${twelveHourFormat}` : twelveHourFormat;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
      },
      convertTimeTo12Hour(time24){
        const [hours, minutes] = time24.split(':');
        const amOrPm = hours >= 12 ? 'PM' : 'AM';
        const twelveHourFormat = hours % 12 === 0 ? 12 : hours % 12;
        const formattedHours = twelveHourFormat < 10 ? `0${twelveHourFormat}` : twelveHourFormat;
        // const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${formattedHours}:${minutes} ${amOrPm}`;
      },
      
      getDaysBetweenDates(date1, date2) {
        // Calculate the difference in milliseconds between the two dates
        const timeDiff = Math.abs(date2.getTime() - date1.getTime());

        // Calculate the number of days between the two dates
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        return daysDiff;
      },
      addLeadingZeros(str, length) {
        while (str.length < length) {
          str = '0' + str;
        }
        return str;
      },
      calculatePercentage(date, time1, time2) {
        // Calculate the difference in milliseconds between the two dates
        const timeDiff = Math.abs(date2.getTime() - date1.getTime());
      
        // Calculate the percentage difference between the two dates
        const percentageDiff = (timeDiff / (1000 * 3600 * 24)) * 100;
      
        return percentageDiff;
      },
      // calculateDays(date1, date2) {
      //   // Calculate the difference in milliseconds between the two dates
      //   const timeDiff = Math.abs(date2.getTime() - date1.getTime());
      
      //   // Calculate the number of days between the two dates
      //   const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
      
      //   return daysDiff;
      // }
      getTotalMinutes(date, from = '0:0') {
        const [min, sec] = from.split(':')
        const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), min, sec, 0); // get the start of the day
        const elapsedMinutes = Math.floor((date - startOfDay) / (1000 * 60)); // get the elapsed minutes
        return elapsedMinutes;
      },

      compareDates(date1, date2) {
        // set the time to 0 for both dates
        date1.setHours(0, 0, 0, 0);
        date2.setHours(0, 0, 0, 0);
      
        const time1 = date1.getTime();
        const time2 = date2.getTime();
      
        if (time1 > time2) {
          return 1;
        } else if (time1 < time2) {
          return -1;
        } else {
          return 0;
        }
      },

      totalMinutesFromTime(timeString) {
        const [hours, minutes] = timeString.split(':').map(Number);
        return (hours * 60) + minutes;
      },

      calculateTimeSincePublished(publishedTime) {
        const now = new Date();
        const timeSincePublished = now - publishedTime;
        
        if (timeSincePublished < 60 * 1000) {
          return "Just now";
        } else if (timeSincePublished < 60 * 60 * 1000) {
          const minutesAgo = Math.floor(timeSincePublished / (60 * 1000));
          return `${minutesAgo} minute${minutesAgo > 1 ? 's' : ''} ago`;
        } else if (timeSincePublished < 24 * 60 * 60 * 1000) {
          const hoursAgo = Math.floor(timeSincePublished / (60 * 60 * 1000));
          return `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
        } else {
          const daysAgo = Math.floor(timeSincePublished / (24 * 60 * 60 * 1000));
          return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
        }
      },

      getTimeString(milliseconds) {
        // Create a new Date object with the given milliseconds
        const dateObj = new Date(milliseconds);
      
        // Extract the hours, minutes, and AM/PM indicator from the date object
        const hours = dateObj.getUTCHours() % 12 || 12; // Convert 24-hour format to 12-hour format
        const minutes = dateObj.getUTCMinutes().toString().padStart(2, '0');
        const ampm = dateObj.getUTCHours() >= 12 ? 'PM' : 'AM';
      
        // Combine the hours, minutes, and AM/PM indicator into a readable time string
        const timeString = `${hours}:${minutes} ${ampm}`;
      
        // Return the time string
        return timeString;
      },
      getTimeStringFromMS(milliseconds) {
        // Calculate the total number of minutes and hours from the given milliseconds
        const totalMinutes = Math.floor(milliseconds / 60000); // 1 minute = 60000 milliseconds
        const hours = Math.floor(totalMinutes / 60).toString().padStart(2, '0');
        const minutes = (totalMinutes % 60).toString().padStart(2, '0');
      
        // Combine the hours and minutes into a readable time string
        const timeString = `${hours}h ${minutes}m`;
      
        // Return the time string
        return timeString;
      },
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      weekDays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thrusday', 'Friday', 'Saturday'],
      getDateString(dateObj) {
        // Extract the day and month from the date object
        const day = dateObj.getDate();
        const monthIndex = dateObj.getMonth();

        // Get the month name from the array
        const monthName = this.monthNames[monthIndex];
      
        // Add the appropriate suffix to the day (e.g. "1st", "2nd", "3rd", etc.)
        const suffixes = ['th', 'st', 'nd', 'rd'];
        const suffixIndex = day % 10 < 4 ? day % 10 : 0;
        const suffix = suffixes[suffixIndex];
      
        // Combine the day, suffix, and month name into a readable date string
        const dateString = `${day}${suffix} ${monthName}`;
      
        // Return the date string
        return dateString;
      },

      formatTimeFromMinutes(minutes) {
        let hours = Math.floor(minutes / 60);
        let mins = minutes % 60;
        return `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`;
      },

      sortObjectKeys(obj) {
        const sortedKeys = Object.keys(obj).sort();
        const sortedObj = {};
        for (const key of sortedKeys) {
          sortedObj[key] = obj[key];
        }
        return sortedObj;
      }

      
}


export default helper