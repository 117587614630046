<template>
    <div class="card p-6">
        <p class="card-heading">Co-ordinators</p>
        <div>
            <div class="flex gap-4 w-full items-center my-2" v-for="person in client.contact_persons" :key="person.name">
                <div class="flex items-center gap-4 w-full">
                    <button>
                        <span class="h-6 w-6 text-xs flex items-center justify-center rounded-full bg-orange-500 text-white">
                           <i class="fa-light fa-user"></i>
                        </span>
                    </button>
                    <p class="text-sm">{{person.name}}</p>
                </div>
                <div class="flex gap-3">
                    <a :href="`https://web.whatsapp.com/send/?phone=${person.phone}`" target="_blank">
                        <span class="h-6 w-6 text-xs flex items-center justify-center rounded-full bg-green-500 text-white">
                           <i class="fa-brands fa-whatsapp"></i>
                        </span>
                    </a>
                    <a :href="`tel:${person.phone}`" target="_blank">
                        <span class="h-6 w-6 text-xs flex items-center justify-center rounded-full bg-gray-500 text-white">
                           <i class="fa-sharp fa-solid fa-phone"></i>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ClientContactPersons',
    props: ['client']
}
</script>