<template>
  <vee-form @submit="save" :initial-values="initVals" :validation-schema="schema">
    <div class="mat-input mb-6">
      <vee-field name="employee" v-slot="{ meta, field }">
        <label for="">Employee:*</label>
        <select
          name="employee"
          v-bind="field"
          :class="{ 'is-invalid': !meta.valid && meta.touched }"
        >
          <option value="">Select employee</option>
          <option v-for="emp in employees" :key="emp._id" :value="emp._id">
            {{ emp.first_name }} {{ emp.last_name }}
          </option>
        </select>
        <error-message name="employee" class="invalid-feedback" />
      </vee-field>
    </div>
    <div class="mat-input mb-6">
      <vee-field name="date" v-slot="{ meta, field }">
        <label for="">Date:*</label>
        <input
          type="date"
          name="date"
          v-bind="field"
          :class="{ 'is-invalid': !meta.valid && meta.touched }"
        />
        <error-message name="date" class="invalid-feedback" />
      </vee-field>
    </div>
    <div class="grid grid-cols-2 gap-4 mb-6">
      <div class="mat-input">
        <vee-field name="start" v-slot="{ meta, field }">
          <label for="">Start:*</label>
          <input
            type="time"
            name="start"
            v-bind="field"
            :class="{ 'is-invalid': !meta.valid && meta.touched }"
          />
          <error-message name="start" class="invalid-feedback" />
        </vee-field>
      </div>

      <div class="mat-input">
        <vee-field name="end" v-slot="{ meta, field }">
          <label for="">End:</label>
          <input
            type="time"
            name="end"
            v-bind="field"
            :class="{ 'is-invalid': !meta.valid && meta.touched }"
          />
          <button href="" type="button" class="text-blue-600 text-sm hover:underline" @click="setEndTime()">Current time</button>
          <error-message name="end" class="invalid-feedback" />
        </vee-field>
      </div>
    </div>
    <div class="flex gap-4">
        <button class="btn btn-primary btn-block">
            <span v-show="!inSubmission">Save</span>
            <span class="text-center" v-show="inSubmission">
                <svg class="animate-spin -ml-1 mr-1 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Please wait...
            </span>
        </button>
        <button type="button" class="btn btn-white btn-block" @click="onClose">
            Close
        </button>
    </div>
  </vee-form>
</template>

<script>
import server from '@/services/server';
import helper from '@/services/helper';
export default {
  name: "UpdateAttendanceForm",
  props: ["attendance", "employees", 'onClose', 'onSave'],
  data(){
    return{
        initVals: {},
        schema: {
          employee: 'required',
          date: 'required',
          start: 'required'
        },
        inSubmission: false
    }
  },
  methods: {
    async save(values){
        this.inSubmission = true
        if(values.end && values.end != ''){
            values.status = 1
        }else{
            values.status = 0
        }
        try{
            await server.patch('/attendance/' + this.$props.attendance._id, values, true)
            this.$moshaToast("Attendance updated successfully.", {
              type: "success",
            });
            await this.onSave()
            this.onClose()
        }catch(e){
            this.$moshaToast('Unable to update attendance. Please try again.', {type: 'danger'})
        }
        this.inSubmission = false
    },
    setEndTime(){
      this.initVals.end = helper.formatTime(new Date())
    }
  },
  async created(){
    this.initVals = this.$props.attendance
    this.initVals.date = helper.formatDate(new Date(this.$props.attendance.date))
    // this.initVals.start = helper.formatTime(new Date())
    // if(this.initVals.end){
    //     this.initVals.end
    // }
  }
};
</script>