<template>
    <div>
        <div class="grid lg:grid-cols-5 gap-4 overflow-hidden">
            <div class="lg:col-span-1">
                <div class="card p-6 mb-6">
                    <p class="text-lg mb-6">Filter</p>
                    <leave-filter :employees="employees" :types="types" :onFilter="filter" :adminAccess="adminAccess" />
                </div>
                <div class="card" v-if="adminAccess">
                    <div class="mb-6 p-6">
                        <p class="text-lg mb-6">Leave Types</p>
                        <add-leave-type-form v-if="reload" :onSave="onLeaveTypeSave" />
                    </div>
                    <div class="w-full overflow-x-auto py-6">
                        <leave-types-table :types="types" :onUpdate="onLeaveTypeUpdate" />
                    </div>
                </div>
            </div>
            <div class="lg:col-span-4 overflow-hidden">
                <div class="card">
                    <div class="p-6">
                        <button class="btn btn-primary btn-sm" @click="applyLeaveModal = true">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
                            </svg>
                            Apply
                        </button>
                    </div>
                    <div class="w-full overflow-x-auto">
                        <leaves-table :leaves="leaves" :employees="employees" :types="types" :onUpdate="getLeaves" :adminAccess="adminAccess" />
                    </div>
                    <div class="p-6">
                        <table-footer :defaultCount="filters.limit" :next="nextPage" :previous="prevPage" :chnangeCount="limitChnage" :pages="tablePages" />
                    </div>
                </div>
            </div>
        </div>
        
        <modal :active="applyLeaveModal" :close="closeApplyModal">
            <div class="w-full max-w-[384px] mx-auto px-4 lg:px-0">
                <div class="card p-6">
                    <apply-leave-form v-if="applyLeaveModal" :types="types" :update="getLeaves" :close="closeApplyModal" />
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import server from '@/services/server';
import LeavesTable from '@/components/partials/tables/hrm/LeavesTable.vue';
import LeaveFilter from '@/components/partials/filters/hrm/LeaveFilter.vue';
import AddLeaveTypeForm from '@/components/partials/forms/hrm/AddLeaveTypeForm.vue';
import LeaveTypesTable from '@/components/partials/tables/hrm/LeaveTypesTable.vue';
import Modal from '@/components/elements/Modal.vue';
import ApplyLeaveForm from '@/components/partials/forms/hrm/ApplyLeaveForm.vue';
import TableFooter from '@/components/elements/TableFooter.vue';
import local from '@/services/local';


export default {
  components: { LeavesTable, LeaveFilter, AddLeaveTypeForm, LeaveTypesTable, Modal, ApplyLeaveForm, TableFooter },
    name: 'Leaves',
    data(){
        return{
            employees: {},
            leaves: [],
            types: {},
            filters: {
                filter: {
                    status: 0
                },
                sort: {createdAt: -1},
                limit: 50,
                skip: 0
            },
            reload: true,
            applyLeaveModal: false,
            tablePages: 0,
            adminAccess: false
        }
    },
    methods: {
        async getEmployees() {
            try {
                const response = await server.post("/employee/fetch", {}, true);
                for(let i in response.employees){
                    this.employees[response.employees[i]._id] = response.employees[i]
                }
            } catch (e) {
                this.$moshaToast("Unable to get employees. Please try again.", {
                type: "danger",
                });
            }
        },
        async getTypes(){
            try{
                const response = await server.get('/leave/type')
                for(let i in response){
                    this.types[response[i]._id] = response[i]
                }
            }catch(e){
                this.$moshaToast("Unable to get leave types. Please try again.", {type: "danger"});
            }
        },
        async getLeaves(){
            try{
                const url = this.adminAccess ? '/leave/fetch' : '/leave/self/fetch'
                const response = await server.post(url, this.filters, true)
                this.leaves = response.leaves
                this.tablePages = Math.ceil(response.length / this.filters.limit)
            }catch(e){
                this.$moshaToast("Unable to get leaves. Please try again.", {type: "danger"});
            }
        },
        async filter(data){
            this.filters.filter = data.filter
            this.filters.sort = data.sort
            await this.getLeaves()
        },
        async onLeaveTypeSave(){
            this.reload = false
            this.$nextTick(() => {
                this.reload = true
            })
            await this.getTypes()
        },
        async onLeaveTypeUpdate(){
            
            await this.getTypes()
        },
        closeApplyModal(){
            this.applyLeaveModal =  false
        },
        async limitChnage(count){
            this.filters.limit = count
            this.filters.skip = 0
            await this.getLeaves()
        },
        async nextPage(){
            this.filters.skip += this.filters.limit
            await this.getLeaves()
        },
        async prevPage(){
            this.filters.skip -= this.filters.limit
            await this.getLeaves()
        },
        
    },
    async created(){
        const localemp = local.get('_auth').employee
        this.adminAccess = (localemp.is_admin || localemp.access.indexOf('hrm') >= 0)
        this.filters.filter.status = this.adminAccess ? 0 : undefined
        await this.getEmployees()
        await this.getTypes()
        await this.getLeaves()
    }
}
</script>