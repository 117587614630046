<template>
    <p class="text-lg mb-6">Filter</p>
    <div class="text-sm">
        <div class="mat-input mb-6">
            <input type="text" placeholder="Search text" v-model="search" @keyup.enter="triggerChnage" @focusout="triggerChnage">
        </div>
        <div class="mat-input mb-6">
            <label for="">Designation:</label>
            <select v-model="designation" @change="triggerChnage">
                <option value="">All</option>
                <option class="" v-for="designation in designations" :key="designation._id" :value="designation._id">{{titleCase(designation.title)}}</option>
            </select>
        </div>
        <div class="mat-input mb-6">
            <label for="">Status:</label>
            <select v-model="status" @change="triggerChnage">
                <option value="">All</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
            </select>
        </div>
        <div class="mat-input mb-6">
            <label for="">Order:</label>
            <select v-model="order" @change="triggerChnage">
                <option value="-1">Recent</option>
                <option value="1">Oldest</option>
            </select>
        </div>
        <div>
            <button class="btn btn-block btn-white" @click="clearFilter">Clear Filter</button>
        </div>
    </div>
</template>

<script>
import Case from 'case'
export default {
    name: 'EmployeeFilter',
    props: ['designations', 'onChnage'],
    data(){
        return{
            search: '',
            designation: '',
            status: '',
            order: '-1'
        }
    },
    methods: {
        titleCase(text){
            return Case.title(text)
        },
        triggerChnage(){
            const filterData = {sort: {createdAt: this.order}, filter: {}}

            if(this.designation !== ''){
                filterData.filter.designation = this.designation
            }

            if(this.status !== ''){
                filterData.filter.status = this.status
            }

            if(this.search !== ''){
                filterData.filter.$or = [
                    {first_name: {$regex: this.search,  $options: "i"}},
                    {last_name: {$regex: this.search,  $options: "i"}},
                    {email: {$regex: this.search,  $options: "i"}}
                ]
            }

            this.$props.onChnage(filterData)
        },
        clearFilter(){
            this.search = ''
            this.designation = ''
            this.status = ''
            this.order = '-1'
            this.triggerChnage()
        }
    }
}
</script>