<template>
    <span class="flex border rounded-full w-fit bg-gray-200">
        <button class="rounded-l-full px-3 py-1" :class="{'bg-white': active == labels[0]}" @click="onChange(labels[0])">{{labels[0]}}</button>
        <button class="rounded-r-full px-3 py-1" :class="{'bg-white': active == labels[1]}" @click="onChange(labels[1])">{{labels[1]}}</button>
    </span>
</template>

<script>
export default {
    name: 'ToggleButton',
    props: ['labels', 'onToggle'],
    data(){
        return{
            active: null
        }
    },
    methods: {
        onChange(label){
            if(label != this.active){
                this.active = label
                this.$props.onToggle(label)
            }
        }
    },
    created(){
        this.active = this.$props.labels[0]
    }
}
</script>