<template>
    <div class="card p-6">
        <p class="card-heading">Client Statistics</p>
        <div class="flex gap-4">
            <div class="flex gap-4 w-full items-center">
                <button>
                    <span
                        class="h-6 w-6 text-xs flex items-center justify-center rounded-full bg-orange-500 text-white"
                        ><i class="fa-regular fa-hourglass"></i
                    ></span>
                </button>
                <p class="text-sm">Remaining: 8</p>
            </div>
            <div class="flex gap-4 w-full items-center">
                <button>
                    <span
                        class="h-6 w-6 text-xs flex items-center justify-center rounded-full bg-orange-500 text-white"
                        ><i class="fa-light fa-circle-check"></i
                    ></span>
                </button>
                <p class="text-sm">Completed: 4</p>
            </div>
        </div>
        <div class="-mb-4">
            <div ref="client-project-stat"></div>
        </div>
    </div>
</template>

<script>
import ApexCharts from 'apexcharts'
export default {
    name: "ClientProjectsStat",
    props: ["client"],
    mounted(){
        var options = {
        chart: {
            height: 230,
            type: "radialBar"
        },
        
        series: [75],
        
        plotOptions: {
            radialBar: {
            hollow: {
                margin: 15,
                size: "65%",
            },
            
            
            dataLabels: {
                showOn: "always",
                name: {
                offsetY: 0,
                show: false,
                color: "#888",
                fontSize: "13px"
                },
                value: {
                color: "#111",
                fontSize: "20px",
                offsetY: 10,
                show: true
                }
            }
            }
        },
        fill: {
            colors: ['#EA3D0D']
        },
        stroke: {
            lineCap: "round",
        },
            labels: [""]
        };

        var chart = new ApexCharts(this.$refs['client-project-stat'], options);

        chart.render();
    }
};
</script>
