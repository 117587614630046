<template>
    <div>
        <table class="table">
            <thead>
                <tr>
                    <th>Site</th>
                    <th>Password</th>
                    <th>Access</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(password, index) in client.passwords" :key="index">
                    <td>{{ password.site }}</td>
                    <td>{{ password.password }}</td>
                    <td>
                        <a  
                            v-show="password.access"
                            :href="password.access"
                            class="btn-link btn-link-blue"
                            target="_black"
                        >
                            Open
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-4 h-4 inline"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                                />
                            </svg>
                        </a>
                    </td>
                    <td>

                        <button class="btn-link btn-link-red" @click="openDeleteModal(index)">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-5 h-5"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                            </svg>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <delete-modal :active="deleteModal.active" :onConfirm="deleteItem" :onClose="closeDeleteModal" />
    </div>
</template>

<script>
import DeleteModal from '@/components/elements/DeleteModal.vue';
import server from '@/services/server';
import Modal from '@/components/elements/Modal.vue';
export default {
  components: { DeleteModal, Modal },
    name: "ClientPasswordsTable",
    props: ["client", 'onUpdate'],
    data(){
        return{
            deleteModal: {
                active: false,
                index: null
            },
        }
    },
    methods: {
        openDeleteModal(index){
            this.deleteModal = {
                active: true,
                index: index
            }
        },
        closeDeleteModal(){
            this.deleteModal = {
                active: false,
                index: null
            }
        },
        async deleteItem(){
            try{
                await server.delete('/client/remove/passwords/' + this.$props.client._id, {idx: this.deleteModal.index})
                this.closeDeleteModal()
                await this.$props.onUpdate()
                this.$moshaToast(
                    "Password deleted successfully.",
                    { type: "success" }
                );
            }catch(e){
                this.$moshaToast(
                    "Unable to delete password. Please try again.",
                    { type: "danger" }
                );
            }
        }

    }
};
</script>
