<template>
    <vee-form @submit="save" :validation-schema="schema" :initial-values="initValue">
        <div class="grid grid-cols-2 gap-4 mb-8">
            <div class="mat-input">
                <vee-field name="dob" v-slot="{meta, field}">
                    <label for="">Date Of Birth:</label>
                    <input type="date" name="dob" v-bind="field" :class="{ 'is-invalid': !meta.valid && meta.touched }">
                    <error-message name="dob" class="invalid-feedback" />
                </vee-field>
            </div>
            <div class="mat-input">
                <vee-field name="phone" v-slot="{meta, field}">
                    <label for="">Phone:</label>
                    <input type="text" name="phone" placeholder="10 digit phone number" v-bind="field" :class="{ 'is-invalid': !meta.valid && meta.touched }">
                    <error-message name="phone" class="invalid-feedback" />
                </vee-field>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mb-8">
            <div class="mat-input">
                <vee-field name="address_1" v-slot="{meta, field}">
                    <label for="">Address 1:</label>
                    <!-- <input type="textarea" name="address_1" v-bind="field" :class="{ 'is-invalid': !meta.valid && meta.touched }"> -->
                    <textarea placeholder="Current Address" name="address_1" v-bind="field" :class="{ 'is-invalid': !meta.valid && meta.touched }"></textarea>
                    <error-message name="address_1" class="invalid-feedback" />
                </vee-field>
            </div>
            <div class="mat-input">
                <vee-field name="address_2" v-slot="{meta, field}">
                    <label for="">Address 2:</label>
                    <!-- <input type="textarea" name="address_1" v-bind="field" :class="{ 'is-invalid': !meta.valid && meta.touched }"> -->
                    <textarea placeholder="Permanent Address" name="address_2" v-bind="field" :class="{ 'is-invalid': !meta.valid && meta.touched }"></textarea>
                    <error-message name="address_2" class="invalid-feedback" />
                </vee-field>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mb-8">
            <div class="mat-input">
                <vee-field name="date_of_joining" v-slot="{meta, field}">
                    <label for="">Date Of Joining:</label>
                    <input type="date" name="date_of_joining" v-bind="field" :class="{ 'is-invalid': !meta.valid && meta.touched }" :disabled="!admin">
                    <error-message name="date_of_joining" class="invalid-feedback" />
                </vee-field>
            </div>
            <div class="mat-input">
                <vee-field name="date_of_leaving" v-slot="{meta, field}">
                    <label for="">Date Of Leaving:</label>
                    <input type="date" name="date_of_leaving" v-bind="field" :class="{ 'is-invalid': !meta.valid && meta.touched }" :disabled="!admin">
                    <error-message name="date_of_leaving" class="invalid-feedback" />
                </vee-field>
            </div>
        </div>
        <div class="mat-input mb-8">
            <vee-field name="bio" v-slot="{meta, field}">
                <label for="">About:</label>
                <textarea name="bio" v-bind="field" :class="{ 'is-invalid': !meta.valid && meta.touched }" placeholder="Enter some text..."></textarea>
                <error-message name="bio" class="invalid-feedback" />
            </vee-field>
        </div>
        <div>
            <button class="btn btn-primary btn-block" :disabled="inSubmission">
                <span v-show="!inSubmission">Save</span>
                <span class="text-center" v-show="inSubmission">
                    <svg class="animate-spin -ml-1 mr-1 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Please wait...
                </span>
            </button>
        </div>
    </vee-form>
</template>

<script>
import server from '@/services/server'
import helper from '@/services/helper'
export default {
    name: 'UpateProfileForm',
    props: ['profile', 'admin'],
    data(){
        return{
            inSubmission: false,
            schema: {
                phone: 'phone|min:10|max:10'
            },
            initValue: {}
        }
    },
    methods: {
        async save(values){
            const url = this.admin ? '/employee/profile/' + this.$props.profile.employee : '/employee/self/profile'
            this.inSubmission = true
            try{
                await server.patch(url, values, true)
                this.$moshaToast('Profile updated successfully.', {type: 'success'})
            }catch(e){
                this.$moshaToast('Unable to update profile. Please try again.', {type: 'danger'})
            }
            this.inSubmission = false
        },
        setInitVals(){
            this.initValue = {
                ...this.$props.profile
            }
            if(this.initValue.dob){
                this.initValue.dob = helper.formatDate(new Date(this.initValue.dob))
            }

            if(this.initValue.date_of_joining){
                this.initValue.date_of_joining = helper.formatDate(new Date(this.initValue.date_of_joining))
            }
            if(this.initValue.date_of_leaving){
                this.initValue.date_of_leaving = helper.formatDate(new Date(this.initValue.date_of_leaving))
            }
        }
    },
    mounted(){
        this.setInitVals()
    }
}
</script>