<template>
    <div class="card p-6 h-fit">
        <p class="card-heading">Upload Logo</p>
        <div class="mb-6">
            <img :src="imgSrc" class="h-16 mx-auto" alt="">
        </div>
        <div class="text-center">
            <button class="btn btn-primary" @click="chooseImg">
                <span v-show="!inSubmission">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                    </svg>
                    Choose
                </span>
                <span class="text-center" v-show="inSubmission">
                    <svg class="animate-spin -ml-1 mr-1 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Please wait...
                </span>
            </button>
            <input type="file" ref="logo-input" @change="upload" class="hidden">
        </div>
        <p class="text-red-500 text-center mt-6" v-show="error_msg">{{error_msg}}</p>
    </div>
</template>

<script>
import server from '@/services/server'
export default {
    name: 'UpdateClientLogo',
    props: ['client'],
    data(){
        return{
            imgSrc: require('@/assets/images/default-logo.png'),
            inSubmission: false,
            error_msg: null
        }
    },
    methods: {
        chooseImg(){
            this.error_msg = null
            this.$refs['logo-input'].click()
        },
        async upload(event){
            this.inSubmission = true
            try{
                const file = event.target.files[0]
                if(file.size > (1024*512)){
                    this.error_msg = 'Logo size must be less then 512kb.'
                    throw new Error();
                }
                if(file.type.split('/')[0] != 'image'){
                    this.error_msg = 'Please upload only JPG or PNG file.'
                    throw new Error();
                }
                await server.patchUpload('/client/logo/'+ this.$props.client._id, {logo: file})
                this.$moshaToast('Logo uploaded successfully.', {type: 'success'})
                await this.getLogo(Math.random());
            }catch(e){
                this.$moshaToast('Unable to upload logo. Please try again.', {type: 'danger'})
            }
            
            
            this.inSubmission = false
        },
        async getLogo(status = null){
            try{
                await server.get('/client/logo/' + this.$props.client._id)
                this.imgSrc = server.resolve('/client/logo/' + this.$props.client._id + (status ? '?s=' + status : ''))
                // console.log(this.imgSrc)
            }catch(e){
                this.imgSrc = require('@/assets/images/default-logo.png')
            }
        }
    },
    async created(){
        await this.getLogo();
    }
}
</script>