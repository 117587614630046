<template>
  <Teleport to="#modal">
    <div v-if="active" class="modal">
      <div class="modal-wrapper" @click="close"></div>
      <div class="modal-content">
        <slot></slot>
      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  name: "Modal",
  props: ["active", "close"],
};
</script>
