<template>
    <div class="card p-6 h-full">
        <div class="grid grid-cols-3 gap-6 h-full">
            <div class="col-span-1 h-full w-full relative">
                <div class="absolute top-0 w-full">
                    <div class="w-full">
                        <p class="card-heading">About Client</p>
                        <img
                            :src="getLogo()"
                            class="h-24 mx-auto mb-6"
                            alt=""
                        />
                    </div>
                </div>
                <div class="absolute bottom-0 w-full">
                    <div class="w-full">
                        <div
                            class="flex w-full rounded-lg overflow-hidden mb-2"
                        >
                            <span
                                class="w-full h-8 flex justify-center items-center cursor-pointer"
                                v-for="color in client.colors.split(',')"
                                :key="color"
                                :style="{ backgroundColor: color }"
                                @click="copyNow(color)"
                                @mouseover="hoverColor = color"
                                @mouseout="hoverColor = null"
                            ></span>
                        </div>
                        <p class="text-xs text-center h-2">{{ hoverColor }}</p>
                    </div>
                </div>
            </div>
            <div
                class="col-span-2 h-full flex flex-col justify-start items-center"
            >
                <div class="my-auto flex gap-4 items-center w-full">
                    <button @click="copyNow(client.address)">
                        <span
                            class="h-6 w-6 text-xs flex items-center justify-center rounded-full bg-purple-500 text-white"
                            ><i class="fa-light fa-location-dot"></i
                        ></span>
                    </button>
                    <p class="text-sm">{{ client.address }}</p>
                </div>
                <div class="flex gap-4 items-center my-auto w-full">
                    <button @click="copyNow(client.phone)">
                        <span
                            class="h-6 w-6 text-xs flex items-center justify-center rounded-full bg-purple-500 text-white"
                            ><i class="fa-light fa-phone"></i
                        ></span>
                    </button>
                    <p class="text-sm">{{ client.phone }}</p>
                </div>
                <div class="flex gap-4 items-center my-auto w-full">
                    <button @click="copyNow(client.website)">
                        <span
                            class="h-6 w-6 text-xs flex items-center justify-center rounded-full bg-purple-500 text-white"
                            ><i class="fa-light fa-globe"></i
                        ></span>
                    </button>
                    <p class="text-sm">{{ client.website }}</p>
                </div>
                <div class="flex gap-4 items-center my-auto w-full">
                    <button @click="copyNow(client.email)">
                        <span
                            class="h-6 w-6 text-xs flex items-center justify-center rounded-full bg-purple-500 text-white"
                            ><i class="fa-light fa-envelope"></i
                        ></span>
                    </button>
                    <p class="text-sm">{{ client.email }}</p>
                </div>
                <div class="flex gap-4 items-center my-auto w-full">
                    <button @click="copyNow(client.affiliation)">
                        <span
                            class="h-6 w-6 text-xs flex items-center justify-center rounded-full bg-purple-500 text-white"
                            ><i class="fa-light fa-circle-check"></i
                        ></span>
                    </button>
                    <p class="text-sm">{{ client.affiliation }}</p>
                </div>
                <!-- <div class="flex gap-4 items-center mb-4">
                    <span class="h-8 w-8 flex items-center justify-center rounded-full bg-purple-500 text-white cursor-pointer"><i class="fa-light fa-copy"></i></span>
                    <p class="text-sm">+91 {{client.phone}}</p>
                </div>
                <div class="flex gap-4 items-center mb-4">
                    <span class="h-8 w-8 flex items-center justify-center rounded-full bg-purple-500 text-white cursor-pointer"><i class="fa-light fa-copy"></i></span>
                    <p class="text-sm">{{client.website}}</p>
                </div>
                <div class="flex gap-4 items-center mb-4">
                    <span class="h-8 w-8 flex items-center justify-center rounded-full bg-purple-500 text-white cursor-pointer"><i class="fa-light fa-copy"></i></span>
                    <p class="text-sm">{{client.email}}</p>
                </div>
                <div class="flex gap-4 items-center mb-4">
                    <span class="h-8 w-8 flex items-center justify-center rounded-full bg-purple-500 text-white cursor-pointer"><i class="fa-light fa-copy"></i></span>
                    <p class="text-sm">{{client.affiliation}}</p>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import copy from "copy-to-clipboard";
import server from "@/services/server";
export default {
    name: "ClientBasicCard",
    props: ["client"],
    data() {
        return {
            hoverColor: "",
        };
    },
    methods: {
        getLogo() {
            return server.resolve("/client/logo/" + this.client._id);
        },
        copyNow(text) {
            copy(text);
        },
    },
};
</script>
