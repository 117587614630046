import server from "."
import local from "../local"

const setting = {

    async reFetch(){
        const response = await server.get('/setting', true)
        local.save('settings', response)
    }
    
}

export default setting