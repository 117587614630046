<template>
    <div class="text-center">
        <img  :src="imgSrc" class="mx-auto w-32 h-32 rounded-full mb-8" alt="">
        <p class="text-red-500 mb-4" v-show="error_msg !== ''">{{error_msg}}</p>
        <button v-if="uploadedAvatar == null" class="btn btn-primary" @click="chooseAvatar">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 inline">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
            </svg>
            Choose image
        </button>
        <div v-if="uploadedAvatar != null">
            <button class="btn btn-primary mr-3" @click="upload">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 inline">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                </svg>
                Upload
            </button>
            <button class="btn btn-white" @click="clearInput">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 inline">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3" />
                </svg>
                Clear
            </button>
        </div>
        <input type="file" ref="avatarInput" class="hidden" @change="onInputChnage($event.target.files ? $event.target.files[0] : null)" accept="image/png, image/jpeg">
    </div>
</template>

<script>
import server from '@/services/server'
import validate from '@/services/validate'

export default {
    name: 'UploadAvatarForm',
    props: ['employee', 'admin'],
    data(){
        return{
            imgSrc: require('@/assets/images/default-avatar.jpg'),
            uploadedAvatar: null,
            error_msg: ''
        }
    },
    methods: {
        onInputChnage(file){
            this.uploadedAvatar = file
            try{
                if(this.uploadedAvatar){
                    this.imgSrc = URL.createObjectURL(this.uploadedAvatar)
                }
            }catch(e){
                this.$moshaToast('Something wrong. Please try again.', {type: 'danger'})
            }
        },
        chooseAvatar(){
            this.$refs.avatarInput.click()
        },
        clearInput(){
            this.$refs.avatarInput.value = ''
            this.uploadedAvatar = null
            this.imgSrc = server.resolve('/employee/avatar/' + this.$props.employee._id + '?refresh=true')
        },
        async upload(){
            try{
                if(!validate.isImage(this.uploadedAvatar.type)){
                    this.error_msg = 'File must be a jpeg or png image';
                    this.clearInput()
                    return;
                }
                if(!validate.maxSize(this.uploadedAvatar.size, 1024*2)){
                    this.error_msg = 'Image should be less than 2MB';
                    this.clearInput()
                    return;
                }
                const url = this.$props.admin ? '/employee/avatar/'+this.$props.employee._id : '/employee/avatar/self'
                await server.patchUpload(url, {avatar: this.uploadedAvatar}, true)
                this.clearInput()
                this.$moshaToast('Avatar uploaded successfully.', {type: 'success'})
            }catch(e){
                this.$moshaToast('Unable to upload file. Please try again.', {type: 'danger'})
            }
        }
    },
    async mounted(){
        this.imgSrc = server.resolve('/employee/avatar/' + this.$props.employee._id)
    }
}
</script>