import { Form as VeeForm, Field as VeeField, defineRule, ErrorMessage, configure } from 'vee-validate'
import { required, min, max, alpha, alpha_spaces, email, numeric, integer, confirmed, alpha_num } from '@vee-validate/rules'


export default {
    install(app) {
        app.component('VeeForm', VeeForm)
        app.component('VeeField', VeeField)
        app.component('ErrorMessage', ErrorMessage)

        defineRule('required', required)
        defineRule('alpha', alpha)
        defineRule('alphaSpaces', alpha_spaces)
        defineRule('min', min)
        defineRule('max', max)
        defineRule('email', email)
        defineRule('phone', numeric)
        defineRule('numeric', numeric)
        defineRule('passwordConfirmed', confirmed)
        defineRule('avatarRequired', required)
        defineRule('alphaNum', alpha_num)
        defineRule('integer', integer)

        configure({
            generateMessage: (ctx) => {
                const messages = {
                    required: `The field ${ctx.field.replaceAll('_', ' ')} is required.`,
                    alpha: `The field ${ctx.field.replaceAll('_', ' ')} must contains alphabates only.`,
                    alphaSpaces: `The field ${ctx.field.replaceAll('_', ' ')} must contains alphabates & space only.`,
                    min: `The field ${ctx.field.replaceAll('_', ' ')} is too short.`,
                    max: `The field ${ctx.field.replaceAll('_', ' ')} is too long.`,
                    email: `The field ${ctx.field.replaceAll('_', ' ')} is invalid.`,
                    phone: `Invalid phone number.`,
                    numeric: `The field ${ctx.field.replaceAll('_', ' ')} must contains numbers only.`,
                    passwordConfirmed: `Password does not match.`,
                    avatarRequired: `Please choose an image file.`,
                    integer: `The field ${ctx.field.replaceAll('_', ' ')} must contain number only.`,
                    alphaNum: `The field ${ctx.field.replaceAll('_', ' ')} must contains alphabates & numbers only.`
                }
                const message = messages[ctx.rule.name] ? messages[ctx.rule.name] : `The field ${ctx.field} is invalid.`
                return message
            }
        })
    }
}