import axios from "axios";
// import server from "./index";
// import local from "../local";

// const gdrive = {
//     async upload(file, params = null, auth = true){
//         const headers = {
//             headers: {'Content-Type': 'multipart/form-data'}
//         }
//         if(params){
//             headers.params = params
//         }
//         if(auth){
//             const token = local.get('_auth').login.token
//             headers.headers.Authorization = `Bearer ${token}`
//         }
//         const response = await axios.post(server.gdrive_url + '/upload', {file}, headers)
//         return response.data
//     }
// }

// export default gdrive

const icons = {
    "application/postscript": "illustrator.png",
    "image/vnd.adobe.photoshop": "photoshop.png",
    "application/pdf": "pdf.png",
    "image/jpeg": "jpg.png",
    "image/png": "png.png",
    "application/x-gzip": "premiere.png",
    "application/octet-stream": "after-effects.png",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        "xls.png",
    "application/zip": "zip.png",
    "application/x-7z-compressed": "zip.png",
    "audio/mpeg": "mp3.png",
    "video/mp4": "mp4.png",
    "video/x-msvideo": "avi.png",
    "text/csv": "csv.png",
    "application/json": "json-file.png",
    "image/svg+xml": "svg.png",
    "text/plain": "txt.png",
    "text/css": "css.png",
    "application/javascript": "javascript.png",
    "text/html": "html.png",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        "doc.png",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        "ppt.png",
};

const storage = {
    url: "https://app-storage.brandwind.in",

    async upload(file, name) {
        await axios.post(
            this.url,
            { file },
            {
                params: { action: "upload", name },
                headers: { "Content-Type": "multipart/form-data" },
            }
        );
    },

    getThumbnail(file, size) {
        if (file.mime.includes("image") && !file.mime.includes('xml')) {
            return this.url + "/thumbs/" + file._id + "-" + size;
        }
        if(icons[file.mime]){
            return require("@/assets/images/file-icons/" + icons[file.mime]);
        }
        return require("@/assets/images/file-icons/file.png");
    },

    async download(file) {
        const response = await axios({
            url: this.url + "/uploads/" + file._id,
            responseType: "blob",
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.title);
        document.body.appendChild(link);
        link.click();
        link.remove();
    },

    async delete(name){
        await axios.get(this.url, {params: { action: "delete", name }})
    }
};

export default storage;
