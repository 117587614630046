<template>
    <div class="card col-span-4 py-6">
        <div class="px-6 mb-6 flex justify-between items-center">
            <div class="w-full">
                <button
                    class="btn btn-primary"
                    @click="$router.push({ name: 'add-client' })"
                >
                    Create
                </button>
            </div>
            <div class="w-full">
                <div class="w-64 ml-auto">
                    <div class="mat-input">
                        <label for="">Search</label>
                        <input
                            type="search"
                            placeholder="Type and press enter"
                            v-model="search"
                            @keyup.enter="onSearch"
                            @focusout="onSearch"
                        />
                    </div>
                </div>
            </div>
        </div>
        <clients-table :clients="clients" :onUpdate="getClients" />
    </div>
</template>

<script>
import server from "@/services/server";
import ClientsTable from "@/components/partials/tables/crm/ClientsTable.vue";
export default {
    components: { ClientsTable },
    name: "Clients",
    data() {
        return {
            clients: [],
            search: "",
        };
    },
    methods: {
        async getClients() {
            try {
                const response = await server.post("/client/fetch", {});
                this.clients = response.clients;
            } catch (e) {
                this.$moshaToast("Unable to get cleints. Please try again.", {
                    type: "danger",
                });
            }
        },
        async onSearch() {
            try {
                const filter = {
                    $or: [
                        { name: { $regex: this.search, $options: "i" } },
                        { keywords: { $regex: this.search, $options: "i" } },
                        { languages: { $regex: this.search, $options: "i" } },
                        { category: { $regex: this.search, $options: "i" } }
                    ],
                };
                const response = await server.post("/client/fetch", {filter});
                this.clients = response.clients;
            } catch (e) {
                this.$moshaToast("Unable to get cleints. Please try again.", {
                    type: "danger",
                });
            }
        },
    },
    async created() {
        await this.getClients();
    },
};
</script>
