<template>
    <div class="">
        <div class="w-fll max-w-[500px] mx-auto">
            <add-service-form class="mb-6" :onUpdate="getServices" />
            <services-table :services="services" :onUpdate="getServices" />
        </div>
    </div>
</template>

<script>
import AddServiceForm from '@/components/partials/forms/crm/AddServiceForm.vue'
import server from '@/services/server';
import ServicesTable from '@/components/partials/tables/crm/ServicesTable.vue';
export default {
  components: { AddServiceForm, ServicesTable },
    name: 'Services',
    data(){
        return{
            services: []
        }
    },
    methods: {
        async getServices(){
            try{
                const response = await server.post('/service/fetch', {})
                this.services = response.services
            }catch(e){
                this.$moshaToast("Unable to get services. Please try again.", {
                    type: "danger",
                });
            }
        }
    },
    async created(){
        await this.getServices()
    }
}
</script>