<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th>Title</th>
          <th>Edit</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in designations" :key="row._id">
          <td>{{ titleCase(row.title) }}</td>
          <td>
            <button type="button" class="btn-link btn-link-blue" @click="openEditModal(row)">EDIT</button>
          </td>
          <td>
            <button type="button" class="btn-link btn-link-red" @click="openDeleteModal(row._id)">DELETE</button>
          </td>
        </tr>
      </tbody>
    </table>
    <modal v-if="editModal.active" :active="editModal.active" :close="closeEditModal">
        <div class="w-full max-w-[384px] mx-auto">
            <div class="card p-6">
                <update-designation-form :designation="editModal.designation" :onClose="closeEditModal" :onSave="onUpdate" />
            </div>
        </div>
    </modal>
    <delete-modal :active="deleteModal.active" :onClose="closeDeleteModal" :onConfirm="confirmDelete" />
  </div>
</template>

<script>
import Case from "case";
import Modal from '@/components/elements/Modal.vue';
import UpdateDesignationForm from '../../forms/hrm/UpdateDesignationForm.vue';
import DeleteModal from '@/components/elements/DeleteModal.vue';
import server from '@/services/server';
export default {
  components: { Modal, UpdateDesignationForm, DeleteModal },
  name: "DesignationsTable",
  props: ["designations", "onUpdate"],
  data(){
    return {
        editModal: {
            active: false,
            designation: null
        },
        deleteModal: {
            active: false,
            designation: null
        }
    }
  },
  methods: {
    titleCase(text) {
      return Case.title(text);
    },
    closeEditModal(){
        this.editModal.active = false
        this.editModal.designation = null
    },
    openEditModal(designation){
        this.editModal.active = true
        this.editModal.designation = designation
    },
    openDeleteModal(designation){
        this.deleteModal.active = true
        this.deleteModal.designation = designation
    },
    closeDeleteModal(){
        this.deleteModal.active = false
        this.deleteModal.designation = null
    },
    async confirmDelete(){
        if(!this.deleteModal.designation){
            this.closeDeleteModal()
            return
        }
        try{
            await server.delete('/designation/'+this.deleteModal.designation, true)
            this.$moshaToast('Designation deleted successfully.', {type: 'success'})
            this.$props.onUpdate()
        }catch(e){
            this.$moshaToast('Unable to delete designation. Please try again.', {type: 'danger'})
        }
        this.closeDeleteModal()
    }
  },
};
</script>