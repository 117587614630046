<template>
    <modal :active="active" :close="onClose">
        <div class="w-full max-w-[384px] mx-auto px-4 lg:px-0">
            <div class="card p-4">
                <p class="mb-4 text-xl">Are you sure want to delete this item?</p>
                <div class="flex gap-4">
                    <button class="btn btn-danger btn-block" @click="confirm">
                        <span v-show="!inSubmission">Delete</span>
                        <span class="text-center" v-show="inSubmission">
                            <svg class="animate-spin -ml-1 mr-1 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Please wait...
                        </span>
                        
                    </button>
                    <button class="btn btn-white btn-block" @click="onClose">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from './Modal.vue'
export default {
    components: { Modal },
    name: 'DeleteModal',
    props: ['active', 'onClose', 'onConfirm'],
    data(){
        return{
            inSubmission: false
        }
    },
    methods: {
        async confirm(){
            this.inSubmission = true
            await this.$props.onConfirm();
            this.inSubmission = false
        }
    }
}
</script>