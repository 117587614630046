<template>
    <div class="mx-auto w-full max-w-[600px]">
        <div class="card p-4 flex gap-4 items-center">
            <div class="w-full text-lg">You are logged in on <span class="text-red-500 font-bold">{{settings.logins_count}}</span> devices</div>
            <div class="w-full text-center">
                <button class="btn btn-danger w-auto" @click="deleteLogins()">Logout All</button>
            </div>
        </div>
    </div>
</template>

<script>
import server from '@/services/server'
export default {
    name: 'SelfSettings',
    data(){
        return{
            settings: {}
        }
    },
    methods: {
        async getSettings(){
            try{
                this.settings = await server.get('/employee/self/settings')
            }catch(e){
                this.$moshaToast('Unable to get data. Please try again.', {type: 'danger'})
            }
        },
        async deleteLogins(){
            try{
                await server.delete('/employee/self/logoutall')
                this.$moshaToast('Successfully logged out from all devices.', {type: 'success'})
                await this.getSettings()
            }catch(e){
                this.$moshaToast('Unable to delete logins. Please try again.', {type: 'danger'})
            }
        }
    },
    async created(){
        await this.getSettings()
    }
}
</script>