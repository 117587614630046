<template>
    <div class="card p-6 text-sm">
        <p class="card-heading">Basic Details</p>
        <vee-form :initial-values="client" :validation-schema="schema" @submit="save">
                <div class="mat-input mb-6">
                    <vee-field name="name" v-slot="{meta, field}">
                        <label>Name:*</label>
                        <input type="text" v-bind="field" name="name" :class="{ 'is-invalid': !meta.valid && meta.touched }" placeholder="Enter client name"  />
                        <error-message name="name" class="invalid-feedback" />
                    </vee-field>
                </div>
                <div class="mat-input mb-6">
                    <vee-field name="address" v-slot="{meta, field}">
                        <label>Address:</label>
                        <textarea v-bind="field" name="address" :class="{ 'is-invalid': !meta.valid && meta.touched }" placeholder="Enter client full address"></textarea>
                        <error-message name="address" class="invalid-feedback" />
                    </vee-field>
                </div>
                <div class="mat-input mb-6">
                    <vee-field name="website" v-slot="{meta, field}">
                        <label>Website:</label>
                        <input type="text" v-bind="field" name="website" :class="{ 'is-invalid': !meta.valid && meta.touched }" placeholder="https://example.com"  />
                        <error-message name="website" class="invalid-feedback" />
                    </vee-field>
                </div>
                <div class="mat-input mb-6">
                    <vee-field name="email" v-slot="{meta, field}">
                        <label>Email:</label>
                        <input type="email" v-bind="field" name="email" :class="{ 'is-invalid': !meta.valid && meta.touched }" placeholder="someone@example.com"  />
                        <error-message name="email" class="invalid-feedback" />
                    </vee-field>
                </div>
                <div class="mat-input mb-6">
                    <vee-field name="phone" v-slot="{meta, field}">
                        <label>Phone:</label>
                        <input type="email" v-bind="field" name="phone" :class="{ 'is-invalid': !meta.valid && meta.touched }" placeholder="someone@example.com"  />
                        <error-message name="phone" class="invalid-feedback" />
                    </vee-field>
                </div>
                <div class="mat-input mb-6">
                    <vee-field name="about" v-slot="{meta, field}">
                        <label>About:</label>
                        <textarea v-bind="field" name="about" :class="{ 'is-invalid': !meta.valid && meta.touched }" placeholder="A short description"></textarea>
                        <error-message name="about" class="invalid-feedback" />
                    </vee-field>
                </div>
                <div class="mat-input mb-6">
                    <vee-field name="requirements" v-slot="{meta, field}">
                        <label>Requirements:</label>
                        <textarea v-bind="field" name="requirements" :class="{ 'is-invalid': !meta.valid && meta.touched }" placeholder="Basic requirements"></textarea>
                        <error-message name="requirements" class="invalid-feedback" />
                    </vee-field>
                </div>
                <div class="">
                    <button class="btn btn-primary btn-block">
                        <span v-show="!inSubmission">Save</span>
                        <span class="text-center" v-show="inSubmission">
                            <svg class="animate-spin -ml-1 mr-1 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Please wait...
                        </span>
                    </button>
                </div>
            </vee-form>
    </div>
</template>

<script>
import server from '@/services/server'
export default {
    name: 'UpdateClientBasicForm',
    props: ['client'],
    data(){
        return{
            inSubmission: false,
            schema: {
                name: 'required',
                email: 'email'
            }
        }
    },
    methods: {
        save(values){
            this.inSubmission = true
            try{
                server.patch('/client/'+this.$props.client._id, values)
                this.$moshaToast('Client updated successfully.', {type: 'success'})
            }catch(e){
                this.$moshaToast('Unable to update client. Please try again.', {type: 'danger'})
            }
            this.inSubmission = false
        }
    }
}
</script>