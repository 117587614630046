<template>
    <div class="text-sm">
        <div class="mat-input mb-6" v-show="adminAccess">
            <label for="">Employee:</label>
            <select v-model="employee" @change="filter">
                <option value="">All</option>
                <option v-for="emp in employees" :key="emp._id" :value="emp._id">{{emp.first_name}} {{emp.last_name}}</option>
            </select>
        </div>
        <div class="mat-input mb-6">
            <label for="">Date:</label>
            <input type="date" v-model="date" @change="filter">
        </div>
        <!-- <div class="mat-input mb-6">
            <label for="">To:</label>
            <input type="date" v-model="toDate">
        </div> -->

        <div class="mat-input mb-6">
            <label for="">Status:</label>
            <select v-model="status" @change="filter">
                <option value="">All</option>
                <option value="1">Approved</option>
                <option value="0">Pending</option>
                <option value="-1">Rejected</option>
            </select>
        </div>

         <div class="mat-input mb-6">
            <label for="">Type:</label>
            <select v-model="type" @change="filter">
                <option value="">All</option>
                <option v-for="type in types" :key="type._id" :value="type._id">{{type.title}}</option>
            </select>
        </div>

        <div class="mat-input mb-6">
            <label for="">Sort:</label>
            <select v-model="sort" @change="filter">
                <option value="-1">Recent</option>
                <option value="1">Oldest</option>
            </select>
        </div>


        <div>
            <button class="btn btn-block btn-white" @click="clear">Clear Filter</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LeaveFilter',
    props: ['employees', 'types', 'onFilter', 'adminAccess'],
    data(){
        return{
            employee: '',
            date: '',
            status: '0',
            type: '',
            sort: '-1'
        }
    },
    methods: {
        async filter(){
            const filteredData = {sort: {createdAt: this.sort}, filter: {}}
            if(this.employee != ''){
                filteredData.filter.employee = this.employee
            }

            if(this.date != ''){
                filteredData.filter.start = {$lte: this.date}
                filteredData.filter.end = {$gte: this.date}
            }

            if(this.status != ''){
                filteredData.filter.status = this.status
            }

            if(this.type != ''){
                filteredData.filter.type = this.type
            }

            await this.$props.onFilter(filteredData)
        },
        async clear(){
            this.employee = ''
            this.date = ''
            this.status = ''
            this.type = ''
            this.sort = '-1'
            await this.filter()
        }
    },
    created(){
        this.status = this.$props.adminAccess ? 0 : ''
    }
}
</script>