<template>
    <vee-form :validation-schema="schema" @submit="save" v-if="reload">
        <div class="grid grid-cols-2 gap-4 mb-8">
            <div class="mat-input">
                <vee-field name="password" v-slot="{ meta, field }">
                    <label for="">New Password:</label>
                    <input type="password" placeholder="Enter new password" v-bind="field" name="password" :class="{ 'is-invalid': !meta.valid && meta.touched }">
                    <error-message name="password" class="invalid-feedback" />
                </vee-field>
            </div>
            <div class="mat-input">
                <vee-field name="confirm_password" v-slot="{ meta, field }">
                    <label for="">Confirm Password:</label>
                    <input type="password" placeholder="Enter new password" v-bind="field" name="confirm_password" :class="{ 'is-invalid': !meta.valid && meta.touched }">
                    <error-message name="confirm_password" class="invalid-feedback" />
                </vee-field>
            </div>
        </div>
        <div>
            <button class="btn btn-primary btn-block" :disabled="inSubmission">
                <span v-show="!inSubmission">Save</span>
                <span class="text-center" v-show="inSubmission">
                    <svg class="animate-spin -ml-1 mr-1 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Please wait...
                </span>
            </button>
        </div>
    </vee-form>
</template>

<script>
import server from '@/services/server'
export default {
    name: 'UpdatePasswordForm',
    props: ['employee_id', 'admin'],
    data(){
        return {
            inSubmission: false,
            schema: {
                password: 'required|min:8',
                confirm_password: 'required|passwordConfirmed:@password'
            },
            reload: true
        }
    },
    methods: {
        async save(values){
            const url = this.$props.admin ? '/employee/' + this.$props.employee_id : '/employee/self'
            this.inSubmission = true
            try{
                await server.patch(url, {password: values.password})
                this.$moshaToast('Password changed successfully.', {type: 'success'})
                this.reload = false
                this.$nextTick(() => {
                    this.reload = true
                })
            }catch(e){
                this.$moshaToast('Unable change password. Please try again.', {type: 'danger'})
            }
            this.inSubmission = false
        }
    }
}
</script>