<template>
    <div class="card p-6">
        <div class="flex justify-between items-center">
            <p class="card-heading">Files</p>
            <div class="flex items-center gap-4">
                <p v-show="inSubmission">
                    Uploading {{ uploadStatus.completed }}/{{
                        uploadStatus.total
                    }}
                    files
                </p>
                <button class="btn btn-primary" @click="chooseFiles">
                    <span v-show="!inSubmission">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5 mr-2 inline"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                            />
                        </svg>
                        Upload
                    </span>
                    <span class="text-center" v-show="inSubmission">
                        <svg
                            class="animate-spin -ml-1 mr-1 h-5 w-5 inline"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                class="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                            ></circle>
                            <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                        Please wait...
                    </span>
                </button>
                <input
                    type="file"
                    class="hidden"
                    ref="filesInput"
                    @change="upload"
                    multiple
                />
            </div>
        </div>
        <div class="grid grid-cols-2 lg:grid-cols-5 gap-6 py-4">
            <div class="w-full" v-for="file in files" :key="file._id">
                <div class="w-fit mx-auto text-center">
                    <div class="mb-3">
                        <img
                            :src="getThumbnail(file)"
                            class="w-20 mx-auto"
                            alt=""
                        />
                    </div>

                    <div class="text-center mb-2">
                        <p class="">{{ file.title }}</p>
                    </div>
                    <div class="flex justify-center gap-3">
                        <button
                            class="btn btn-primary btn-sm"
                            @click="download(file)"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-5 h-5"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                                />
                            </svg>
                        </button>
                        <button class="btn btn-danger btn-sm" @click="openDeleteModal(file._id)">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <delete-modal :active="deleteModal.active" :onClose="closeDeleteModal" :onConfirm="deleteFile" />
    </div>
</template>

<script>
import server from "@/services/server";
import storage from "@/services/server/storage";
import DeleteModal from '@/components/elements/DeleteModal.vue';
export default {
  components: { DeleteModal },
    name: "ClientFiles",
    props: ["client", "files", "onUpdate"],
    data() {
        return {
            inSubmission: false,
            uploadStatus: { total: 0, completed: 0 },
            deleteModal: {
                active: false,
                id: null
            }
        };
    },
    methods: {
        chooseFiles() {
            this.$refs.filesInput.click();
        },
        async upload(event) {
            this.inSubmission = true;
            try {
                const files = event.target.files;
                // console.log(files)
                // console.dir(files[0])
                // return
                this.uploadStatus.total = files.length;
                this.uploadStatus.completed = 0;

                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    const data = {
                        title: file.name,
                        mime: file.type,
                        size: file.size,
                        folder: "client_files",
                        ref: this.$props.client._id,
                    };
                    const response = await server.post("/file", data, true);
                    // file.name = response._id
                    this.uploadStatus.completed++;
                    await storage.upload(file, response._id);
                }
                await this.$props.onUpdate();
                this.$moshaToast("All files uploaded successfully.", {
                    type: "success",
                });
            } catch (e) {
                this.$moshaToast(
                    "Unable to upload file service. Please try again.",
                    {
                        type: "danger",
                    }
                );
            }
            this.inSubmission = false;
        },
        async download(file) {
            try {
                await storage.download(file);
            } catch (e) {
                this.$moshaToast(
                    "Unable to download. Please try again.",
                    {
                        type: "danger",
                    }
                );
            }
        },
        openDeleteModal(id){
            this.deleteModal = {
                active: true,
                id
            }
        },
        closeDeleteModal(){
            this.deleteModal = {
                active: false,
                id: null
            }
        },
        async deleteFile(){
            try{
                await server.delete('/file/' + this.deleteModal.id)
                await storage.delete(this.deleteModal.id)
                await this.$props.onUpdate()
                this.closeDeleteModal()
                this.$moshaToast(
                    "File deleted successfully.",
                    {
                        type: "success",
                    }
                );
            }catch(e){
                this.$moshaToast(
                    "Unable to delete file. Please try again.",
                    {
                        type: "danger",
                    }
                );
            }
        },
        getThumbnail(file) {
            return storage.getThumbnail(file, 128);
        },
    },
};
</script>
