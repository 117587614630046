<template>
    <div class="text-sm">
        <div class="mat-input mb-6" v-show="adminAccess">
            <label for="">Employee:</label>
            <select v-model="filterData.employee" @change="filter">
                <option value="">All</option>
                <option v-for="emp in employees" :key="emp._id" :value="emp._id">{{emp.first_name}} {{emp.last_name}}</option>
            </select>
        </div>

        <div class="mat-input mb-6">
            <label for="">Date:</label>
            <input type="date" v-model="filterData.date"  @change="filter">
        </div>

        <div class="mat-input mb-6">
            <label for="">Date To:</label>
            <input type="date" v-model="filterData.end"  @change="filter">
        </div>

        <div class="mat-input mb-6">
            <label for="">Order:</label>
            <select v-model="filterData.sort"  @change="filter">
                <option value="-1">Recent</option>
                <option value="1">Oldest</option>
            </select>
        </div>
        <div>
            <button class="btn btn-white btn-block" @click="clearFilter">Clear Filter</button>
        </div>
    </div>
</template>

<script>
import helper from '@/services/helper'
export default {
    name: 'AttendanceFilter',
    props: ['employees', 'onFilter', 'adminAccess'],
    data(){
        return{
            filterData: {
                employee: '',
                date: '',
                end: '',
                sort: '-1'
            }
        }
    },
    methods: {
        async filter(){
            const filteredData = {sort: {date: this.filterData.sort}, filter: {date: this.filterData.date}}
            if(this.filterData.end != ''){
                filteredData.filter.date = {
                    $gte: this.filterData.date,
                    $lte: this.filterData.end
                }
            }

            if(this.filterData.employee != ''){
                filteredData.filter.employee = this.filterData.employee
            }

            await this.$props.onFilter(filteredData)
            
        },

        async clearFilter(){
            this.filterData = {
                employee: '',
                date: helper.formatDate(new Date()),
                end: '',
                sort: '-1'
            }
            await this.filter()
        }
    },
    created(){
        this.filterData.date = helper.formatDate(new Date())
    }
}
</script>