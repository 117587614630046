<template>
    <div class="custom-bar-chart">
        <div class="rows" v-for="series in chartData" :key="series.x">
            <div class="w-28 px-4">{{series.x}}</div>
            <div class="bar h-4">
                <span class="h-4" :style="{width: calculatePercentage(series.y, maxHours) + '%'}"></span>
            </div>
        </div>
        <div class="rows">
            <div class="progress-bar-container">
  <div class="progress-bar-progress"></div>
</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "WorkingHoursProgress",
    props: ["progress", "employees", "holidays"],
    data() {
        return {
            chartData: [],
            maxHours: 0,
            holi_days: 0,
        };
    },
    methods: {
        prepare() {
            for (let i in this.$props.progress.counts) {
                this.chartData.push({
                    x: this.$props.employees[i].first_name,
                    y: (
                        this.$props.progress.counts[i].duration /
                        1000 /
                        (60 * 60)
                    ).toFixed(2),
                });
            }
            this.countHolidays()
            this.maxHours = (this.$props.progress.days-this.holi_days) * 8;
        },
        calculatePercentage(value, total){
            const result = (value / total) * 100;
            return result <= 100 ? result: 100
        },
        countHolidays() {
            let count = 0;
            const currentDate = new Date();
            for (let i in this.$props.holidays) {
                const date = this.$props.holidays[i];
                if (
                    date.getMonth() === currentDate.getMonth() &&
                    date.getFullYear() === currentDate.getFullYear()
                ) {
                    count++;
                }
            }
            this.holi_days = count
        },
    },
    created() {
        this.prepare();
    },
};
</script>


<style scoped>
.progress-bar-container {
  position: relative;
  width: 100%;
  height: 8px;
  background-color: #eee;
  border-radius: 8px;
}

.progress-bar-progress {
  position: absolute;
  height: 100%;
  border-radius: 8px;
  background-image: linear-gradient(to right, red, yellow, green);
  clip-path: inset(0% 100% 0% 0%);
  animation: progress 2s ease-in-out forwards;
}

@keyframes progress {
  from {
    clip-path: inset(0% 100% 0% 0%);
  }
  to {
    clip-path: inset(0% calc(100% - var(--progress)) 0% 0%);
  }
}


</style>