<template>
    <div class="py-2 px-4 flex justify-between">
            <div>
                <select class="mat-input-inline" :value="defaultCount" @change="changeCountHandler($event.target.value)">
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                    <option value="60">60</option>
                    <option value="70">70</option>
                    <option value="80">80</option>
                    <option value="90">90</option>
                    <option value="100">100</option>
                </select>
            </div>
            <div class="flex items-center gap-4">
                <button class="px-2 hover:text-blue-700" @click="prevtPage">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                </button>
                <button class="px-2 hover:text-blue-700" @click="nextPage">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                </button>
            </div>
        </div>
</template>

<script>
export default {
    name: 'TableFooter',
    props: ['next', 'previous', 'defaultCount', 'chnangeCount', 'pages'],
    data(){
        return{
            currentPage: 1
        }
    },
    methods: {
        nextPage(){
            
            if(this.currentPage < this.$props.pages){
                this.currentPage++
                this.$props.next()
            }
        },
        prevtPage(){
            if(this.currentPage > 1){
                this.currentPage--
                this.$props.previous()
            }
        },
        changeCountHandler(value){
            this.currentPage = 1
            this.chnangeCount(parseInt(value))
        }
    }
}
</script>