<template>
    <div class="main-body">
        <!-- SIDEBAR -->
        <sidebar
            :onToggle="onToggle"
            :state="sidebarState"
            :chnageState="chnageState"
        />
        <div
            class="sidebar-backdrop"
            :class="{ active: sidebarState }"
            @click="sidebarState = false"
        ></div>
        <!-- SIDEBAR -->

        <!-- NAVBAR -->
        <navbar :state="sidebarState" :toggleSidebar="onToggle" />
        <!-- NAVBAR -->

        <!-- BODY -->
        <div class="body">
            <!-- <p class="text-xl mb-6">{{ $route.meta.title }}</p> -->
            <router-view />
        </div>
        <!-- BODY -->
    </div>
</template>

<script>
import Sidebar from "@/components/partials/frame/Sidebar.vue";
import Navbar from "@/components/partials/frame/Navbar.vue";
import setting from "@/services/server/setting";
export default {
    components: { Sidebar, Navbar },
    name: "Frame",
    props: ["ready"],
    data() {
        return {
            sidebarState: window.screen.width >= 1020,
            notifications: {},
            notificationInterval: null,
        };
    },
    methods: {
        onToggle() {
            this.sidebarState = !this.sidebarState;
        },
        windResize() {
            if (window.screen.width < 1020) {
                this.sidebarState = false;
            }else{
                this.sidebarState = true;
            }
        },
        chnageState() {
            if (window.screen.width < 1020) {
                this.sidebarState = false;
            }
        },
    },
    async created() {
        await setting.reFetch();
        this.windResize();
        window.addEventListener("resize", this.windResize);
        this.$props.ready();
    },
};
</script>
