<template>
    <div class="card block calender p-6 h-full">
        <p class="card-heading">Calender</p>
        <div class="w-full mb-6">
            <div
                class="py-2 mb-4 flex items-center justify-between text-base font-bold"
            >
                <span>{{ currentMonth() }}</span>
                <span>{{ readDay() }}</span>
            </div>
            <div class="flex justify-evenly font-bold">
                <div
                    class="w-full min-w-[40px] h-fit p-1 flex items-center justify-center"
                >
                    <div class="box">Sun</div>
                </div>
                <div
                    class="w-full min-w-[40px] h-fit p-1 flex items-center justify-center"
                >
                    <div class="box">Mon</div>
                </div>
                <div
                    class="w-full min-w-[40px] h-fit p-1 flex items-center justify-center"
                >
                    <div class="box">Tue</div>
                </div>
                <div
                    class="w-full min-w-[40px] h-fit p-1 flex items-center justify-center"
                >
                    <div class="box">Wed</div>
                </div>
                <div
                    class="w-full min-w-[40px] h-fit p-1 flex items-center justify-center"
                >
                    <div class="box">Thu</div>
                </div>
                <div
                    class="w-full min-w-[40px] h-fit p-1 flex items-center justify-center"
                >
                    <div class="box">Fri</div>
                </div>
                <div
                    class="w-full min-w-[40px] h-fit p-1 flex items-center justify-center"
                >
                    <div class="box">Sat</div>
                </div>
            </div>
            <div class="divide-y">
                <div
                    v-for="row in dates"
                    :key="row"
                    class="flex justify-evenly divide-x"
                >
                    <div
                        class="w-full min-w-[40px] h-fit p-1"
                        v-for="dt in row"
                        :key="dt"
                    >
                        <button
                            v-if="dt"
                            class="box mx-auto"
                            :class="{
                                active: activeDate(dt),
                                holiday: isDateInArray(dt, holidays),
                                birthday: isDateInArray(dt, birthdays),
                                current: currentDate(dt),
                                other: isDateInArray(dt, others),
                            }"
                            @click="showEvent(dt)"
                        >
                            {{ dt.getDate() }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- <hr class="mb-4"> -->
        <div class="w-full text-base px-4">
            <div
                class="py-2"
                v-for="event in events[eventToShow.getDate()]"
                :key="event"
            >
                <i class="fa-solid fa-calendar-check mr-2"></i>{{ event }}
            </div>
        </div>
    </div>
</template>

<script>
import helper from "@/services/helper";
import server from "@/services/server";
export default {
    name: "Calender",
    props: ["employees"],
    data() {
        return {
            dates: [],
            active: new Date(),
            holidays: [],
            birthdays: [],
            others: [],
            offsets: {
                start: 0,
                end: 0,
            },
            eventToShow: new Date(),
            events: {},
        };
    },
    methods: {
        generateMonthDates() {
            const now = new Date();
            const daysInMonth = new Date(
                now.getFullYear(),
                now.getMonth() + 1,
                0
            ).getDate();
            const firstDay = new Date(
                now.getFullYear(),
                now.getMonth(),
                1
            ).getDay();
            const calendar = [[]];
            let week = 0;

            // Add null values for days before the start of the month
            let offsetsStart = 0;
            for (let i = 0; i < firstDay; i++) {
                offsetsStart++;
                calendar[week].push(null);
            }

            // Add each day of the month to the calendar
            for (let i = 1; i <= daysInMonth; i++) {
                if (calendar[week].length === 7) {
                    week++;
                    calendar[week] = [];
                }
                calendar[week].push(
                    new Date(now.getFullYear(), now.getMonth(), i)
                );
                this.events[i] = [];
            }

            // Add null values for days after the end of the month
            let offsetEnd = 0;
            while (calendar[week].length < 7) {
                offsetEnd++;
                calendar[week].push(null);
            }
            this.dates = calendar;
            this.offsets = {
                start: offsetsStart,
                end: offsetEnd,
            };
        },
        activeDate(date) {
            return helper.compareDates(this.active, date) == 0;
        },
        currentDate(date) {
            return helper.compareDates(date, new Date()) == 0;
        },
        currentMonth() {
            const date = new Date();
            return `${
                helper.monthNames[date.getMonth()]
            }, ${date.getFullYear()}`;
        },
        readDay() {
            const date = new Date();
            return `${helper.weekDays[date.getDay()]}`;
        },
        isDateInArray(date, array) {
            const dateToCheck = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
            );

            for (let i = 0; i < array.length; i++) {
                const arrayDate = new Date(array[i]);
                const dateInArray = new Date(
                    arrayDate.getFullYear(),
                    arrayDate.getMonth(),
                    arrayDate.getDate()
                );

                if (dateToCheck.getTime() === dateInArray.getTime()) {
                    return true;
                }
            }

            return false;
        },
        getDates(startDate, endDate) {
            let dates = [];
            let currentDate = new Date(startDate);

            while (currentDate <= endDate) {
                dates.push(new Date(currentDate));
                currentDate.setDate(currentDate.getDate() + 1);
            }

            return dates;
        },
        async fetchHolidays() {
            const endWeek = this.dates[this.dates.length - 1];
            try {
                const filter = {
                    date: {
                        $gte: helper.formatDate(
                            this.dates[0][this.offsets.start]
                        ),
                        $lte: helper.formatDate(
                            endWeek[endWeek.length - (this.offsets.end + 1)]
                        ),
                    },
                };
                const response = await server.post("/holiday/fetch", {
                    filter,
                });
                let holidays = [];
                for (let i in response) {
                    if (!response[i].end) {
                        holidays.push(new Date(response[i].date));
                        const eventStr = `${
                            response[i].title
                        } on ${helper.getDateString(
                            new Date(response[i].date)
                        )}`;
                        this.events[new Date(response[i].date).getDate()].push(
                            eventStr
                        );
                    } else {
                        const dates = this.getDates(
                            new Date(response[i].date),
                            new Date(response[i].end)
                        );
                        for (let d in dates) {
                            const eventStr = `${
                                response[i].title
                            }, from ${helper.getDateString(
                                new Date(response[i].date)
                            )} to ${helper.getDateString(
                                new Date(response[i].end)
                            )}`;
                            this.events[dates[d].getDate()].push(eventStr);
                        }
                        holidays = [...holidays, ...dates];
                    }
                }
                this.holidays = holidays;
                // this.$props.setHolidays(holidays)
            } catch (e) {
                this.$moshaToast(
                    "Unable to get holidaydata. Please try again.",
                    {
                        type: "danger",
                    }
                );
            }
        },
        showEvent(date) {
            // console.log(this.birthdays)
            this.active = date;
            this.eventToShow = date;
            // console.log(this.events[date.getDate()])
        },
        async getBirthdays() {
            try {
                // console.log(this.$props.employees)
                // console.log(filter)
                const response = await server.post(
                    "/employee/profile/fetch",
                    {}
                );
                // console.log(response)
                for (let i in response.profiles) {
                    const dob = new Date(response.profiles[i].dob);
                    if (dob.getMonth() === new Date().getMonth()) {
                        this.birthdays.push(
                            new Date(response.profiles[i].dob).setFullYear(
                                new Date().getFullYear()
                            )
                        );
                        const employee =
                            this.$props.employees[
                                response.profiles[i].employee
                            ];
                        const eventStr = `Birthday of ${employee.first_name}`;
                        this.events[
                            new Date(response.profiles[i].dob).getDate()
                        ].push(eventStr);
                    }
                }
            } catch (e) {
                this.$moshaToast("Unable to get birthdays. Please try again.", {
                    type: "danger",
                });
            }
        },
        setDefaultEvent() {
            const today = new Date();
            while (this.events[today.getDate()].length == 0) {
                today.setDate(today.getDate() + 1);
                const month = new Date().getMonth();
                if (today.getMonth() > month) {
                    break;
                }
            }
            this.eventToShow = today;
        },
    },
    async created() {
        this.generateMonthDates();
        await this.fetchHolidays();
        await this.getBirthdays();
        this.setDefaultEvent();
    },
};
</script>
