<template>
    <div class="card p-6">
        <p class="card-heading">Social Links</p>
        <div class="flex justify-center gap-6">
            <a v-for="social in client.passwords" :key="social.access" :href="social.access" target="_blank">
                <img v-if="checkSite(social.site, 'f')" src="@/assets/images/social-icons/facebook.png" class="w-10" alt="">
                <img v-if="checkSite(social.site, 'i')" src="@/assets/images/social-icons/instagram.png" class="w-10" alt="">
                <img v-if="checkSite(social.site, 'y')" src="@/assets/images/social-icons/youtube.png" class="w-10" alt="">
                <img v-if="checkSite(social.site, 't')" src="@/assets/images/social-icons/twitter.png" class="w-10" alt="">
            </a>
            <!-- <a href="" target="_blank">
                <img src="@/assets/images/social-icons/instagram.png" class="w-12" alt="">
            </a>
             <a href="" target="_blank">
                <img src="@/assets/images/social-icons/twitter.png" class="w-12" alt="">
            </a>
             <a href="" target="_blank">
                <img src="@/assets/images/social-icons/youtube.png" class="w-12" alt="">
            </a> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'ClientSocialLink',
    props: ['client'],
    methods: {
        checkSite(site, letter){
            return site.toLowerCase()[0] == letter
        }
    }
}
</script>