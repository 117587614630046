<template>
    <vee-form v-if="reloadForm" @submit="save" :initial-values="initialValues" :validation-schema="schema">
       <div class="mat-input mb-6">
            <vee-field name="service" v-slot="{ meta, field }">
                <label for="">Select Service:*</label>
                <select name="service" v-bind="field" :class="{ 'is-invalid': !meta.valid && meta.touched }">
                    <option value="">Select a service</option>
                    <option v-for="service in services" :key="service._id" :value="service._id">{{service.title}}</option>
                </select>
                <error-message name="service" class="invalid-feedback" />
            </vee-field>
        </div>
        <div class="grid lg:grid-cols-4 lg:gap-3 mb-6">

            <div class="mat-input">
                <vee-field name="weekly_target" v-slot="{ meta, field }">
                    <label for="">Weekly Target:*</label>
                    <input
                        type="text"
                        name="weekly_target"
                        v-bind="field"
                        :class="{ 'is-invalid': !meta.valid && meta.touched }"
                    />
                    <error-message name="weekly_target" class="invalid-feedback" />
                </vee-field>
            </div>

            <div class="mat-input">
                <vee-field name="monthly_target" v-slot="{ meta, field }">
                    <label for="">Monthly Target:*</label>
                    <input
                        type="text"
                        name="monthly_target"
                        v-bind="field"
                        :class="{ 'is-invalid': !meta.valid && meta.touched }"
                    />
                    <error-message name="monthly_target" class="invalid-feedback" />
                </vee-field>
            </div>

            <div class="mat-input">
                <vee-field name="quarterly_target" v-slot="{ meta, field }">
                    <label for="">Quarterly Target:*</label>
                    <input
                        type="text"
                        name="quarterly_target"
                        v-bind="field"
                        :class="{ 'is-invalid': !meta.valid && meta.touched }"
                    />
                    <error-message name="quarterly_target" class="invalid-feedback" />
                </vee-field>
            </div>

             <div class="mat-input">
                <vee-field name="annual_target" v-slot="{ meta, field }">
                    <label for="">Annual Target:*</label>
                    <input
                        type="text"
                        name="annual_target"
                        v-bind="field"
                        :class="{ 'is-invalid': !meta.valid && meta.touched }"
                    />
                    <error-message name="annual_target" class="invalid-feedback" />
                </vee-field>
            </div>
        </div>
        <div>
            <button type="submit" class="btn btn-block btn-primary">
                <span v-show="!inSubmission">Add</span>
                    <span class="text-center" v-show="inSubmission">
                        <svg class="animate-spin -ml-1 mr-1 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Please wait...
                    </span>
            </button>
        </div>
    </vee-form>
</template>

<script>
import server from '@/services/server';
export default {
    name: 'AddClientServiceForm',
    props: ['services', 'client', 'onUpdate'],
    data(){
        return{
            initialValues: {
                service: '',
                weekly_target: 0,
                monthly_target: 0,
                quarterly_target: 0,
                annual_target: 0,
            },
            schema: {
                service: 'required',
                weekly_target: 'numeric',
                monthly_target: 'numeric',
                quarterly_target: 'numeric',
                annual_target: 'numeric'
            },
            inSubmission: false,
            reloadForm: true
        }
    },
    methods: {
        async save(values){
            this.inSubmission = true
            
            try{
                await server.patch('/client/service/' + this.$props.client._id, values)
                await this.$props.onUpdate()
                this.$moshaToast("Service added successfully.", {
                    type: "success",
                });
            }catch(e){
                this.$moshaToast("Unable to add services. Please try again.", {
                    type: "danger",
                });
            }
            this.inSubmission = false
            this.reloadForm = false
            this.$nextTick(() => {
                this.reloadForm = true
            })
        }
    },
    async created(){
        
    }
}
</script>