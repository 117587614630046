<template>
    <div>
        <div class="grid lg:grid-cols-2 gap-6">
            <div>
                <div class="card p-6 h-fit overflow-hidden mb-6">
                    <div class="grid grid-cols-2 gap-2 lg:gap-4 mb-6">
                        <div>
                            <label for="">Employee Last Index:</label>
                        </div>
                        <div class="mat-input">
                            <input
                                type="text"
                                v-model="configs.last_employee_index"
                            />
                        </div>
                    </div>

                    <div class="grid grid-cols-2 gap-2 lg:gap-4 mb-6">
                        <div>
                            <label for="">Employee Id Prefix:</label>
                        </div>
                        <div class="mat-input">
                            <input
                                type="text"
                                v-model="configs.employee_id_prefix"
                            />
                        </div>
                    </div>

                    <div class="grid grid-cols-2 gap-2 lg:gap-4 mb-6">
                        <div>
                            <label for="">Allowed Leaves:</label>
                        </div>
                        <div class="mat-input">
                            <input
                                type="text"
                                v-model="configs.allowed_leave"
                            />
                        </div>
                    </div>

                    <div>
                        <button class="btn btn-primary btn-block" :disabled="inSubmission" @click="save">
                            <span v-show="!inSubmission">Save</span>
                            <span class="text-center" v-show="inSubmission">
                                <svg
                                    class="animate-spin -ml-1 mr-1 h-5 w-5 inline"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        class="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        stroke-width="4"
                                    ></circle>
                                    <path
                                        class="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Please wait...
                            </span>
                        </button>
                    </div>
                </div>

                <div class="card p-6">
                    <div class="flex items-center justify-between mb-6">
                        <p class="card-heading">Attendence Hash</p>
                        <button class="btn btn-primary" @click="generateHash" :disabled="inSubmission">
                           <span v-show="!inSubmission">New</span>
                            <span class="text-center" v-show="inSubmission">
                                <svg
                                    class="animate-spin -ml-1 mr-1 h-5 w-5 inline"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        class="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        stroke-width="4"
                                    ></circle>
                                    <path
                                        class="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <div class="divide-y">
                        <div
                            class="flex items-center gap-4 py-2"
                            v-for="hash in hashes"
                            :key="hash"
                        >
                            <span class="w-full">{{ hash.key }}</span>
                            <span class="w-full">{{
                                formatDate(hash.createdAt)
                            }}</span>
                            <button class="btn-link btn-link-red" @click="openHashDelete(hash._id)">
                                <i class="fa-regular fa-trash-can"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card p-6 h-fit overflow-hidden">
                <p class="text-lg mb-6">Holidays</p>
                <form @submit="addHoliday">
                    <div class="mb-4 flex gap-4">
                        <div class="mat-input">
                            <label for="">Date:*</label>
                            <input
                                type="date"
                                v-model="holidayForm.date"
                                required
                            />
                        </div>

                        <div class="mat-input">
                            <label for="">End Date:</label>
                            <input type="date" v-model="holidayForm.end" />
                        </div>

                        <div class="mat-input hidden lg:block">
                            <label for="">Title:*</label>
                            <input
                                type="text"
                                placeholder="Enter title"
                                v-model="holidayForm.title"
                                required
                            />
                        </div>
                    </div>
                    <div class="mat-input block lg:hidden mb-6">
                        <label for="">Title:*</label>
                        <input
                            type="text"
                            placeholder="Enter title"
                            v-model="holidayForm.title"
                            required
                        />
                    </div>
                    <div class="mb-8">
                        <button class="btn-primary btn mr-3" type="subnit">
                            <span v-show="!inSubmission">Save</span>
                            <span class="text-center" v-show="inSubmission">
                                <svg
                                    class="animate-spin -ml-1 mr-1 h-5 w-5 inline"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        class="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        stroke-width="4"
                                    ></circle>
                                    <path
                                        class="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Please wait...
                            </span>
                        </button>
                        <button
                            type="button"
                            class="btn-white btn mr-3"
                            @click="reset"
                        >
                            Reset
                        </button>
                    </div>
                </form>
                <div class="overflow-hidden">
                    <div class="py-2 w-24">
                        <div class="mat-input">
                            <select
                                @change="reFetchHolidays($event.target.value)"
                            >
                                <option value="2023">2023</option>
                                <option value="2022">2022</option>
                            </select>
                        </div>
                    </div>
                    <div class="w-full overflow-x-auto">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Date</th>
                                    <th>End Date</th>
                                    <th>Days</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="row in holidays" :key="row._id">
                                    <td>{{ row.title }}</td>
                                    <td>{{ formatDate(row.date) }}</td>
                                    <td>
                                        <span v-show="row.end">{{
                                            formatDate(row.end)
                                        }}</span>
                                    </td>
                                    <td>{{ calcDays(row.date, row.end) }}</td>
                                    <td>
                                        <button
                                            class="btn-link btn-link-red"
                                            @click="openDeleteModal(row._id)"
                                        >
                                            DELETE
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <delete-modal
            :active="deleteModal.active"
            :onClose="closeDeleteModal"
            :onConfirm="confirmDelete"
        />
        <delete-modal
            :active="hashDelete.active"
            :onClose="closeHashDelete"
            :onConfirm="deleteHash"
        />
    </div>
</template>

<script>
import local from "@/services/local";
import server from "@/services/server";
import setting from "@/services/server/setting";
import helper from "@/services/helper";
import DeleteModal from "@/components/elements/DeleteModal.vue";
export default {
    components: { DeleteModal },
    name: "HrmSettings",
    data() {
        return {
            configs: local.get("settings").hrm,
            inSubmission: false,
            holidayForm: {
                date: "",
                end: "",
                title: "",
            },
            fetchYear: null,
            holidays: [],
            deleteModal: {
                active: false,
                id: null,
            },
            hashes: [],
            hashDelete: {
                active: false,
                id: null,
            },
        };
    },
    methods: {
        async save() {
            this.inSubmission = true;
            try {
                await server.patch("/setting/save/hrm", this.configs);
                await setting.reFetch();
                this.$moshaToast("Setting saved successfully.", {
                    type: "success",
                });
            } catch (e) {
                this.$moshaToast("Unable to save settings. Please try again.", {
                    type: "danger",
                });
            }
            this.inSubmission = false;
        },

        async reset() {
            this.holidayForm = {
                date: "",
                end: "",
                title: "",
            };
        },

        async getHolidays() {
            const year = this.fetchYear
                ? this.fetchYear
                : new Date().getFullYear();
            const dateFilter = {
                $gte: `${year}-01-01`,
                $lte: `${year}-12-31`,
            };
            try {
                const response = await server.post("/holiday/fetch", {
                    filter: { date: dateFilter },
                    sort: { date: 1 },
                });
                this.holidays = response;
            } catch (e) {
                this.$moshaToast("Unable to get holidays. Please try again.", {
                    type: "danger",
                });
            }
        },

        async addHoliday(e) {
            e.preventDefault();
            this.inSubmission = true;
            try {
                await server.post("/holiday", this.holidayForm, true);
                this.$moshaToast("Holiday added successfully.", {
                    type: "success",
                });
                this.reset();
                await this.getHolidays();
            } catch (e) {
                this.$moshaToast("Unable to add holiday. Please try again.", {
                    type: "danger",
                });
            }
            this.inSubmission = false;
        },
        formatDate(date) {
            return helper.formatDate(new Date(date));
        },
        calcDays(start, end) {
            // console.log(start, end)
            if (!end) {
                return 1;
            }
            return (
                helper.getDaysBetweenDates(new Date(start), new Date(end)) + 1
            );
        },
        async reFetchHolidays(value) {
            this.fetchYear = value;
            await this.getHolidays();
        },
        openDeleteModal(id) {
            this.deleteModal = {
                active: true,
                id: id,
            };
        },
        closeDeleteModal() {
            this.deleteModal = {
                active: false,
                id: null,
            };
        },
        async confirmDelete() {
            try {
                await server.delete("/holiday/" + this.deleteModal.id);
                this.$moshaToast("Holiday deleted successfully.", {
                    type: "success",
                });
                this.closeDeleteModal();
                await this.getHolidays();
            } catch (e) {
                this.$moshaToast(
                    "Unable to delete holiday. Please try again.",
                    { type: "danger" }
                );
            }
        },
        async getHashes() {
            try {
                this.hashes = await server.get("/attendance/hash/all");
            } catch (e) {
                this.$moshaToast("Unable to get hashes. Please try again.", {
                    type: "danger",
                });
            }
        },
        async generateHash() {
            try {
                const response = await server.get("/attendance/hash/generate");
                this.hashes.push(response);
            } catch (e) {
                this.$moshaToast("Unable to generate hash. Please try again.", {
                    type: "danger",
                });
            }
        },
        openHashDelete(id) {
            this.hashDelete = {
                active: true,
                id,
            };
        },
        closeHashDelete() {
            this.hashDelete = {
                active: false,
                id: null,
            };
        },
        async deleteHash() {
            try {
                await server.delete('/attendance/hash/' + this.hashDelete.id)
                this.closeHashDelete()
                await this.getHashes()
            } catch (e) {
                this.$moshaToast("Unable to delete hash. Please try again.", {
                    type: "danger",
                });
            }
        },
    },
    async created() {
        await this.getHolidays();
        await this.getHashes();
    },
};
</script>
