<template>
   <div>
        <div class="mb-6">
            <div v-for="m in modules" :key="m.id" class="mat-checkbox">
                <input type="checkbox" @change="onchange($event.target.checked, m.id)" :checked="employee.access.indexOf(m.id) > -1">
                <label for="">{{m.title}}</label>
            </div>
        </div>
        <div>
            <button class="btn btn-primary btn-block" :disabled="inSubmission" @click="Save">
                <span v-show="!inSubmission">Save</span>
                <span class="text-center" v-show="inSubmission">
                    <svg class="animate-spin -ml-1 mr-1 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Please wait...
                </span>
            </button>
        </div>
   </div>
</template>

<script>
import server from '@/services/server'
export default {
    name: 'UpdateEmployeeAccessForm',
    props: ['employee'],
    data(){
        return{
            modules: [
                {
                    id: 'hrm',
                    title: 'Human Resource'
                },
                {
                    id: 'notices',
                    title: 'Notices'
                },
                {
                    id: 'crm',
                    title: 'Client Management'
                },
            ],
            selected: [],
            inSubmission: false
        }
    },
    methods: {
        onchange(event, module_id){
            if(event){
                this.selected.push(module_id)
            }else{
                const index = this.selected.indexOf(module_id)
                if(index >= 0){
                    this.selected.splice(index, 1)
                }
            }
        },
        async Save(){
            try{
                await server.patch('/employee/' + this.employee._id, {access: this.selected})
                this.$moshaToast('Employee access updated successfully..', {type: 'success'})
            }catch(e){
                this.$moshaToast('Unable to update access. Please try again.', {type: 'danger'})
            }
        }
    }
}
</script>