<template>
    <div class="card p-6 col-span-2 mb-6 lg:mb-0">
        <div class="flex justify-between items-center">
            <span class="w-full"
                ><p class="card-heading pb-6">Working Hours</p></span
            >
            <toggle-button
                :labels="['Monthly', 'Weekly']"
                :onToggle="toggleWorkingHoursData"
                class="text-sm"
            />
        </div>
        <div ref="monthlyWorkingHoursRef">
            <!-- <monthly-hours-progress
          v-if="monthlyAttendances.counts"
          :progress="monthlyAttendances"
          :employees="allEmployees"
        /> -->
            <div ref="monthly-progress-chart" class="w-full h-full"></div>
            <!-- <working-hours-progress v-if="monthlyAttendances.counts && holidaysOfMonth.length > 0"
          :progress="monthlyAttendances"
          :employees="allEmployees" :holidays="holidaysOfMonth" /> -->
        </div>
    </div>
</template>

<script>
import server from "@/services/server";
import ApexCharts from "apexcharts";
import ToggleButton from "@/components/elements/ToggleButton.vue";
import helper from '@/services/helper';
export default {
    components: { ToggleButton },
    name: "MonthlyHoursProgress",
    props: ["employees"],
    data() {
        return {
            chartData: [],
            monthlyAttendances: {},
            chart: null
        };
    },
    methods: {
        async toggleWorkingHoursData(toggle) {
            this.chartData = []
            try {
                // this.monthlyAttendances.counts = false
                const type = toggle.toLowerCase();
                 const response = await server.get(
                    "/dashboard/attendances/" + type
                );
                response.counts = helper.sortObjectKeys(response.counts)
                this.monthlyAttendances = response
                // console.log(this.monthlyAttendances.counts)
                this.generateData();
                this.updateChart()
                // console.log(this.chartData)
            } catch (e) {
                this.$moshaToast("Unable to get data. Please try again.", {
                    type: "danger",
                });
            }
        },
        generateData() {
            const chartData = []
            for (let i in this.monthlyAttendances.counts) {
                chartData.push({
                    x: this.$props.employees[i].first_name,
                    y: (
                        this.monthlyAttendances.counts[i].duration /
                        1000 /
                        (60 * 60)
                    ).toFixed(2),
                    // fillColor: "#BA27A3",
                });
            }
            // console.log(chartData)
            this.chartData = chartData
        },
        mountChart() {
            const options = {
                chart: {
                    type: "bar",
                    height: 400,
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                series: [
                    {
                        name: "Hours",
                        data: [],
                    },
                ],
                fill: {
                    type: "gradient",
                    gradient: {
                        // shade: "dark",
                        type: "horizontal",
                        shadeIntensity: 1,
                        // gradientToColors: ["#4CAF50"],
                        inverseColors: false,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100],
                        colorStops: [
                            {
                                offset: 0,
                                color: "#D4770B", // start color
                                opacity: 1,
                            },
                            {
                                offset: 100,
                                color: "#EC4898", // end color
                                opacity: 1,
                            },
                        ],
                    },
                },
            };

            this.chart = new ApexCharts(
                this.$refs["monthly-progress-chart"],
                options
            );

            this.chart.render();
        },
        updateChart() {
            const options = {
                chart: {
                    type: "bar",
                    height: 400,
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                series: [
                    {
                        name: "Hours",
                        data: this.chartData,
                    },
                ],
                fill: {
                    type: "gradient",
                    gradient: {
                        // shade: "dark",
                        type: "horizontal",
                        shadeIntensity: 1,
                        // gradientToColors: ["#4CAF50"],
                        inverseColors: false,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100],
                        colorStops: [
                            {
                                offset: 0,
                                color: "#D4770B", // start color
                                opacity: 1,
                            },
                            {
                                offset: 100,
                                color: "#EC4898", // end color
                                opacity: 1,
                            },
                        ],
                    },
                },
            };

            // this.chart = new ApexCharts(
            //     this.$refs["monthly-progress-chart"],
            //     options
            // );

            this.chart.updateOptions(options);
        },
    },
    async created() {
        // console.log('created')
        // console.log(this.$props.employees)
        // console.log(this.chartData);
        
        // console.log(this.chartData);
    },
    async mounted() {
        this.mountChart()
        await this.toggleWorkingHoursData("Monthly");
        
    }
};
</script>
