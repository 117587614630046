import { createRouter, createWebHistory } from 'vue-router'
import server from '@/services/server'
import local from '@/services/local'

import Login from '@/views/auth/Login'
import Dashboard from '@/views/pages/Dashboard'
import Employees from '@/views/hrm/Employees'
import UpdateEmployee from '@/views/hrm/UpdateEmployee'
import Designations from '@/views/hrm/Designations'
import Attendances from '@/views/hrm/Attendances'
import Leaves from '@/views/hrm/Leaves'
import HrmSettings from '@/views/hrm/Settings'
import Profile from '@/views/self/Profile'
import UpdateSelf from '@/views/self/UpdateSelf'
import SelfSettings from '@/views/self/Settings'
import About from '@/views/pages/About'
import Notices from '@/views/pages/Notices'
import Clients from '@/views/crm/Clients'
import AddClient from '@/views/crm/AddClient'
import UpdateClient from '@/views/crm/UpdateClient'
import Services from '@/views/crm/Services'
import ViewClient from '@/views/crm/ViewClient'

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      auth: false
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      auth: true,
      title: 'Dashboard',
      module: 'dashboard'
    }
  },
  {
    path: '/notices',
    name: 'notices',
    component: Notices,
    meta: {
      auth: true,
      title: 'Notices',
      module: 'notices'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      auth: true,
      title: 'About',
      module: 'dashboard'
    }
  },
  {
    path: '/hrm/employees',
    name: 'employees',
    component: Employees,
    meta: {
      auth: true,
      title: 'Human Resource / Employees',
      module: 'hrm'
    }
  },
  {
    path: '/hrm/employee/:id',
    name: 'update-employee',
    component: UpdateEmployee,
    meta: {
      auth: true,
      title: 'Human Resource / Employees / Update',
      module: 'hrm'
    }
  },
  {
    path: '/hrm/designations',
    name: 'designations',
    component: Designations,
    meta: {
      auth: true,
      title: 'Human Resource / Designations',
      module: 'hrm'
    }
  },
  {
    path: '/hrm/attendances',
    name: 'attendances',
    component: Attendances,
    meta: {
      auth: true,
      title: 'Human Resource / Attendances',
      module: 'hrm'
    }
  },

  {
    path: '/hrm/leaves',
    name: 'leaves',
    component: Leaves,
    meta: {
      auth: true,
      title: 'Human Resource / Leaves',
      module: 'hrm'
    }
  },

  {
    path: '/hrm/settings',
    name: 'hrm-settings',
    component: HrmSettings,
    meta: {
      auth: true,
      title: 'Human Resource / Settings',
      module: 'hrm'
    }
  },

  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      auth: true,
      module: 'self',
      title: 'Profile'
    }
  },

  {
    path: '/profile/update',
    name: 'update-profile',
    component: UpdateSelf,
    meta: {
      auth: true,
      module: 'self',
      title: 'Update Profile'
    }
  },

  {
    path: '/profile/settings',
    name: 'self-settings',
    component: SelfSettings,
    meta: {
      auth: true,
      module: 'self',
      title: 'Settings'
    }
  },
  {
    path: '/crm/clients',
    name: 'clients',
    component: Clients,
    meta: {
      auth: true,
      title: 'Client Relationship / Clients',
      module: 'crm'
    }
  },
  {
    path: '/crm/client/add',
    name: 'add-client',
    component: AddClient,
    meta: {
      auth: true,
      title: 'Client Relationship / Add Client',
      module: 'crm'
    }
  },
  {
    path: '/crm/client/update/:id',
    name: 'update-client',
    component: UpdateClient,
    meta: {
      auth: true,
      title: 'Client Relationship / Update Client',
      module: 'crm'
    }
  },
  {
    path: '/crm/client/:id',
    name: 'view-client',
    component: ViewClient,
    meta: {
      auth: true,
      title: 'Client Relationship / View',
      module: 'crm'
    }
  },
  {
    path: '/crm/services',
    name: 'services',
    component: Services,
    meta: {
      auth: true,
      title: 'Client Relationship / Services',
      module: 'crm'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  if(!to.matched.some(record => record.meta.auth)){
    next()
    return
  }
  try{
    const token  = local.get('_auth').login.token
    if(token){
      next()
      return
    }
  }catch(e){
    // console.log(e)
  }
  next({name: 'login'})
})

router.afterEach(async (to, from, next) => {

  if(to.matched.some(record => record.meta.auth)){
    try{
      const response = await server.get('/employee/self', true)
      const auth = local.get('_auth')
      local.save('_auth', {...auth, employee: response.employee})
    }catch(e){
      router.push({name: 'login'})
      return
    }
  }
})

export default router
