<template>
    <div class="card lg:col-span-1 p-4">
        <p class="card-heading">Notices</p>

        <div class="h-96 overflow-y-auto">
            <div
                v-for="notice in notices"
                :key="notice._id"
                class="dashboard-notice"
                :class="`${notice.color} ${aciveNotice(notice._id)}`"
            >
                <button
                    class="flex w-full justify-between items-center cursor-pointer hover:scale-105 transition-all"
                    @focus="active = notice._id"
                    @focusout="active = null"
                >
                    <span>{{ notice.title }}</span>
                    <span class="text-sm">{{
                        clacTime(notice.createdAt)
                    }}</span>
                </button>
                <div class="mt-3" v-html="notice.content"></div>
            </div>
        </div>
    </div>
</template>

<script>
import helper from "@/services/helper";

export default {
    name: "NoticeList",
    props: ["notices"],
    data() {
        return {
            active: null,
        };
    },
    methods: {
        aciveNotice(id) {
            return this.active === id ? "active" : "";
        },
        clacTime(date) {
            return helper.calculateTimeSincePublished(new Date(date));
        },
    },
    created() {},
};
</script>
