<template>
  <p class="mb-8 text-2xl">Add Employee</p>
  <div class="text-sm">
    <vee-form
    :validation-schema="schema"
    @submit="save"
    :initial-values="initValues"
  >
    <div class="mb-8 grid grid-cols-2 gap-4">
      <div class="mat-input">
        <vee-field name="serial" v-slot="{ meta, field }">
          <label for="" class="mb-2 block">Serial:*</label>
          <input
            type="text"
            name="serial"
            v-bind="field"
            :class="{ 'is-invalid': !meta.valid && meta.touched }"
          />
          <error-message name="serial" class="invalid-feedback" />
        </vee-field>
      </div>
      <div class="mat-input">
        <vee-field name="id" v-slot="{ meta, field }">
          <label for="" class="mb-2 block">Id:*</label>
          <input
            type="text"
            name="id"
            v-bind="field"
            :class="{ 'is-invalid': !meta.valid && meta.touched }"
          />
          <error-message name="id" class="invalid-feedback" />
        </vee-field>
      </div>
    </div>
    <div class="mb-8 grid grid-cols-2 gap-4">
      <div class="mat-input">
        <vee-field name="first_name" v-slot="{ meta, field }">
          <label for="" class="mb-2 block">First Name:*</label>
          <input
            type="text"
            name="first_name"
            v-bind="field"
            placeholder="First name"
            :class="{ 'is-invalid': !meta.valid && meta.touched }"
          />
          <error-message name="first_name" class="invalid-feedback" />
        </vee-field>
      </div>
      <div class="mat-input">
        <vee-field name="last_name" v-slot="{ meta, field }">
          <label for="" class="mb-2 block">Last Name:</label>
          <input
            type="text"
            name="last_name"
            v-bind="field"
            placeholder="Last name"
            :class="{ 'is-invalid': !meta.valid && meta.touched }"
          />
          <error-message name="last_name" class="invalid-feedback" />
        </vee-field>
      </div>
    </div>
    <div class="mb-8 grid grid-cols-2 gap-4">
      <div class="mat-input">
        <vee-field name="nick_name" v-slot="{ meta, field }">
          <label for="" class="mb-2 block">Nick Name:</label>
          <input
            type="text"
            name="nick_name"
            v-bind="field"
            placeholder="ex. Tony Stark, Hulk"
            :class="{ 'is-invalid': !meta.valid && meta.touched }"
          />
          <error-message name="nick_name" class="invalid-feedback" />
        </vee-field>
      </div>
      <div class="mat-input">
        <vee-field name="gender" v-slot="{ meta, field }">
          <label for="" class="mb-2 block">Gender:*</label>
          <select name="gender" v-bind="field" :class="{ 'is-invalid': !meta.valid && meta.touched }">
              <option value="">Select gender</option>
              <option value="f">Female</option>
              <option value="m">Male</option>
              <option value="o">Others</option>
          </select>
          <error-message name="gender" class="invalid-feedback" />
        </vee-field>
      </div>
    </div>
    <div class="mb-8 grid grid-cols-2 gap-4">
      <div class="mat-input">
        <vee-field name="email" v-slot="{ meta, field }">
          <label for="" class="mb-2 block">Email:*</label>
          <input
            type="email"
            name="email"
            v-bind="field"
            placeholder="someone@brandwind.in"
            :class="{ 'is-invalid': !meta.valid && meta.touched }"
          />
          <error-message name="email" class="invalid-feedback" />
        </vee-field>
      </div>
      <div class="mat-input">
        <vee-field name="password" v-slot="{ meta, field }">
          <label for="" class="mb-2 block">Password:*</label>
          <input
            type="password"
            name="password"
            v-bind="field"
            class="gen-input"
            placeholder="Secret password"
            :class="{ 'is-invalid': !meta.valid && meta.touched }"
          />
          <error-message name="password" class="invalid-feedback" />
        </vee-field>
      </div>
    </div>
    <div class="mb-8 grid grid-cols-2 gap-4">
      <div class="mat-input">
        <vee-field name="designation" v-slot="{ meta, field }">
          <label for="" class="mb-2 block">Designation:*</label>
          <select name="designation" v-bind="field" :class="{ 'is-invalid': !meta.valid && meta.touched }">
              <option value="" selected>Not selected</option>
              <option
                v-for="designation in designations"
                :key="designation._id"
                :value="designation._id"
                >
                {{ chageCase(designation.title) }}
              </option>
          </select>
          <error-message name="designation" class="invalid-feedback" />
        </vee-field>
        <!-- <button class="btn-link mt-2">Create designation</button> -->
      </div>
      <div class="flex justify-center items-center">
        <span class="px-4 py-2 inline-block">Allow login:</span>
        <toggle-switch :default="true" :onToggle="onToggle" />
      </div>
    </div>

    <div class="flex gap-4">
      <button type="submit" class="btn btn-primary btn-block" :disabled="inSubmission">
        <span v-show="!inSubmission">Save</span>
        <span class="text-center" v-show="inSubmission">
            <svg class="animate-spin -ml-1 mr-1 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Please wait...
        </span>
      </button>
      <button type="button" class="btn btn-white btn-block" @click="close">Close</button>
    </div>
  </vee-form>
  </div>
</template>

<script>

import server from '@/services/server'
import Case from 'case'

import local from '@/services/local'
import helper from '@/services/helper'
import ToggleSwitch from '@/components/elements/ToggleSwitch.vue'
import settings from '@/services/server/setting'

export default {
  components: { ToggleSwitch },
  props: ['designations', 'close'],
  name: "AddEmployeeForm",
  data(){
        return{
            schema: {
                first_name: "required|alpha|min:2|max:50",
                last_name: "alphaSpaces|max:50",
                email: "required|email",
                password: "required|min:8",
                serial: 'required|numeric',
                id: 'required',
                designation: 'required',
                gender: 'required'
            },
            initValues: {gender: 'f'},
            status: 1,
            inSubmission: false
        }
    },
    methods: {
        chageCase(text){
            return Case.title(text)
        },
        onToggle(value){
            this.status = value ? 1 : 0
        },
        async save(data){
            this.inSubmission =  true
            data.status = this.status
            try{
                const response = await server.post('/employee', data, true)
                this.$moshaToast('Employee added successfully.', {type: 'success'})
                this.$router.push({name: 'update-employee', params: {id: response._id}})
            }catch(e){
                this.$moshaToast('Unable to add employee. Please try again.', {type: 'danger'})
                this.inSubmission = false
            }
            
        },
        async setInitValues(){
            await settings.reFetch()
            const setting = local.get('settings').hrm
            this.initValues = {
                serial: setting.last_employee_index,
                id: setting.employee_id_prefix + helper.addLeadingZeros(setting.last_employee_index + '', 4)
            }
            
        }
    },
    async created(){
        this.setInitValues()
    }
};
</script>