<template>
    <div>
        <vee-form @submit="save" :validation-schema="schema">


            <div class="grid grid-cols-2 gap-4 mb-8">
                <div class="mat-input">
                    <vee-field name="start" v-slot="{ meta, field }">
                        <label for="" class="mb-2 block">Start:*</label>
                        <input
                            type="date"
                            name="start"
                            v-bind="field"
                            :class="{ 'is-invalid': !meta.valid && meta.touched }"
                            @change="setStartDate($event.target.value)"
                        />
                        <error-message name="start" class="invalid-feedback" />
                    </vee-field>
                </div>
                <div class="mat-input">
                    <vee-field name="end" v-slot="{ meta, field }">
                        <label for="" class="mb-2 block">End:*</label>
                        <input
                            type="date"
                            name="end"
                            v-bind="field"
                            :class="{ 'is-invalid': !meta.valid && meta.touched }"
                            @change="setEndDays($event.target.value)"
                        />
                        <error-message name="end" class="invalid-feedback" />
                    </vee-field>
                </div>
            </div>


            <div class="grid grid-cols-2 gap-4 mb-8">
                <div class="mat-input">
                    <label for="" class="mb-2 block">Days: (left {{daysLeft}})</label>
                    <input type="text" v-model="days" disabled />
                </div>
                <div class="mat-input">
                    <vee-field name="type" v-slot="{ meta, field }">
                        <label for="" class="mb-2 block">Type:*</label>
                        <select 
                            name="type"
                            v-bind="field"
                            :class="{ 'is-invalid': !meta.valid && meta.touched }"
                        >
                            <option value="">Select type</option>
                            <option v-for="type in types" :key="type._id" :value="type._id">{{titleCase(type.title)}}</option>
                        </select>
                        <error-message name="end" class="invalid-feedback" />
                    </vee-field>
                </div>
            </div>
             <div class="mat-input mb-8">
                <vee-field name="description" v-slot="{ meta, field }">
                    <label for="" class="mb-2 block">Description:*</label>
                    <!-- <input
                        type="date"
                        name="end"
                        v-bind="field"
                        :class="{ 'is-invalid': !meta.valid && meta.touched }"
                    /> -->
                    <textarea name="description"
                        v-bind="field"
                        :class="{ 'is-invalid': !meta.valid && meta.touched }" placeholder="Enter a description"></textarea>
                    <error-message name="description" class="invalid-feedback" />
                </vee-field>
            </div>
            <div class="flex gap-4">
                <button type="submit" class="btn-primary btn btn-block">
                    <span v-show="!inSubmission">Apply</span>
                    <span class="text-center" v-show="inSubmission">
                        <svg class="animate-spin -ml-1 mr-1 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Please wait...
                    </span>
                </button>
                <button type="button" class="btn-white btn btn-block" @click="close">Close</button>
            </div>
        </vee-form>
    </div>
</template>

<script>
import Case from 'case'
import helper from '@/services/helper'
import server from '@/services/server'
import local from '@/services/local'
export default {
    name: 'ApplyLeaveForm',
    props: ['types', 'close', 'update'],
    data(){
        return{
            days: 0,
            startDate: '',
            endDate: '',
            schema: {
                start: 'required',
                end: 'required',
                type: 'required',
                description: 'required'
            },
            daysLeft: 0,
            inSubmission: false
        }
    },
    methods: {
        titleCase(text){
            return Case.title(text)
        },
        calcDays(){
            if(this.endDate == ''){
                this.days = 1
                return
            }
            if(this.endDate !== ''){
                this.days = helper.getDaysBetweenDates(new Date(this.startDate), new Date(this.endDate)) + 1
                return
            }
        },
        setStartDate(value){
            this.startDate = value
            this.calcDays()
        },
        setEndDays(value){
            this.endDate = value
            this.calcDays()
        },
        async save(values){
            this.inSubmission = true
            try{
                await server.post('/leave/apply', values, true)
                this.$moshaToast('Leave applied successfully.', {type: 'success'})
                await this.$props.update()
                this.$props.close()
            }catch(e){
                this.$moshaToast('Unable to apply for leave. Please try again.', {type: 'danger'})
            }
            this.inSubmission = false
        },
        async getCount(){
            try{
                const response = await server.get('/leave/self/days')
                this.daysLeft = local.get('settings').hrm.allowed_leave - response.days
            }catch(e){
                this.$moshaToast('Unable get data. Please try again.', {type: 'danger'})
            }
        }
    },
    async created(){
        await this.getCount()
        
    }
}
</script>