<template>
    <form action="" @submit="add" class="text-sm">
        <div class="grid lg:grid-cols-3 lg:gap-3 mb-6">
            <div class="mat-input">
                <label for="">Site Name:</label>
                <input
                    type="text"
                    v-model="site"
                    placeholder="ex. facebook, instagram"
                    required
                />
            </div>
            <div class="mat-input">
                <label for="">Password:</label>
                <input
                    type="text"
                    v-model="password"
                    placeholder="Password"
                    required
                />
            </div>
            <div class="mat-input">
                <label for="">Link:</label>
                <input
                    type="text"
                    v-model="access"
                    placeholder="https://faceboo..."
                />
            </div>
        </div>
        <p class="text-red-500 mb-6 text-sm" v-show="error_msg">
            {{ error_msg }}
        </p>
        <div class="">
            <button class="btn btn-primary btn-block">
                <span v-show="!inSubmission">Add</span>
                <span class="text-center" v-show="inSubmission">
                    <svg
                        class="animate-spin -ml-1 mr-1 h-5 w-5 inline"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        ></circle>
                        <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                    Please wait...
                </span>
            </button>
        </div>
    </form>
</template>

<script>
import server from "@/services/server";
export default {
    name: "UpdateClientPasswordForm",
    props: ["client", "onUpdate"],
    data() {
        return {
            inSubmission: false,
            site: "",
            password: "",
            access: "",
            error_msg: null,
        };
    },
    methods: {
        async add(event) {
            event.preventDefault();
            this.inSubmission = true;
            if (!this.site || !this.password) {
                this.error_msg = "Please enter site name & password.";
                this.inSubmission = false;
                return;
            }
            const data = {
                site: this.site,
                password: this.password,
            };
            if (this.access) {
                data.access = this.access;
            }
            try {
                await server.patch("/client/" + this.$props.client._id, {
                    passwords: data,
                });
                
                this.site = "";
                this.password = "";
                this.access = "";
                await this.$props.onUpdate();
                this.$moshaToast("Contact person added successfully.", {
                    type: "success",
                });
            } catch (e) {
                this.$moshaToast("Unable to add password. Please try again.", {
                    type: "danger",
                });
            }
            this.inSubmission = false;
        },
    },
};
</script>
