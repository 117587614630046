<template>
    <span
        class="h-full text-sm flex items-center px-4 font-ubuntu italic"
    ><img :src="env" class="inline h-10 mr-1" alt=""> {{temp}}&deg;</span>
</template>

<script>
import axios from "axios";
export default {
    name: "Weather",
    data() {
        return {
            temp: 0,
            env: null,
        };
    },
    methods: {
        async getWeather() {
            try {
                const url =
                    "https://api.openweathermap.org/data/2.5/weather?lat=23.64060509262143&lon=87.68011216267067&appid=52955c505e38528ec9f74ab7fdef0db7";
                const response = await axios.get(url);
                // console.log(response.data)
                this.temp = (response.data.main.temp - 273.15).toFixed(2)
                this.env = `http://openweathermap.org/img/w/${response.data.weather[0].icon}.png`
                // this.temp = response.data.current.temp_c
                // this.env = response.data.current.condition.icon
            } catch (e) {
                this.env = null
            }
        },
    },
    async created() {
        await this.getWeather()
    },
};
</script>
