<template>
    <span class="toggle-switch" @click="toggle()" :class="{active: active}">
        <span class="toggle-path"></span>
        <span class="toggle-point" :class="{'left-5': active, '-left-1': !active}"></span>
    </span>
</template>

<script>
export default {
    name: 'ToggleSwitch',
    props: ['default', 'onToggle'],
    data(){
        return{
            active: this.$props.default
        }
    },
    methods: {
        toggle(){
            this.active = !this.active
            this.$props.onToggle(this.active)
        }
    }
}
</script>