<template>
    <div
        class="h-screen w-screen flex justify-center items-center bg-gray-900 text-gray-600 px-4 lg:px-0"
    >
        <div class="w-full max-w-[384px]">
            <div class="card p-6">
                <div class="mb-6 text-center">
                    <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        class="fill-gray-900 h-10 mx-auto mb-4"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 624.48 163.28"
                        style="enable-background: new 0 0 624.48 163.28"
                        xml:space="preserve"
                    >
                        <g>
                            <path
                                class="st0"
                                d="M122.32,90.07c-6.87-38.13-47.64-60.17-81.17-45.66c-4.91,2.13-9.49,5.01-14.52,7.7V0.23
		C25.87,0.11,25.45-0.01,25.02,0c-0.9,0.02-1.82,0.02-2.71,0.19C12.7,2.05,5.84,7.39,2.09,16.48C1.2,18.64,0.69,20.96,0,23.2v83.64
		c0.55,2.83,1.02,5.68,1.67,8.49c6.02,26.11,26.89,44.52,53.63,47.36c0.63,0.07,1.22,0.39,1.84,0.59h8.84
		c1.86-0.33,3.72-0.67,5.58-0.99C106.23,156.29,128.58,124.86,122.32,90.07z M61.45,136.65c-18.85-0.06-35.05-16.47-34.91-35.34
		c0.14-18.97,16.54-35.32,35.23-35.14c19.31,0.19,35.18,16.18,35.14,35.45C96.87,120.82,80.78,136.72,61.45,136.65z"
                            />
                            <path
                                class="st0"
                                d="M624.32,95.96C622.5,71.84,601.01,52.45,576,52.45c-153.66-0.01-307.32-0.01-460.98,0
		c-1.07,0-2.14,0.1-3.45,0.16c25.94,27.68,25.47,69.41-0.2,96.74h4.34c152.98,0,305.96,0,458.94-0.03c2.94,0,5.91-0.09,8.81-0.54
		C609.09,144.78,626.33,122.45,624.32,95.96z M189.91,111.76c-0.49,6.62-3.3,11.17-9.83,12c-9.44,1.2-19.05,1.06-28.8,1.5V75.26
		c9.19,0,18.21-0.5,27.15,0.18c5.53,0.42,8.68,4.6,9.26,9.79c0.63,5.62-0.22,10.64-6.6,13.35
		C187.38,101.04,190.39,105.25,189.91,111.76z M226.35,119.95c-1.2-1.65-2.41-3.28-3.6-4.93c-5.1-7.04-5.1-7.04-14.31-5.8v8.7
		c-0.01,6.29-0.8,7.08-7.01,6.93c-0.56-0.01-1.12-0.09-1.98-0.16V75.11c9.02,0,18.07-0.66,26.98,0.2
		c7.24,0.71,11.66,6.87,12.64,14.82c0.87,7.02-2.6,13.96-8.55,17.14c-0.47,0.24-0.95,0.48-1.73,0.88
		c4.01,5.45,7.87,10.69,11.75,15.98C234.27,126.28,230.03,124.99,226.35,119.95z M281.96,119.23c-0.61-1.08-2.28-2.16-3.51-2.2
		c-6.33-0.22-12.68-0.02-19.01-0.15c-1.88-0.03-2.79,0.63-3.58,2.28c-2.84,5.88-5.26,6.85-12.66,5.12
		c8.5-16.62,16.95-33.15,25.79-50.44c8.83,17.28,17.29,33.8,25.78,50.42C287.43,126.01,285.19,124.97,281.96,119.23z M344.7,125.39
		c-11.52-10.37-22.44-20.19-34.01-30.6v20.38c0,1.36,0.01,2.72-0.01,4.08c-0.03,4.47-1.14,5.56-5.66,5.6c-1.12,0.01-2.24,0-3.55,0
		V74.07c11.42,10.47,22.34,20.48,33.59,30.8c0.14-1.16,0.31-1.97,0.32-2.79c0.02-6.45,0.01-12.91,0.01-19.37
		c0-7.75,1.14-8.76,9.3-7.92V125.39z M399.88,108.6c-2.46,9.22-9.46,15.23-18.97,15.84c-8,0.52-16.05,0.11-24.36,0.11V74.94
		c8.41,0,16.6-0.58,24.67,0.14c11.67,1.04,19.53,10.26,19.84,22.46C401.15,101.23,400.82,105.06,399.88,108.6z M451.35,124.68
		c-0.29,0.03-0.58,0.06-0.86,0.1c-4.46-9.55-8.93-19.09-13.72-29.36c-4.83,10.24-9.34,19.82-14.28,30.3
		c-6.4-17.34-12.51-33.89-18.61-50.44c6.44-2.16,9.79-0.61,11.95,5.39c2.5,6.98,4.98,13.97,7.77,21.77
		c4.49-9.64,8.66-18.56,13.15-28.2c4.48,9.64,8.64,18.59,13.14,28.26c1.89-5.21,3.54-9.78,5.21-14.34c0.77-2.12,1.57-4.24,2.34-6.36
		c2.68-7.39,4.22-8.27,12.47-6.82C463.7,91.58,457.52,108.13,451.35,124.68z M486.59,121.45c0,0.99-0.13,1.99-0.22,3.35
		c-1.94,0-3.88,0.44-5.44-0.13c-1.42-0.52-3.46-2.12-3.47-3.27c-0.23-15.46-0.16-30.93-0.16-46.85c1.45,0,2.46-0.02,3.47,0.01
		c4.78,0.12,5.81,1.11,5.82,5.82C486.61,94.07,486.61,107.76,486.59,121.45z M541.86,125.33c-11.49-10.34-22.36-20.14-33.84-30.47
		v20.28c0,1.47,0.04,2.94-0.01,4.41c-0.14,4.08-1.3,5.22-5.34,5.3c-1.24,0.03-2.48,0.01-3.94,0.01V74.07
		c11.46,10.51,22.37,20.52,33.89,31.1V82.78c0-7.8,1.12-8.79,9.24-7.99V125.33z M598.27,103.55c-1.66,12.88-9.04,20.22-22.05,21.08
		c-7.41,0.49-14.88,0.09-22.53,0.09V74.71c7.02,0,14-0.29,20.94,0.12c3.86,0.22,7.94,1.01,11.44,2.6
		C595.17,81.53,599.77,91.91,598.27,103.55z"
                            />
                            <path
                                class="st0"
                                d="M180.53,110.37c-0.14,3.66-2.3,5.85-6.39,6.02c-4.37,0.17-8.76,0.03-13.33,0.03v-12.76
		c4.79,0,9.42-0.29,13.99,0.09C178.85,104.07,180.68,106.57,180.53,110.37z"
                            />
                            <path
                                class="st0"
                                d="M178.45,88.65c-0.01,2.95-0.91,5.64-4.23,6.01c-4.34,0.5-8.74,0.48-13.48,0.69V83.15
		c4.47,0,8.85-0.24,13.19,0.08C176.9,83.45,178.45,85.63,178.45,88.65z"
                            />
                            <path
                                class="st0"
                                d="M588.27,105.82c-1.46,6.26-5.76,10-12.56,10.44c-4.05,0.27-8.13,0.05-12.45,0.05V83.19
		c4.32,0,8.4-0.23,12.44,0.1c2.3,0.18,4.72,0.92,6.74,2.04C588.06,88.42,590.35,96.9,588.27,105.82z"
                            />
                            <path
                                class="st0"
                                d="M390.99,105.99c-1.52,6.2-5.91,9.89-12.7,10.28c-4.05,0.23-8.13,0.04-12.47,0.04V83.2
		c4.45,0,8.65-0.24,12.8,0.1c2.29,0.18,4.71,0.97,6.71,2.1C390.87,88.53,393.12,97.24,390.99,105.99z"
                            />
                            <path
                                class="st0"
                                d="M222.48,100.3c-4.46,0.46-9,0.1-13.84,0.1V83.1c4.58,0,8.98-0.22,13.35,0.06c4.65,0.29,7.6,3.66,7.77,8.26
		C229.95,96.47,227.56,99.78,222.48,100.3z"
                            />
                            <path
                                class="st0"
                                d="M276.22,108.54h-14.48c2.38-5.05,4.67-9.89,7.26-15.37C271.57,98.65,273.82,103.42,276.22,108.54z"
                            />
                        </g>
                    </svg>
                    <p>Sign in to your account</p>
                </div>
                <div>
                    <form @submit="login">
                      <div class="mat-input mb-4">
                        <!-- <label for="">Email:</label> -->
                        <input
                            type="text"
                            placeholder="Username"
                            v-model="email"
                        />
                    </div>
                    <div class="mat-input mb-8 relative">
                        <!-- <label for="">Password:</label> -->
                        <input
                            :type="showPassword ? 'text' : 'password'"
                            placeholder="Password"
                            v-model="password"
                        />
                        <span @click="showPassword = !showPassword" class="py-2 px-4 inline-block absolute cursor-pointer top-0 right-0 text-gray-500">
                            <i v-show="showPassword" class="fa-regular fa-eye"></i>
                            <i v-show="!showPassword" class="fa-regular fa-eye-slash"></i>
                        </span>
                    </div>
                    <div>
                        <button
                            class="btn-block btn btn-primary"
                            
                            :disabled="!email || !password"
                        >
                            <span v-show="!inSubmission"> Sign in </span>
                            <span class="text-center" v-show="inSubmission">
                                <svg
                                    class="animate-spin -ml-1 mr-1 h-5 w-5 inline"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        class="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        stroke-width="4"
                                    ></circle>
                                    <path
                                        class="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Please wait...
                            </span>
                        </button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import server from "@/services/server";
import local from "@/services/local";
export default {
    name: "login",
    props: ["ready"],
    data() {
        return {
            email: "",
            password: "",
            inSubmission: false,
            showPassword: false
        };
    },
    methods: {

        async login(event) {
          event.preventDefault()
            const pat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            this.email = this.email.toLowerCase()
            if (!this.email.match(pat)) {
                this.email = this.email.toLowerCase() + "@brandwind.in";
            }
            this.inSubmission = true;
            try {
                const response = await server.post(
                    "/auth/login",
                    { email: this.email, password: this.password },
                    false
                );
                local.save("_auth", response);
                this.$moshaToast("Welcome " + response.employee.first_name, {
                    type: "success",
                });
                this.$router.push({ name: "dashboard" });
            } catch (e) {
                this.$moshaToast("Please enter correct email & password.", {
                    type: "danger",
                });
                this.inSubmission = false;
            }
        },
    },
    async beforeMount() {
        try {
            await server.get("/employee/self");
            this.$router.push({ name: "dashboard" });
        } catch (e) {}
        this.$props.ready();
    },
};
</script>
