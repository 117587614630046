<template>
    <div class="p-6">
        <form action="" @submit="add" class="">
            <div class="grid grid-cols-3 gap-3 text-sm mb-6">
                <div class="mat-input">
                    <label for="">Name:</label>
                    <input
                        type="text"
                        placeholder="Person name"
                        v-model="name"
                        required
                    />
                </div>
                <div class="mat-input">
                    <label for="">Phone:</label>
                    <input
                        type="text"
                        v-model="phone"
                        placeholder="10 digit phone"
                    />
                </div>
                <div class="mat-input">
                    <label for="">Email:</label>
                    <input
                        type="email"
                        v-model="email"
                        placeholder="name@example.com"
                    />
                </div>
            </div>
            <p class="text-red-500 mb-6 text-sm" v-show="error_msg">
                {{ error_msg }}
            </p>
            <div class="flex gap-3 text-sm">
                <button class="btn btn-primary btn-block text-sm" type="submit">
                    <span v-show="!inSubmission">Add</span>
                    <span class="text-center" v-show="inSubmission">
                        <svg
                            class="animate-spin -ml-1 mr-1 h-5 w-5 inline"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                class="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                            ></circle>
                            <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                        Please wait...
                    </span>
                </button>
                
            </div>
        </form>
    </div>
</template>

<script>
import server from "@/services/server";
export default {
    props: ["client", "onUpdate"],
    name: "ContactPersonForm",
    data() {
        return {
            error_msg: null,
            name: "",
            phone: "",
            email: "",
            inSubmission: false,
            reload_table: true,
        };
    },
    methods: {
        async add(event) {
            event.preventDefault();
            this.inSubmission = true;
            if (!this.phone && !this.email) {
                this.error_msg = "Please enter a contact details.";
                this.inSubmission = false;
                return;
            }
            const data = {
                name: this.name,
            };
            if (this.phone) {
                data["phone"] = this.phone;
            }
            if (this.email) {
                data["email"] = this.email;
            }
            try {
                await server.patch("/client/" + this.$props.client._id, {
                    contact_persons: data,
                });
                this.$moshaToast("Contact person added successfully.", {
                    type: "success",
                });

                await this.$props.onUpdate();
                this.email = ''
                this.phone = ''
                this.name = ''
            } catch (e) {
                this.$moshaToast(
                    "Unable to add contact person. Please try again.",
                    { type: "danger" }
                );
            }
            this.inSubmission = false;
        },
    },
};
</script>
