<template>
    <div class="card p-6">
        <p class="card-heading">Other Details</p>
        <form class="text-sm" @submit="save">
             <div class="mat-input mb-6">
                <label for="">Affiliation:</label>
                <input
                    type="text"
                    placeholder="Affiliation"
                    v-model="affiliation"
                />
            </div>
            <div class="mat-input mb-6">
                <label for="">Requirements:</label>
                <textarea placeholder="Basic requirements" v-model="requirements"></textarea>
            </div>
            <div class="mat-input mb-6">
                <label for="">Languages:</label>
                <input
                    type="text"
                    placeholder="English, Bengali etc."
                    v-model="languages"
                />
            </div>
            <div class="mat-input mb-6">
                <label for="">Category:</label>
                <input
                    type="text"
                    placeholder="ex. Education"
                    v-model="category"
                />
            </div>
            <div class="mat-input mb-6">
                <label for="">Colors:</label>
                <input
                    type="text"
                    placeholder="ex. #FFFFFF, #DDDDDD"
                    v-model="colors"
                />
            </div>
            <div class="mat-input mb-6">
                <label for="">Keywords:</label>
                <input
                    type="text"
                    placeholder="ex. education, schools etc."
                    v-model="keywords"
                />
            </div>
            <div class="mat-input mb-6">
                <label for="">Other Details:</label>
                <textarea v-model="other_details" placeholder="Enter other details for design"></textarea>
                <!-- <input
                    type="text"
                    placeholder="ex. education, schools etc."
                    v-model="other_details"
                /> -->
            </div>
            <button class="btn btn-primary btn-block">
                <span v-show="!inSubmission">Save</span>
                    <span class="text-center" v-show="inSubmission">
                        <svg
                            class="animate-spin -ml-1 mr-1 h-5 w-5 inline"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                class="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                            ></circle>
                            <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                        Please wait...
                    </span>
            </button>
        </form>
    </div>
</template>

<script>
import server from "@/services/server";
export default {
    name: "ClientOtherDetailsForm",
    props: ["client"],
    data() {
        return {
            languages: "",
            category: "",
            keywords: "",
            colors: '',
            affiliation: '',
            requirements: '',
            other_details: '',
            inSubmission: false
        };
    },
    methods: {
        async save(e) {
            e.preventDefault()
            this.inSubmission = true
            const data = {
                languages: this.languages,
                category: this.category,
                keywords: this.keywords,
                colors: this.colors,
                affiliation: this.affiliation,
                requirements: this.requirements,
                other_details: this.other_details
            };
            try {
                await server.patch("/client/" + this.$props.client._id, data);
                this.$moshaToast("Client updated successfully.", {
                    type: "success",
                });
            } catch (e) {
                this.$moshaToast("Unable to add password. Please try again.", {
                    type: "danger",
                });
            }
            this.inSubmission = false
        },
    },
    created() {
        this.languages = this.$props.client.languages;
        this.category = this.$props.client.category;
        this.keywords = this.$props.client.keywords;
        this.colors = this.$props.client.colors
        this.affiliation = this.$props.client.affiliation
        this.requirements = this.$props.client.requirements
        this.other_details = this.$props.client.other_details
    },
};
</script>
