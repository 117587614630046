<template>
    <div class="grid lg:grid-cols-4 gap-5">
        <div class="lg:col-span-1">
            <div class="card">
                <div class="p-6 text-center">
                    <img :src="avatar" class="w-20 h-20 mx-auto rounded-full my-4" alt="">
                    <p class="text-lg">{{this.employee.first_name}} {{this.employee.last_name}}</p>
                    <p class="mb-4">{{titleCase(designation.title)}}</p>
                    <p class="text-sm mb-4">{{profile.bio}}</p>
                </div>
                <div class="divide-y">
                    <div class="text-left text-sm flex justify-between py-2 px-4">
                        <p class="">ID:</p>
                        <p class="">{{employee.id}}</p>
                    </div>
                    <div class="text-left text-sm flex justify-between py-2 px-4">
                        <p class="">Nick Name:</p>
                        <p class="">{{employee.nick_name}}</p>
                    </div>
                </div>
                <div class="mt-4 py-6 text-center">
                    <router-link :to="{name: 'update-profile'}" class="btn btn-primary">Edit</router-link>
                </div>
            </div>
        </div>
        <div class="lg:col-span-3">
            <div class="card p-6">
                <div class="grid lg:grid-cols-2 gap-5">
                    <div>
                        <div class="text-left flex justify-between py-2 px-4">
                            <p class="">Serial:</p>
                            <p class="">{{employee.serial}}</p>
                        </div>
                        <div class="text-left flex justify-between py-2 px-4">
                            <p class="">Gender:</p>
                            <p class="">{{genders[employee.gender]}}</p>
                        </div>
                         <div class="text-left flex justify-between py-2 px-4">
                            <p class="">Email:</p>
                            <p class="">{{employee.email}}</p>
                        </div>
                        <div class="text-left flex justify-between py-2 px-4">
                            <p class="">Phone:</p>
                            <p class="">{{profile.phone}}</p>
                        </div>
                    </div>
                    <div>
                        <div class="text-left flex justify-between py-2 px-4">
                            <p class="">Date Of Birth:</p>
                            <p class="" v-show="profile.dob">{{formatDate(profile.dob)}}</p>
                        </div>
                        <div class="text-left flex justify-between py-2 px-4">
                            <p class="">Joining:</p>
                            <p class="" v-show="profile.date_of_joining">{{formatDate(profile.date_of_joining)}}</p>
                        </div>
                         <div class="text-left flex justify-between py-2 px-4">
                            <p class="">Address 1:</p>
                            <p class="">{{profile.address_1}}</p>
                        </div>
                        <div class="text-left flex justify-between py-2 px-4">
                            <p class="">Address 2:</p>
                            <p class="">{{profile.address_2}}</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import server from '@/services/server'
import Case from 'case'
import helper from '@/services/helper'
export default {
    name: 'Profile',
    data(){
        return {
            avatar: require('@/assets/images/default-avatar.jpg'),
            employee: {},
            profile: {},
            designation: {},
            attendances: [],
            genders: {
                m: 'Male',
                f: 'Female',
                o: 'Others'
            }
        }
    },
    methods: {
        async getSelf(){
            try{
                const response = await server.get('/employee/self')
                this.employee = response.employee
                this.profile = response.profile
                this.designation = response.designation
                this.attendances = response.attendances
                this.avatar = server.resolve('/employee/avatar/' + this.employee._id)
            }catch(e){
                this.$moshaToast('Unable to get data. Please try again.', {type: 'danger'})
            }
        },
        titleCase(text){
            return Case.title(text)
        },
        formatDate(date){
            return helper.formatDate(new Date(date))
        }
    },
    async created(){
        await this.getSelf()
    }
}
</script>