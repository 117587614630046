<template>
   <div>
     <div class="grid lg:grid-cols-2 gap-6 mb-6">
        <div class="card p-6 h-fit">
            <p class="mb-4 text-lg">Basic Details:</p>
            <update-employee-form v-if="employee._id" :employee="employee" :designations="designations" :admin="true" />
        </div>
        <div>
            <div class="card p-6 h-fit mb-8">
                <p class="mb-4 text-lg">Change Password:</p>
                <update-password-form v-if="employee._id" :employee_id="employee._id" :admin="true" />
            </div>
            <div class="card p-6 h-fit mb-8">
                <p class="mb-4 text-lg">Change Avatar:</p>
                <upload-avatar-form v-if="employee._id" :employee="employee" :admin="true" />
            </div>
            <div class="card p-6 h-fit">
                <p class="mb-4 text-lg">Manage Access:</p>
                <update-employee-access-form v-if="employee._id" :employee="employee" />
            </div>
        </div>
        
    </div>
    <div class="grid lg:grid-cols-2 gap-6">
        <div>
            <div class="card p-6 h-fit">
                <p class="mb-4 text-lg">Profile:</p>
                <update-profile-form v-if="profile._id" :profile="profile" :admin="true" />
            </div>
        </div>
        <!-- <div class="card p-4 h-fit">
            <p class="mb-4 text-lg">Attendances:</p>
            <mini-attendance-table v-if="attendances" :attendances="attendances" />
        </div> -->
    </div>
   </div>
</template>

<script>
import UpdateEmployeeForm from '@/components/partials/forms/hrm/UpdateEmployeeForm.vue'
import server from '@/services/server'
import UpdatePasswordForm from '@/components/partials/forms/hrm/UpdatePasswordForm.vue'
import UploadAvatarForm from '@/components/partials/forms/hrm/UploadAvatarForm.vue'
import UpdateProfileForm from '@/components/partials/forms/hrm/UpdateProfileForm.vue'
import MiniAttendanceTable from '@/components/partials/tables/hrm/MiniAttendanceTable.vue'
import UpdateEmployeeAccessForm from '@/components/partials/forms/hrm/UpdateEmployeeAccessForm.vue'

export default {
    name: 'UpdateEmployee',
    components: { UpdateEmployeeForm, UpdatePasswordForm, UploadAvatarForm, UpdateProfileForm, MiniAttendanceTable, UpdateEmployeeAccessForm },
    data(){
        return{
            employee: {},
            designations: {},
            profile: {},
            attendances: []
        }
    },
    methods: {
        async getEmployee(){
            try{
                 const response = await server.get('/employee/'+this.$route.params.id)
                 this.employee = response.employee
                 this.profile= response.profile
                 this.attendances = response.attendances
            }catch(e){
                this.$moshaToast('Unable to get employee. Please try again.', {type: 'danger'})
                this.$router.push({name: 'employees'})
            }
        },
        
        async getDesignations(){
           try{
                const response = await server.post('/designation/fetch')
                this.designations = response.designations
           }catch(e){
                this.$moshaToast('Unable to get designations data.', {type: 'danger'})
            }
        }
        
    },
    async created(){
        await this.getEmployee()
        await this.getDesignations()
    }
}
</script>