<template>
    <span class="h-full text-sm hidden lg:flex items-center px-4 font-ubuntu italic">{{date}} | {{time}}</span>
</template>

<script>
import helper from '@/services/helper'
export default {
    name: 'Clock',
    data(){
        return {
            time: '',
            date: ''
        }
    },
    methods: {
        setDate(){
            const date = new Date()
            this.date = `${helper.monthNames[date.getMonth()]} ${date.getDate()}`
        },
        setTime(){
            this.time = helper.formatTime12Hour(new Date())
        }
    },
    created(){
        this.setDate()
        this.setTime()
        setInterval(() => {
            this.setTime()
        }, 1000);
    },
    mounted(){
        
    }
}
</script>