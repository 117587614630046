<template>
    <div>
        <table class="table">
            <thead>
                <tr>
                    <th>Employee</th>
                    <th>Type</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Days</th>
                    <th>Status</th>
                    <th v-show="adminAccess">Edit</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in leaves" :key="row._id">
                    <td>{{employees[row.employee].first_name}} {{employees[row.employee].last_name}}</td>
                    <td>
                        <span v-if="types[row.type]">{{titleCase(types[row.type].title)}}</span>
                    </td>
                    <td>{{formatDate(row.start)}}</td>
                    <td>{{formatDate(row.end)}}</td>
                    <td>{{row.days}}</td>
                    <td>
                        <span v-if="row.status == 1" class="py-1 px-3 rounded-full bg-green-600 text-white text-sm">Approved</span>
                        <span v-if="row.status == 0" class="py-1 px-3 rounded-full bg-gray-500 text-white text-sm">Pending</span>
                        <span v-if="row.status == -1" class="py-1 px-3 rounded-full bg-red-500 text-white text-sm">Reject</span>
                    </td>
                    <td v-show="adminAccess">
                        <button class="btn-link btn-link-blue" @click="openUpdateLeaveModal(row, types[row.type], employees[row.employee])">EDIT</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <modal :active="updateLeaveModal.active" :close="closeUpdateLeaveModal">
            <div class="w-full max-w-[384px] mx-auto px-4 lg:px-0">
                <div class="card p-6">
                    <update-leave-form :employee="updateLeaveModal.employee" :leave="updateLeaveModal.leave" :type="updateLeaveModal.type" :onClose="closeUpdateLeaveModal" :onUpdate="onUpdate" />
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import helper from '@/services/helper'
import Case from 'case'
import Modal from '@/components/elements/Modal.vue'
import UpdateLeaveForm from '../../forms/hrm/UpdateLeaveForm.vue'
export default {
  components: { Modal, UpdateLeaveForm },
    name: 'LeavesTable',
    props: ['leaves', 'employees', 'types', 'onUpdate', 'adminAccess'],
    data(){
        return{
            updateLeaveModal: {
                active: false,
                leave: null,
                type: null,
                employee: null
            }
        }
    },
    methods: {
        formatDate(date){
            return helper.formatDate(new Date(date))
        },
        titleCase(text){
            return Case.title(text)
        },
        openUpdateLeaveModal(leave, type, employee){
            this.updateLeaveModal = {
                active: true,
                leave: leave,
                type: type,
                employee: employee
            }
        },
        closeUpdateLeaveModal(){
            this.updateLeaveModal = {
                active: false,
                leave: null,
                type: null,
                employee: null
            }
        }
    }
}
</script>