const local = {
    save(name, data){
        localStorage.setItem(name, JSON.stringify(data))
    },
    get(name){
        return JSON.parse(localStorage.getItem(name))
    },
    clearAll(){
        localStorage.clear()
    }
}

export default local