<template>
    <div class="welcome-card">
        <div>
            <div class="mb-6 lg:mb-0">
                <p class="card-heading">Welcome {{ employee.first_name }}!</p>
                <!-- <h2 class="text-xl font-bold mb-2"></h2> -->
                <p class="italic">"{{dayQuote}}"</p>
            </div>
            <div>
                <div class="mx-auto">
                    <day-progress
                        v-if="
                            attendances[0] &&
                            checkIfToday(attendances[0].date) === 0
                        "
                        :attendance="attendances[0]"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DayProgress from "../../charts/dashboard/DayProgress.vue";
import helper from "@/services/helper";
export default {
    name: "WelcomeCard",
    props: ["employee", "attendances"],
    data() {
        return {
            phrases: [
                "Together we succeed!",
                "One team, one dream!",
                "Innovate to thrive!",
                "Be the change!",
                "Never give up!",
                "Continuous improvement!",
                "Collaborate for impact!",
                "Think outside the box!",
                "Make it happen!",
                "Lead by example!",
                "Believe in yourself!",
                "Strive for excellence!",
                "Challenge the status quo!",
                "Create a legacy!",
                "Find your passion!",
                "Inspire greatness!",
                "Embrace change!",
                "Think big, act bold!",
                "Take calculated risks!",
                "Celebrate diversity!",
                "Communicate with clarity!",
                "Stay focused and driven!",
                "Learn from mistakes!",
                "Be resilient!",
                "Stay hungry, stay foolish!",
                "Work hard, play hard!",
                "Innovate or die!",
                "Take ownership!",
                "Go above and beyond!",
                "Never stop learning!",
                "Lead with empathy!",
                "Stay positive and optimistic!",
                "Strive for balance!",
                "Think long-term!",
                "Be a problem solver!",
                "Stay curious!",
                "Challenge yourself!",
                "Embrace feedback!",
                "Stay committed to excellence!",
                "Celebrate success!",
                "Believe in yourself!",
                "Strive for excellence!",
                "Challenge the status quo!",
                "Create a legacy!",
                "Find your passion!",
                "Inspire greatness!",
                "Embrace change!",
                "Think big, act bold!",
                "Take calculated risks!",
                "Celebrate diversity!",
                "Communicate with clarity!",
                "Stay focused and driven!",
                "Learn from mistakes!",
                "Be resilient!",
                "Stay hungry, stay foolish!",
                "Work hard, play hard!",
                "Innovate or die!",
                "Take ownership!",
                "Go above and beyond!",
                "Never stop learning!",
                "Lead with empathy!",
                "Stay positive and optimistic!",
                "Strive for balance!",
                "Think long-term!",
                "Be a problem solver!",
                "Stay curious!",
                "Challenge yourself!",
                "Embrace feedback!",
                "Stay committed to excellence!",
                "Celebrate success!",
            ],
            dayQuote: ''
        };
    },
    components: { DayProgress },
    methods: {
        checkIfToday(date1) {
            return helper.compareDates(new Date(date1), new Date());
        },
        setQuote(){
          const date = new Date()
          const day = date.getDate()
          const month = date.getMonth()+1
          if(month%2 == 0){
            this.dayQuote = this.phrases[day]
          }else{
            this.dayQuote = this.phrases.splice(-day, 1)[0]
          }
        }
    },
    created(){
      this.setQuote()
    }
};
</script>
