<template>
    <p class="text-3xl font-bold mb-6">{{client.name}}</p>
    <div class="grid grid-cols-10 gap-6">
        <div class="col-span-4">
            <client-basic-card v-if="client._id" :client="client" />
        </div>
        <div class="col-span-3">
            <client-projects-stat :client="client" class="h-full"  />
        </div>
        <div class="col-span-3">
            <client-social-links :client="client" class="mb-6" />
            <client-contact-persons  :client="client" />
        </div>
    </div>
</template>

<script>
import server from '@/services/server';
import ClientBasicCard from '@/components/partials/info/crm/ClientBasicCard.vue';
import ClientProjectsStat from '@/components/partials/charts/crm/ClientProjectsStat.vue';
import ClientSocialLinks from '@/components/partials/info/crm/ClientSocialLinks.vue';
import ClientContactPersons from '@/components/partials/info/crm/ClientContactPersons.vue';
export default {
  components: { ClientBasicCard, ClientProjectsStat, ClientSocialLinks, ClientContactPersons },
    name: 'ViewClient',
    data(){
        return{
            client: {}
        }
    },
    methods: {
        async getClient(){
            try{
                // console.log(this.$route.params.id)
                const response = await server.get('/client/' + this.$route.params.id)
                this.client = response.client
            }catch(e){
                this.$moshaToast(
                    "Unable to get client Please try again.",
                    { type: "danger" }
                );
            }
        }
    },
    async created(){
        await this.getClient()
    }
}
</script>