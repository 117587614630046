<template>
    <div>
        <div class="grid lg:grid-cols-2 lg:gap-6 mb-6">
            <div class="mb-6">
                <update-client-basic-form class="mb-6" :client="client" />
                <div class="card pt-6 mb-6">
                    <p class="card-heading mx-6">Services</p>
                    <client-services-table
                        :client="client"
                        :services="services"
                        :onUpdate="getClient"
                    />
                    <div class="p-6">
                        <add-client-service-form
                            :client="client"
                            :services="services"
                            :onUpdate="getClient"
                        />
                    </div>
                </div>
                <div class="card pt-6">
                    <p class="card-heading mx-6">Passwords</p>
                    <client-passwords-table
                        class="mb-6"
                        :client="client"
                        :onUpdate="getClient"
                    />
                    <div class="p-6">
                        <update-client-password-form
                            :client="client"
                            :onUpdate="getClient"
                        />
                    </div>
                </div>
            </div>
            <div class="">
                <update-client-logo
                    class="mb-6"
                    :client="client"
                    v-if="client._id"
                />
                <div class="card pt-6 mb-6">
                    <p class="card-heading mx-6">Contact Persons</p>
                    <contact-persons-table
                        v-if="client._id"
                        :client="client"
                        :onUpdate="getClient"
                    />
                    <contact-person-form
                        :client="client"
                        :onUpdate="getClient"
                    />
                </div>
                <client-other-details-form v-if="client._id" :client="client" />
            </div>
        </div>
        <client-files :client="client" :files="files" :onUpdate="getClient" />
    </div>
</template>

<script>
import UpdateClientBasicForm from "@/components/partials/forms/crm/UpdateClientBasicForm.vue";
import server from "@/services/server";
import UpdateClientLogo from "@/components/partials/forms/crm/UpdateClientLogo.vue";
import ContactPersonForm from "@/components/partials/forms/crm/ContactPersonForm.vue";
import ContactPersonsTable from "@/components/partials/tables/crm/ContactPersonsTable.vue";
import ClientPasswordsTable from "@/components/partials/tables/crm/ClientPasswordsTable.vue";
import UpdateClientPasswordForm from "@/components/partials/forms/crm/UpdateClientPasswordForm.vue";
import ClientOtherDetailsForm from "@/components/partials/forms/crm/ClientOtherDetailsForm.vue";
import AddClientServiceForm from "@/components/partials/forms/crm/AddClientServiceForm.vue";
import ClientServicesTable from "@/components/partials/tables/crm/ClientServicesTable.vue";
import ClientFiles from '@/components/partials/lists/crm/ClientFiles.vue';
export default {
    components: {
        UpdateClientBasicForm,
        UpdateClientLogo,
        ContactPersonForm,
        ContactPersonsTable,
        ClientPasswordsTable,
        UpdateClientPasswordForm,
        ClientOtherDetailsForm,
        AddClientServiceForm,
        ClientServicesTable,
        ClientFiles,
    },
    name: "UpdateClient",
    data() {
        return {
            client: {},
            services: {},
            files: {}
        };
    },
    methods: {
        async getClient() {
            try {
                const response = await server.get(
                    "/client/" + this.$route.params.id
                );
                this.client = response.client
                this.files = response.files
            } catch (e) {
                this.$moshaToast("Unable to get cleint. Please try again.", {
                    type: "danger",
                });
            }
        },
        async getService() {
            try {
                const response = await server.post("/service/fetch", {});
                for (let i in response.services) {
                    this.services[response.services[i]._id] =
                        response.services[i];
                }
                // this.services = response.services
            } catch (e) {
                this.$moshaToast("Unable to get services. Please try again.", {
                    type: "danger",
                });
            }
        },

    },
    async created() {
        await this.getClient();
        await this.getService();
    },
};
</script>
