<template>
    <div class="text-sm">
        <div class="mat-input mb-6">
            <label for="">Employee:</label>
            <input type="text" disabled :value="employee.first_name + ' ' + employee.last_name">
        </div>
       <div class="grid grid-cols-2 gap-4 mb-6">
            <div class="mat-input">
                <label for="">Start:</label>
                <input type="date" disabled :value="formatDate(leave.start)">
            </div>
            <div class="mat-input">
                <label for="">End:</label>
                <input type="date" disabled :value="formatDate(leave.end)">
            </div>
       </div>
       <div class="grid grid-cols-2 gap-4 mb-6">
            <div class="mat-input">
                <label for="">Days: (left {{daysLeft}})</label>
                <!-- <input type="text" disabled :value="calcDays(leave.end, leave.start)"> -->
                <input type="text" disabled :value="leave.days">
            </div>
            <div class="mat-input">
                <label for="">Type:</label>
                <input v-if="type" type="text" disabled :value="type.title">
            </div>
       </div>
        <div class="mat-input mb-6">
            <label for="">Description:</label>
            <textarea disabled :value="leave.description"></textarea>
        </div>
        <div class="mat-input mb-6">
            <label for="">Response:</label>
            <textarea v-model="response" placeholder="Please enter a note here"></textarea>
        </div>
        <div class="flex gap-3">
            <button class="btn btn-primary btn-block" @click="save(1)" :disabled="leave.status == 1">Approve</button>
            <button class="btn btn-danger btn-block" @click="save(-1)" :disabled="leave.status == -1">Reject</button>
            <button class="btn btn-white btn-block" @click="onClose">Close</button>
        </div>
    </div>
</template>

<script>
import helper from '@/services/helper'
import server from '@/services/server'
import local from '@/services/local'
export default {
    name: 'UpdateLaveForm',
    props: ['employee', 'leave', 'type', 'onClose', 'onUpdate'],
    data(){
        return{
            response: this.$props.leave.response,
            daysLeft: 0
        }
    },
    methods: {
        formatDate(date){
            return helper.formatDate(new Date(date))
        },
        calcDays(date1, date2){
            return helper.getDaysBetweenDates(new Date(date1), new Date(date2))
        },
        async save(status){
            try{
                await server.patch('/leave/' + this.$props.leave._id, {response: this.response, status: status})
                this.$moshaToast("Leave updated successfully.", {type: "success"})
                await this.$props.onUpdate()
                this.$props.onClose()
            }catch(e){
                this.$moshaToast("Unable to save leave. Please try again.", {type: "danger"});
            }
        },
        async getCount(){
            try{
                const response = await server.get('/leave/days/' + this.$props.employee._id)
                this.daysLeft = local.get('settings').hrm.allowed_leave - response.days
            }catch(e){
                this.$moshaToast('Unable get data. Please try again.', {type: 'danger'})
            }
        }
    },
    async created(){
        await this.getCount()
    }
}
</script>