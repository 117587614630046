<template>
    <div>
        <table class="table">
        <thead>
            <tr>
                <th>id</th>
                <th>name</th>
                <th>designation</th>
                <th>email</th>
                <th>status</th>
                <th>edit</th>
                <th>delete</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="row in employees" :key="row._id">
                <td>{{row.id}}</td>
                <td>{{row.first_name}} {{row.last_name}}</td>
                <td><span v-if="designations[row.designation]">{{designations[row.designation].title}}</span></td>
                <td>{{row.email}}</td>
                <td>
                    <span v-show="row.status == 1" class="text-green-600">Active</span>
                    <span v-show="row.status == 0" class="text-gray-500">Inactive</span>
                </td>
                <td>
                    <router-link class="btn-link btn-link-blue" :to="{name: 'update-employee', params: {id: row._id}}">
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 inline">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>  -->
                        EDIT
                    </router-link>
                </td>
                <td>
                    <button class="btn-link btn-link-red" @click="openDeleteModal(row._id)">
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 inline">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg> -->
                        DELETE
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
    <delete-modal :active="deleteModal.active" v-if="deleteModal.id" :onClose="closeDeleteModal" :onConfirm="deleteEmployee" />
    </div>
</template>

<script>
import DeleteModal from '@/components/elements/DeleteModal.vue'
import server from '@/services/server'
export default {
  components: { DeleteModal },
    name: 'EmployeesTable',
    props: ['employees', 'designations', 'onUpdate'],
    data(){
        return{
            deleteModal: {
                active: false,
                id: null
            }
        }
    },
    methods: {
        closeDeleteModal(){
            this.deleteModal = {
                active: false,
                id: null
            }
        },
        openDeleteModal(id){
            this.deleteModal = {
                active: true,
                id: id
            }
        },
        async deleteEmployee(){
            try{
                if(!this.deleteModal.id){
                    throw new Error()
                }
                await server.delete('/employee/' + this.deleteModal.id)
                this.$moshaToast('Employee deleted successfully.', {type: 'success'})
                this.closeDeleteModal()
                await this.$props.onUpdate()
            }catch(e){
                this.$moshaToast('Unable to delete employee. Please try again.', {type: 'danger'})
            }
        }
    }
}
</script>