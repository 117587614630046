<template>
    <div>
        <table class="table">
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Edit</th>
                    <th>Delete</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in types" :key="row._id">
                    <td>{{titleCase(row.title)}}</td>
                    <td>
                        <button class="btn-link btn-link-blue">EDIT</button>
                    </td>
                    <td>
                        <button class="btn-link btn-link-red" @click="openDeleteModal(row._id)">DELETE</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <delete-modal :active="deleteModal.active" :onClose="closeDeleteModal" :onConfirm="deleteItem" />
    </div>
</template>

<script>

import Case from 'case'
import DeleteModal from '@/components/elements/DeleteModal.vue'
import server from '@/services/server'

export default {
  components: { DeleteModal },
    name: 'LeaveTypesTable',
    props: ['types', 'onUpdate'],
    data(){
        return {
            deleteModal: {
                active: false,
                id: null
            }
        }
    },
    methods: {
        titleCase(text){
            return Case.title(text)
        },
        closeDeleteModal(){
            this.deleteModal = {
                active: false,
                id: null
            }
        },
        openDeleteModal(id){
            this.deleteModal = {
                active: true,
                id: id
            }
        },
        async deleteItem(){
            try{
                await server.delete('/leave/type/' + this.deleteModal.id)
                this.$moshaToast("Leave type deleted successfully.", { type: "success"})
                await this.$props.onUpdate()
                this.closeDeleteModal()
            }catch(e){
                this.$moshaToast("Unable to delete leave types. Please try again.", { type: "danger"});
            }
        }
    }
}
</script>