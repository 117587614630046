<template>
    <div class="w-64 lg:w-16 min-h-screen absolute left-0 top-0 bg-gray-800  text-white transition-all z-[20] py-3 [&.close]:-left-64" :class="{ close: !state }">
        <span class="w-32 lg:w-8 lg:mx-auto block mb-12 mx-4">
            <router-link :to="{name: 'dashboard'}">
                <img src="@/assets/images/icon.png" class="w-full hidden lg:block" alt="">
                <img src="@/assets/images/logo.png" class="w-full block lg:hidden" alt="">
            </router-link>
        </span>

        <div class="w-full relative flex lg:block lg:[&>div]:right-0 [&>button]:hover:text-blue-500 [&>div]:-right-48 lg:[&>div]:hover:-right-48 lg:[&>div]:w-0 [&>div]:w-48 lg:[&>div]:hover:w-48 mb-4">
            <button class="text-2xl rounded flex justify-center items-center py-2 lg:mx-auto ml-4">
                <i class="fa-light fa-gauge"></i>
            </button>
            <div class="rounded-r-lg bg-gray-800 lg:absolute top-0 text-white transition-all lg:z-[-1] overflow-hidden hover:[&>button]:text-blue-500 shadow-lg">
                <button @click="routeChnage('dashboard')" class="py-2 px-4 block w-full text-left">Dashboard</button>
            </div>
        </div>


        <div class="w-full relative flex items-start lg:block lg:[&>div]:right-0 [&>button]:hover:text-blue-500 [&>div]:-right-48 lg:[&>div]:hover:-right-48 lg:[&>div]:w-0 [&>div]:w-48 lg:[&>div]:hover:w-48 mb-4">
            <button class="text-2xl rounded flex justify-center items-center py-2 lg:mx-auto ml-4">
                <i class="fa-light fa-users"></i>
            </button>
            <div class="rounded-r-lg bg-gray-800 lg:absolute top-0 text-white text-left transition-all lg:z-[-1] overflow-hidden hover:[&>button]:text-blue-500 shadow-lg">
                <button class="py-2 px-4 block w-48 text-left" @click="routeChnage('employees')">Employees</button>
                <button class="py-2 px-4 block w-48 text-left" @click="routeChnage('designations')">Designations</button>
                <button class="py-2 px-4 block w-48 text-left" @click="routeChnage('attendances')">Attendances</button>
                <button class="py-2 px-4 block w-48text-left" @click="routeChnage('leaves')">Leaves</button>
            </div>
        </div>


         <div class="w-full relative flex lg:block lg:[&>div]:right-0 [&>button]:hover:text-blue-500 [&>div]:-right-48 lg:[&>div]:hover:-right-48 lg:[&>div]:w-0 [&>div]:w-48 lg:[&>div]:hover:w-48 mb-4">
            <button class="text-2xl rounded flex justify-center items-center py-2 lg:mx-auto ml-4">
                <i class="fa-light fa-newspaper"></i>
            </button>
            <div class="rounded-r-lg bg-gray-800 lg:absolute top-0 text-white text-left transition-all lg:z-[-1] overflow-hidden hover:[&>button]:text-blue-500 shadow-lg">
                <button class="py-2 px-4 block w-full text-left" @click="routeChnage('notices')">Notices</button>
            </div>
        </div>


    </div>
</template>

<script>
import local from "@/services/local";
export default {
    name: "Sidebar",
    props: ["state", "chnageState", "onToggle"],
    data() {
        return {
        };
    },
    methods: {
        validModule(id) {
            if (this.employee.is_admin) {
                return true;
            }
            return this.employee.access.indexOf(id) >= 0;
        },
        // logout() {
        //     server.get("/auth/logout");
        //     local.clearAll();
        //     this.$router.push("/");
        // },
        routeChnage(name){
             if (window.screen.width < 1020) {
                this.$props.onToggle()
            }
            this.$router.push({name})
            
        }
    },
    watch: {
    },
    created() {
        this.emitter.on("update-local-employee", () => {
            this.employee = local.get("_auth").employee;
        });
    },
};
</script>


<style scoped>
.transition-sidebarmenu{
    animation: sidebarmenu-kf 2s forwards;
}

@keyframes sidebarmenu-kf {
  from {
    clip-path: inset(0 0 0 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}
</style>