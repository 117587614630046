<template>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div class="card h-fit divide-y">
            <p class="text-xl p-6">Notifications</p>
            <div class="w-full overflow-x-auto">
                <notifications-table :notices="notices" :onUpdate="getNotices" />
            </div>
        </div>
        <div class="card p-6 h-fit">
            <add-notice-form :onUpdate="getNotices" />
        </div>
    </div>
</template>

<script>
import NotificationsTable from '@/components/partials/tables/notice/NoticesTable.vue'
import AddNoticeForm from '@/components/partials/forms/notice/AddNoticeForm.vue'
import server from '@/services/server'

export default {
    components: { NotificationsTable, AddNoticeForm },
    name: 'Notices',
    data(){
        return{
            notices: []
        }
    },
    methods: {
        async getNotices(){
            try{
                const response = await server.post('/notice/fetch', {})
                this.notices = response.notices
            }catch(e){
                this.$moshaToast('Unable to get notices. Please try again.', {type: 'danger'})
            }
        }
    },
    async created(){
        await this.getNotices()
    }  
    
}
</script>