<template>
    <div class="card py-6">
        <p class="card-heading mx-6">Services</p>
        <table class="table">
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="service in services" :key="service._id">
                    <td>{{service.title}}</td>
                    <td>{{service.description}}</td>
                    <td>
                        <button class="btn-link btn-link-blue mr-3" @click="openEditModal(service)">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                            </svg>
                        </button>
                        <button class="btn-link btn-link-red" @click="openDeleteModal(service._id)">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <delete-modal :active="deleteModal.active" :onClose="closeDeleteModal" :onConfirm="deleteItem" />
        <modal :active="editModal.active" :close="closeEditModal">
            <div class="max-w-full w-[500px] mx-auto">
                <update-service-form :service="editModal.service" :onClose="closeEditModal"  />
            </div>
        </modal>
    </div>
</template>

<script>
import DeleteModal from '@/components/elements/DeleteModal.vue'
import server from '@/services/server'
import Modal from '@/components/elements/Modal.vue'
import UpdateServiceForm from '../../forms/crm/UpdateServiceForm.vue'
export default {
  components: { DeleteModal, Modal, UpdateServiceForm },
    name: 'ServicesTable',
    props: ['services', 'onUpdate'],
    data(){
        return{
            deleteModal: {
                active: false,
                id: null
            },
            editModal: {
                active: false,
                service: null
            }
        }
    },
    methods: {
        openDeleteModal(id){
            this.deleteModal = {
                active: true,
                id
            }
        },
        closeDeleteModal(){
            this.deleteModal = {
                active: false,
                id: null
            }
        },
        async deleteItem(){
            try{
                await server.delete('/service/'+this.deleteModal.id)
                this.closeDeleteModal()
                await this.$props.onUpdate()
                this.$moshaToast("Service delete successfullt.", {
                    type: "success",
                });
            }catch(e){
                this.$moshaToast("Unable to delete service. Please try again.", {
                    type: "danger",
                });
            }
        },
        openEditModal(item){
            this.editModal = {
                active: true,
                service: item
            }
        },
        async closeEditModal(update = false){
            this.editModal = {
                active: false,
                service: null
            }
            if(update){
                await this.$props.onUpdate()
            }
        }

    }
}
</script>