import axios from "axios"
import local from "../local"


const baseUrl = 'https://apiv1.brandwind.in'
// const baseUrl = 'http://192.168.0.201:3000'

const server = {
    url: baseUrl + '/api/v1',

    resolve(uri){
        return this.url + uri;
    },

    async post(uri, data, auth = true){
        const headers = {
            'Content-Type': 'application/json'
        }
        if(auth){
            const token = local.get('_auth').login.token
            headers.Authorization = `Bearer ${token}`
        }
        const response = await axios.post(this.url + uri, JSON.stringify(data), {headers})
        return response.data
    },

    async patch(uri, data, auth = true){
        const headers = {
            'Content-Type': 'application/json'
        }
        if(auth){
            const token = local.get('_auth').login.token
            headers.Authorization = `Bearer ${token}`
        }
        const response = await axios.patch(this.url + uri, JSON.stringify(data), {headers})
        return response.data
    },

    async patchUpload(uri, data, auth = true){
        const headers = {
            'Content-Type': 'multipart/form-data'
        }
        if(auth){
            const token = local.get('_auth').login.token
            headers.Authorization = `Bearer ${token}`
        }
        const response = await axios.patch(this.url + uri, data, {headers})
        return response.data
    },
    async postUpload(uri, data, auth = true){
        const headers = {
            headers: {'Content-Type': 'multipart/form-data'}
        }

        if(data.params){
            headers.params = data.params
        }
        
        if(auth){
            const token = local.get('_auth').login.token
            headers.Authorization = `Bearer ${token}`
        }
        const response = await axios.post(this.url + uri, data.files, headers)
        return response.data
    },

    async get(uri, auth = true){
        const headers = {}
        if(auth){
            const token = local.get('_auth').login.token
            headers.Authorization = `Bearer ${token}`
        }
        const response = await axios.get(this.url + uri, {headers})
        return response.data
    },

    async delete(uri, auth = true){
        const headers = {}
        if(auth){
            const token = local.get('_auth').login.token
            headers.Authorization = `Bearer ${token}`
        }
        const response = await axios.delete(this.url + uri, {headers})
        return response.data
    },
    
    axios,

}

export default server