<template>
  <div>
    <div class="w-full max-w-[384px] mx-auto mb-20">
      <p class="mb-6 text-2xl">Create Designation</p>
      <div class="card p-6">
        <add-designation-form v-if="reload" :onSave="newDeignation" />
      </div>
    </div>
    <div class="w-full max-w-[500px] mx-auto mb-20">
        <div class="card py-6">
            <designations-table :designations="designations" :onUpdate="getDesignations" />
        </div>
    </div>
  </div>
</template>

<script>
import server from "@/services/server";
import AddDesignationForm from "@/components/partials/forms/hrm/AddDesignationForm.vue";
import DesignationsTable from '@/components/partials/tables/hrm/DesignationsTable.vue';

export default {
  components: { AddDesignationForm, DesignationsTable },
  name: "Designations",
  data() {
    return {
      designations: [],
      reload: true
    };
  },
  methods: {
    async getDesignations() {
      try {
        const response = await server.post("/designation/fetch", {}, true);
        this.designations = response.designations;
      } catch (e) {
        this.$moshaToast("Unable to get designations.", { type: "danger" });
      }
    },
    async newDeignation(){
        await this.getDesignations()
        this.reload = false
        this.$nextTick(() => {
            this.reload = true
        })
    }
  },
  async created() {
    await this.getDesignations();
  },
};
</script>