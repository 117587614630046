<template>
  <div>
    <table class="table">
      <tbody>
        <tr v-for="notice in notices" :key="notice._id">
          <td>
            <button class="w-7 h-7 rounded" :class="notice.color"></button>
          </td>
          <td class="text-left">{{ notice.title }}</td>
          <td>{{ formatDate(notice.createdAt) }}</td>
          <td><button class="btn-link btn-link-blue" @click="updateModal = {active: true, notice}">EDIT</button></td>
          <td><button class="btn-link btn-link-red" @click="deleteModal = {active: true, id: notice._id}">DELETE</button></td>
        </tr>
      </tbody>
    </table>
    <modal :active="updateModal.active" :close="closeUpdateModal">
        <div class="w-full lg:w-[500px]">
            <div class="card p-4">
                <update-notice-form :notice="updateModal.notice" :onUpdate="onUpdate" :onClose="closeUpdateModal" />
            </div>
        </div>
    </modal>
    <delete-modal :active="deleteModal.active" :onClose="closeDeleteModal" :onConfirm="confirmDelete" />
  </div>
</template>

<script>
import Modal from '@/components/elements/Modal.vue';
import UpdateNoticeForm from '../../forms/notice/UpdateNoticeForm.vue';
import DeleteModal from '@/components/elements/DeleteModal.vue';
import server from '@/services/server';
import helper from '@/services/helper';
export default {
  components: { Modal, UpdateNoticeForm, DeleteModal },
  name: "NoticesTable",
  props: ["notices", 'onUpdate'],
  data(){
    return{
      updateModal: {
        active: false,
        notice: null
      },
      deleteModal: {
        active: false,
        id: null
      }
    }
  },
  methods: {
    closeUpdateModal(){
      this.updateModal = {
        active: false,
        notice: null
      }
    },
    closeDeleteModal(){
      this.deleteModal = {active: false, id: null}
    },
    async confirmDelete(){
      try{
        await server.delete('/notice/' + this.deleteModal.id)
        this.closeDeleteModal()
        this.$moshaToast('Notice deleted successfully.', {type: 'success'})
        await this.$props.onUpdate()
      }catch(e){
        this.$moshaToast('Unable to delete notice. Please try again.', {type: 'danger'})
      }
    },
    formatDate(date){
      return helper.formatDate(new Date(date))
    }
  }
};
</script>