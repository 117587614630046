<template>
    <div class="card p-6">
          <p class="card-heading">Monthly Status</p>
          <div class=" flex justify-center text-center gap-4 divide-x text-sm">
            <div class="w-full">
            <p class="mb-2">On Time</p>
            <p class="text-2xl">{{attendanceScore.onTime}}</p>
          </div>
          <div class="w-full">
            <p class="mb-2">Late</p>
            <p class="text-2xl">{{attendanceScore.late}}</p>
          </div>
          <div class="w-full">
            <p class="mb-2">Leaves</p>
            <p class="text-2xl">{{attendanceScore.leaves}}</p>
          </div>
          <div class="w-full">
            <p class="mb-2">Absent</p>
            <p class="text-2xl">{{attendanceScore.absent}}</p>
          </div>
          </div>
        </div>
</template>

<script>
export default {
    name: 'AttendanceScoreBoard',
    props: ['attendanceScore']
}
</script>