<template>
    <div class="card p-6 w-full">
        <p class="card-heading">Update Service</p>
        <vee-form class="text-sm" :validation-schema="schema" @submit="save" :initial-values="service">
            <div class="mat-input mb-6">
                <vee-field name="title" v-slot="{ meta, field }">
                    <label for="">Title:*</label>
                    <input
                        type="text"
                        name="title"
                        v-bind="field"
                        placeholder="Enter service here"
                        :class="{ 'is-invalid': !meta.valid && meta.touched }"
                    />
                    <error-message name="title" class="invalid-feedback" />
                </vee-field>
            </div>
            <div class="mat-input mb-6">
                 <vee-field name="description" v-slot="{ meta, field }">
                    <label for="">Description:</label>
                    <textarea name="description" v-bind="field" placeholder="Enter a quick description" :class="{ 'is-invalid': !meta.valid && meta.touched }"></textarea>
                    <error-message name="description" class="invalid-feedback" />
                </vee-field>
            </div>
            <div class="flex gap-3">
                <button class="btn btn-primary btn-block">
                    <span v-show="!inSubmission">Save</span>
                    <span class="text-center" v-show="inSubmission">
                        <svg class="animate-spin -ml-1 mr-1 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Please wait...
                    </span>
                </button>
                <button type="button" class="btn btn-white btn-block" @click="onClose">
                    Close
                </button>
            </div>
         </vee-form>
    </div>
</template>

<script>
import server from '@/services/server'
export default {
    name: 'UpdateServiceForm',
    props: ['service', 'onClose'],
    data(){
        return{
            schema: {
                title: 'required'
            },
            inSubmission: false
        }
    },
    methods: {
        async save(values){
             this.inSubmission = true
            try{
                await server.patch('/service/' + this.$props.service._id, values)
                this.$moshaToast("Service updated successfully.", {
                    type: "success",
                });
                await this.$props.onClose(true)
            }catch(e){
                this.$moshaToast("Unable to updated service. Please try again.", {
                    type: "danger",
                });
            }
            this.inSubmission = false
        }
    }
}
</script>