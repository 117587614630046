<template>
  <Teleport v-if="!ready" to="#preloader">
    <preloader />
  </Teleport>
  <router-view :ready="setReady" v-if="!$route.meta.auth" />
  <frame :ready="setReady" v-if="$route.meta.auth" />
</template>

<script>
import Frame from '@/views/Frame.vue'
import Preloader from './components/elements/Preloader.vue'
export default {
  components: { Frame, Preloader },
  name: 'App',
  data() {
    return {
      ready: false
    }
  },
  methods: {
    setReady(){
      this.ready = true
    }
  }
}
</script>
