import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from 'mosha-vue-toastify'
import VeeValidate from './services/validation'
import mitt from 'mitt'
import './assets/tailwind.css'
import 'mosha-vue-toastify/dist/style.css'

const emitter = mitt()

const app = createApp(App)
app.config.globalProperties.emitter = emitter
app.use(store)
app.use(router)
app.use(Toast)
app.use(VeeValidate)

app.mount('#app')
