<template>
    <form @submit="save">
        <div class="flex justify-between items-center mx-2 gap-2 mb-6">
            <div class="flex gap-2">
                <button type="button" class="w-7 h-7 bg-red-500 rounded hover:scale-110 hover:shadow" @click="editor.color = 'bg-red-500'"></button>
                <button type="button" class="w-7 h-7 bg-green-500 rounded hover:scale-110 hover:shadow" @click="editor.color = 'bg-green-500'"></button>
                <button type="button" class="w-7 h-7 bg-amber-500 rounded hover:scale-110 hover:shadow" @click="editor.color = 'bg-amber-500'"></button>
                <button type="button" class="w-7 h-7 bg-blue-500 rounded hover:scale-110 hover:shadow" @click="editor.color = 'bg-blue-500'"></button>    
            </div>
            <div class="flex items-center gap-4">
                <span>{{editor.status == 1 ? 'Publish' : 'Draft'}}</span><span><toggle-switch :default="editor.status === 1" :onToggle="toggleStatus" /></span>
            </div>
        </div>
        <div>
            <div class="mat-input mb-6">
                <input type="text" placeholder="Enter title ..." v-model="editor.title" required>
            </div>
            <div class="px-6 py-4 lg:px-4 text-white rounded-lg focus:outline-none shadow w-full overflow-y-visible" :class="editor.color" contenteditable="true" ref="update-editor"></div>
            <div class="mt-6 flex justify-between items-center">
                <div class="w-full">
                    <button class="btn btn-primary mr-4"><i class="fa-solid fa-floppy-disk mr-2"></i>
                        <span v-show="!inSubmission">Save</span>
                        <span class="text-center" v-show="inSubmission">
                            <svg class="animate-spin -ml-1 mr-1 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Please wait...
                        </span>
                    </button>
                </div>
                <div class="mat-input text-sm">
                    <label for="">Expire:</label>
                    <input type="date" v-model="editor.expire">
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import server from '@/services/server'
import helper from '@/services/helper'
import ToggleSwitch from '@/components/elements/ToggleSwitch.vue'
export default {
  components: { ToggleSwitch },
    name: 'UpdateNoticeForm',
    props: ['onUpdate', 'notice', 'onClose'],
    data(){
        return{
            editor: {
                color: 'bg-blue-500',
                title: '',
                expire: '',
                status: 1
            },
            inSubmission: false
        }
    },
    methods: {
        async save(e){
            e.preventDefault()
            this.inSubmission = true
            try{
                await server.patch('/notice/' + this.$props.notice._id, {...this.editor, content: this.$refs['update-editor'].innerHTML})
                this.$moshaToast('Notice updated successfully.', {type: 'success'})
                await this.$props.onUpdate()
                this.$props.onClose()
            }catch(e){
                this.$moshaToast('Unable to update notice. Please try again.', {type: 'danger'})
            }
            this.inSubmission = false
        },
        toggleStatus(value){
            this.editor.status = value ? 1 : 0
        }
    },
    created(){
        this.editor = {
            color: this.$props.notice.color,
            title: this.$props.notice.title,
            expire: this.$props.notice.expire ? helper.formatDate(new Date(this.$props.notice.expire)) : '',
            status: this.$props.notice.status
        }
    },
    mounted(){
        this.$refs['update-editor'].innerHTML = this.$props.notice.content
    }
}
</script>