<template>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div class="card p-4 h-fit">
            <p class="text-2xl mb-4">Brandwind ERP</p>
            <p class="text-xl mb-4">Beta v0.3.5</p>
            <p class="mb-4 text-justify">
                Brandwind ERP is a comprehensive enterprise resource planning software that offers a wide range of features and functionalities to help businesses manage their operations more efficiently. The software is designed to automate and streamline key business processes such as inventory management, accounting, customer relationship management, supply chain management, and human resources. The software also includes advanced reporting and analytics tools that enable businesses to gain valuable insights into their operations and performance. With Brandwind ERP, businesses can improve productivity, reduce costs, and enhance collaboration across departments, making it an ideal solution for companies of all sizes and industries.
            </p>
            <div>
                <p class="text-xl">Developed By</p>
                <p class="text-lg">Kunal A. Khan</p>
                <p>Senior Developer, Brandwind</p>
            </div>
        </div>
        <div class="card p-4 h-fit">
            <p class="text-xl mb-4">Whats New</p>
            <div class="pl-4">
                <p class="mb-2" v-for="update in updated" :key="update">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 inline">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg> {{update}}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About',
    data(){
        return{
            updated: [
                'Fix max time shows on dashboard',
                'Fix dashboard monthly attendance chart',
                'View actual time under the progress bar.',
                'Show monthly attendance hours in dashboard.',
                'Cron job to reset attendance hours for no end'
            ]
        }
    }
}
</script>