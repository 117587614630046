<template>
    <div class="text-sm">
    <vee-form
    :validation-schema="schema"
    @submit="save"
    :initial-values="employee"
  >
    <div class="mb-8 grid grid-cols-2 gap-4">
      <div class="mat-input">
        <vee-field name="serial" v-slot="{ meta, field }">
          <label for="" class="mb-2 block">Serial:*</label>
          <input
            type="text"
            name="serial"
            v-bind="field"
            :disabled="!admin"
            :class="{ 'is-invalid': !meta.valid && meta.touched }"
          />
          <error-message name="serial" class="invalid-feedback" />
        </vee-field>
      </div>
      <div class="mat-input">
        <vee-field name="id" v-slot="{ meta, field }">
          <label for="" class="mb-2 block">Id:*</label>
          <input
            type="text"
            name="id"
            v-bind="field"
            :disabled="!admin"
            :class="{ 'is-invalid': !meta.valid && meta.touched }"
          />
          <error-message name="id" class="invalid-feedback" />
        </vee-field>
      </div>
    </div>
    <div class="mb-8 grid grid-cols-2 gap-4">
      <div class="mat-input">
        <vee-field name="first_name" v-slot="{ meta, field }">
          <label for="" class="mb-2 block">First Name:*</label>
          <input
            type="text"
            name="first_name"
            v-bind="field"
            placeholder="First name"
            :class="{ 'is-invalid': !meta.valid && meta.touched }"
          />
          <error-message name="first_name" class="invalid-feedback" />
        </vee-field>
      </div>
      <div class="mat-input">
        <vee-field name="last_name" v-slot="{ meta, field }">
          <label for="" class="mb-2 block">Last Name:</label>
          <input
            type="text"
            name="last_name"
            v-bind="field"
            placeholder="Last name"
            :class="{ 'is-invalid': !meta.valid && meta.touched }"
          />
          <error-message name="last_name" class="invalid-feedback" />
        </vee-field>
      </div>
    </div>
    <div class="mb-8 grid grid-cols-2 gap-4">
      <div class="mat-input">
        <vee-field name="nick_name" v-slot="{ meta, field }">
          <label for="" class="mb-2 block">Nick Name:</label>
          <input
            type="text"
            name="nick_name"
            v-bind="field"
            placeholder="ex. Tony Stark, Hulk"
            :class="{ 'is-invalid': !meta.valid && meta.touched }"
          />
          <error-message name="nick_name" class="invalid-feedback" />
        </vee-field>
      </div>
      <div class="mat-input">
        <vee-field name="gender" v-slot="{ meta, field }">
          <label for="" class="mb-2 block">Gender:*</label>
          <select name="gender" v-bind="field" :class="{ 'is-invalid': !meta.valid && meta.touched }">
              <option value="">Select gender</option>
              <option value="f">Female</option>
              <option value="m">Male</option>
              <option value="o">Others</option>
          </select>
          <error-message name="gender" class="invalid-feedback" />
        </vee-field>
      </div>
    </div>
    <div class="mb-8 grid grid-cols-2 gap-4">
      <div class="mat-input">
        <vee-field name="email" v-slot="{ meta, field }">
          <label for="" class="mb-2 block">Email:*</label>
          <input
            type="email"
            name="email"
            v-bind="field"
            placeholder="someone@brandwind.in"
            :class="{ 'is-invalid': !meta.valid && meta.touched }"
          />
          <error-message name="email" class="invalid-feedback" />
        </vee-field>
      </div>

      <div class="mat-input">
        <vee-field name="designation" v-slot="{ meta, field }">
          <label for="" class="mb-2 block">Designation:*</label>
          <select name="designation" v-bind="field" :class="{ 'is-invalid': !meta.valid && meta.touched }" :disabled="!admin">
              <option
                v-for="designation in designations"
                :key="designation._id"
                :value="designation._id"
                >
                {{ chageCase(designation.title) }}
              </option>
          </select>
          <error-message name="designation" class="invalid-feedback" />
        </vee-field>
      </div>
    </div>
    <div class="mb-8 grid grid-cols-2 gap-4" v-show="admin">

      <div class="flex justify-center items-center">
        <span class="px-4 py-2 inline-block">Admin Access:</span>
        <toggle-switch :default="formData.is_admin" :onToggle="onAdminToggle" />
      </div>
      
      <div class="flex justify-center items-center">
        <span class="px-4 py-2 inline-block">Allow login:</span>
        <toggle-switch :default="formData.status == 1" :onToggle="onStatusToggle" />
      </div>
    </div>

    <div class="">
      <button :disabled="inSubmission" type="submit" class="btn btn-primary btn-block">
        <span v-show="!inSubmission">Save</span>
        <span class="text-center" v-show="inSubmission">
            <svg class="animate-spin -ml-1 mr-1 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Please wait...
        </span>
      </button>
    </div>
  </vee-form>
  </div>
</template>

<script>
import ToggleSwitch from '@/components/elements/ToggleSwitch.vue'
import Case from 'case'
import server from '@/services/server'
import local from '@/services/local'
export default {
    name: 'UpdateEmployeeForm',
    components: { ToggleSwitch },
    props: ['employee', 'designations', 'admin'],
    data(){
        return{
             schema: {
                first_name: "required|alpha|min:2|max:50",
                last_name: "alphaSpaces|max:50",
                email: "required|email",
                serial: 'required|numeric',
                id: 'required',
                designation: 'required',
                gender: 'required'
            },
            formData: {status: this.employee.status, is_admin: this.employee.is_admin},
            inSubmission: false
        }
    },
    methods: {
        chageCase(text){
            return Case.title(text)
        },
        async save(values){
          const url = this.$props.admin ? '/employee/' + this.$props.employee._id : '/employee/self'
          try{
            this.inSubmission = true
            values.status = this.formData.status
            values.is_admin = this.formData.is_admin
            const response = await server.patch(url, values)
            this.$moshaToast('Employee saved successfully.', {type: 'success'})
            if(!this.$props.admin){
              const past = local.get('_auth')
              local.save('_auth', {...past, employee: response})
              this.emitter.emit('update-local-employee')
            }
          }catch(e){
            this.$moshaToast('Unable to update employee. Please try again.', {type: 'danger'})
          }
          this.inSubmission = false
        },
        onStatusToggle(value){
            this.formData.status = value ? 1 : 0
        },
        onAdminToggle(value){
            this.formData.is_admin = value
        },
    },
    mounted(){
        
    }
}
</script>