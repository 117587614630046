<template>
    <div class="card p-6">
        <div class="flex justify-between items-center mb-6">
            <p class="card-heading mb-0">Entry Time</p>
            <toggle-button
                class="text-sm"
                :labels="['Last 30 days', 'Last 7 days']"
                :onToggle="onDurationToggle"
            />
        </div>
        <div ref="monthly-time-chart"></div>
    </div>
</template>

<script>
import ApexCharts from "apexcharts";
import helper from "@/services/helper";
import ToggleButton from "@/components/elements/ToggleButton.vue";
export default {
    components: { ToggleButton },
    name: "MonthlyAttendanceTime",
    props: ["attendances"],
    data() {
        return {
            seriesData: [],
            labels: [],
            duration: "Last 30 days",
            chart: null
        };
    },
    methods: {
        generateData() {
            this.seriesData = []
            this.labels = []
            if (this.duration == "Last 30 days") {
                const attendances = this.$props.attendances;
                for (let i in attendances) {
                    const minutes = helper.totalMinutesFromTime(
                        attendances[i].start
                    );
                    this.seriesData.push(minutes);
                    // this.attObject[minutes] = attendances[i];
                    this.labels.push(new Date(attendances[i].date).getDate());
                }
            } else {
                const attendances = this.$props.attendances.slice(-7);
                for (let i in attendances) {
                    const minutes = helper.totalMinutesFromTime(
                        attendances[i].start
                    );
                    this.seriesData.push(minutes);
                    // this.attObject[minutes] = attendances[i];
                    this.labels.push(new Date(attendances[i].date).getDate());
                }
            }
        },
        mountChart() {
            this.generateData();
            const seriesData = this.seriesData;
            const labels = this.labels;
            var options = {
                series: [
                    {
                        name: "Entry Time",
                        data: seriesData,
                    },
                ],
                chart: {
                    height: 250,
                    type: "line",
                    zoom: {
                        enabled: false,
                    },
                },
                // dataLabels: {
                //     enabled: false,
                // },
                stroke: {
                    curve: "straight",
                    width: 3,
                    colors: ["#FF7D5D"],
                },

                grid: {
                    row: {
                        // colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0.2,
                    },
                },
                colors: ["#FA407F"],
                xaxis: {
                    categories: labels,
                },
                yaxis: {
                    labels: {
                        formatter: function (val, index) {
                            // console.log(val)
                            // return attObject[val] ? attObject[val].start : '';
                            return helper.formatTimeFromMinutes(val);
                        },
                    },
                },
                tooltip: {
                    enabled: true,
                    y: {
                        formatter: function (val, opts) {
                            return helper.formatTimeFromMinutes(val);
                        },
                    },
                },
            };
           this.chart = new ApexCharts(
                this.$refs["monthly-time-chart"],
                options
            );
            this.chart.render();
        },
         updateChart() {
            this.generateData();
            const seriesData = this.seriesData;
            const labels = this.labels;
            var options = {
                series: [
                    {
                        name: "Entry Time",
                        data: seriesData,
                    },
                ],
                chart: {
                    height: 250,
                    type: "line",
                    zoom: {
                        enabled: false,
                    },
                },
                // dataLabels: {
                //     enabled: false,
                // },
                stroke: {
                    curve: "straight",
                    width: 3,
                    colors: ["#FF7D5D"],
                },

                grid: {
                    row: {
                        // colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0.2,
                    },
                },
                colors: ["#FA407F"],
                xaxis: {
                    categories: labels,
                },
                yaxis: {
                    labels: {
                        formatter: function (val, index) {
                            // console.log(val)
                            // return attObject[val] ? attObject[val].start : '';
                            return helper.formatTimeFromMinutes(val);
                        },
                    },
                },
                tooltip: {
                    enabled: true,
                    y: {
                        formatter: function (val, opts) {
                            return helper.formatTimeFromMinutes(val);
                        },
                    },
                },
            };
            this.chart.updateOptions(options)
        },
        onDurationToggle(label) {
            this.duration = label;
            this.updateChart()
        },
    },
    mounted() {
        this.mountChart();
    },
};
</script>
