<template>
    <div class="grid lg:grid-cols-4 gap-4">
        <div class="lg:col-span-1">
            <div class="card p-6">
                <employee-filter :designations="designations" :onChnage="filterChange" />
            </div>
        </div>
        <div class="card overflow-hidden lg:col-span-3 h-fit">
            <div class="p-6">
                <button class="btn btn-primary btn-sm" @click="addEmployeeModal = true">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
                    </svg>
                    Create
                </button>
            </div>
            <div class="w-full overflow-x-auto">
                <employees-table :employees="employees" :designations="designations" :onUpdate="getEployees" />
            </div>
            <div class="p-6">
                <table-footer :defaultCount="filters.limit" :next="nextPage" :previous="prevPage" :chnangeCount="limitChnage" :pages="tablePages" />
            </div>
        </div>
        <modal :active="addEmployeeModal" :close="() => addEmployeeModal = false">
            <div class="w-full max-w-[600px] px-4 lg:px-0">
                <div class="card p-6">
                    <add-employee-form :close="() => addEmployeeModal = false" :designations="designations" />
                </div>
            </div>
        </modal>
    </div>
</template>

<script>

import server from '@/services/server'
import Case from 'case'
import EmployeesTable from '@/components/partials/tables/hrm/EmployeesTable.vue'
import TableFooter from '@/components/elements/TableFooter.vue'
import EmployeeFilter from '@/components/partials/filters/hrm/EmployeeFilter.vue'
import Modal from '@/components/elements/Modal.vue'
import AddEmployeeForm from '@/components/partials/forms/hrm/AddEmployeeForm.vue'

export default {
  components: { EmployeesTable, TableFooter, EmployeeFilter, Modal, AddEmployeeForm },
    name: 'Employees',
    data(){
        return{
            employees: [],
            designations: {},
            filters: {
                filter: {},
                skip: 0,
                limit: 50,
                sort: {createdAt: '-1'}
            },
            tablePages: 0,
            addEmployeeModal: false
        }
    },
    methods: {
        async getEployees(){
            try{
                const response = await server.post('/employee/fetch', this.filters, true)
                this.employees = response.employees
                this.tablePages = Math.ceil(response.length / this.filters.limit)
            }catch(e){
                this.$moshaToast('Unable to get employee data.', {type: 'danger'})
            }
        },

        async getDesignations(){
           try{
                const response = await server.post('/designation/fetch')
                response.designations.forEach(element => {
                    element.title = Case.title(element.title)
                    this.designations[element._id] = element
                })

           }catch(e){
                this.$moshaToast('Unable to get designations data.', {type: 'danger'})
           }
        },
        async filterChange(values){
            this.filters.sort = values.sort
            this.filters.filter = values.filter
            await this.getEployees()
        },
        async nextPage(){
            this.filters.skip += this.filters.limit
            await this.getEployees()
            // console.log(this.filters.skip)
        },
        async prevPage(){
            this.filters.skip -= this.filters.limit
            await this.getEployees()
            // console.log(this.filters.skip)
        },
        async limitChnage(limit){
            this.filters.limit = limit
            this.filters.skip = 0
            await this.getEployees()
        }
    },
    async created(){
        await this.getDesignations()
        await this.getEployees()
    }
}
</script>