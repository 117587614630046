<template>
    <vee-form @submit="save" :validation-schema="schema">
            <div class="mat-input mb-4">
                <vee-field name="title" v-slot="{meta, field}">
                    <label for="">Designation Title:</label>
                    <input type="text" name="title" v-bind="field" :class="{ 'is-invalid': !meta.valid && meta.touched }" placeholder="Enter new designation title">
                    <error-message name="title" class="invalid-feedback" />
                </vee-field>
            </div>
            <div>
                <button class="btn btn-primary btn-block">
                    <span v-show="!inSubmission">Save</span>
                    <span class="text-center" v-show="inSubmission">
                        <svg class="animate-spin -ml-1 mr-1 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Please wait...
                    </span>
                </button>
            </div>
        </vee-form>
</template>

<script>
import server from '@/services/server'

export default {
    name: 'AddDesignationForm',
    props: ['onSave'],
    data(){
        return{
            inSubmission: false,
            schema: {
                title: 'required'
            }
        }
    },
    methods: {
        async save(values){
            this.inSubmission = true
            try{
                await server.post('/designation', values, true)
                this.$moshaToast('Designation created successfully.', {type: 'success'})
                await this.$props.onSave()
            }catch(e){
                this.$moshaToast('Unable to create designation. Please try again.', {type: 'danger'})
            }
            this.inSubmission = false
        }
    },

}
</script>