<template>
    <div>
        <table class="table">
        <thead>
            <tr>
                <th>Employee Id</th>
                <th>Employee Name</th>
                <th>Date</th>
                <th>Start</th>
                <th>End</th>
                <th>Time</th>
                <th v-show="adminAccess">Edit</th>
                <th v-show="adminAccess">Delete</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="row in attendances" :key="row._id">
                <td>{{employees[row.employee].id}}</td>
                <td>{{employees[row.employee].first_name}} {{employees[row.employee].last_name}}</td>
                <td>{{formatDate(row.date)}}</td>
                <td>{{formatTime(formatDate(row.date), row.start)}}</td>
                <td>
                    <span v-if="row.end">{{formatTime(formatDate(row.date), row.end)}}</span>
                </td>
                <td>
                    <span >{{calcTime(row)}}</span>
                </td>
                <td v-show="adminAccess">
                    <button class="btn-link btn-link-blue" @click="openAttendanceModal(row)">EDIT</button>
                </td>
                <td v-show="adminAccess">
                    <button class="btn-link btn-link-red" @click="openDeleteModal(row._id)">DELETE</button>
                </td>
            </tr>
        </tbody>
    </table>
    <modal :active="attendanceModal.active" :close="closeAttendanceModal">
        <div class="w-full max-w-[384px] mx-auto px-4 lg:px-0">
            <div class="card p-4">
                <update-attendance-form v-if="attendanceModal.data" :attendance="attendanceModal.data" :employees="employees" :onClose="closeAttendanceModal" :onSave="onUpdate" />
            </div>
        </div>
    </modal>
    <delete-modal :active="deleteModal.active" :onClose="closeDeleteModal" :onConfirm="deleteItem" />
    </div>
</template>

<script>
import helper from '@/services/helper'
import Modal from '@/components/elements/Modal.vue'
import UpdateAttendanceForm from '../../forms/hrm/UpdateAttendanceForm.vue'
import DeleteModal from '@/components/elements/DeleteModal.vue'
import server from '@/services/server'
export default {
  components: { Modal, UpdateAttendanceForm, DeleteModal },
    name: 'AttendancesTable',
    props: ['attendances', 'employees', 'onUpdate', 'adminAccess'],
    data(){
        return{
            attendanceModal: {
                active: false,
                data: null
            },
            deleteModal:{
                active: false,
                id: null
            }
        }
    },
    methods: {
        formatDate(date){
            return helper.formatDate(new Date(date))
        },
        formatTime(date, time){
            return helper.formatTime12Hour(new Date(date + ' ' + time))
        },
        calcTime(attend){
            // console.log(attend.duration)
            if(attend.end && attend.duration !== undefined){
                return helper.getTimeStringFromMS(attend.duration)
            }
            // formatDate(row.date), row.start, row.end)
            const date = this.formatDate(attend.date)
            return helper.clacTime(new Date(), new Date(date + ' ' + attend.start))
        },
        openAttendanceModal(data){
            this.attendanceModal.active = true
            this.attendanceModal.data = data
        },
        closeAttendanceModal(){
            this.attendanceModal.active = false
            this.attendanceModal.data = null
        },
        openDeleteModal(id){
            this.deleteModal = {
                active: true,
                id: id
            }
        },
        closeDeleteModal(){
            this.deleteModal = {
                active: false,
                id: null
            }
        },
        async deleteItem(){
            try{
                await server.delete('/attendance/' + this.deleteModal.id)
                this.$moshaToast("Attendance deleted successfully.", {
                    type: "success",
                });
                await this.onUpdate()
                this.closeDeleteModal()
            }catch(e){
                this.$moshaToast("Unable to delete attendance. Please try again.", {
                    type: "danger",
                });
            }
        }
    },
    created(){
        // console.log(this.$props.attendances)
    }
}
</script>